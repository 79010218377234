import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CONSTANTS, server, FlexRow, FlexCol, FlexItem } from '../assets/CONSTANTS';

const propTypes = {
  dataPacket: PropTypes.array
};

const defaultProps = {};

const FlexRowPadded = styled(FlexRow)`
  margin: 15px 0px;
`;

const ProfileImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 5px;
`;

const AdminName = styled(FlexItem)`
  margin-top: 5px;
`;

const GreyText = styled(FlexItem)`
  color: ${CONSTANTS.color.greyP};
  font-size: 14px;
`;

const Wrapper50 = styled.div`
  width: 50%;
  text-align: left;
  padding: 0;
  background-color: transparent;
  margin: 0px;
`;

class AdminItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  generateAdminItems(adminProfiles) {
    if (adminProfiles !== undefined) {
      return adminProfiles.map(profile => {
        return (
          <Wrapper50 key={profile.id}>
            <FlexRowPadded>
              <FlexItem flex={1}>
                <ProfileImg alt="" src={profile.profile_url ? profile.profile_url : `${server}media/default_profile/default_profile.png`} />
              </FlexItem>
              <FlexItem flex={5}>
                <FlexCol>
                  <AdminName>{`${profile.first_name} ${profile.last_name}`}</AdminName>
                  <GreyText>Admin | {profile.user_email}</GreyText>
                </FlexCol>
              </FlexItem>
            </FlexRowPadded>
          </Wrapper50>
        );
      });
    } else {
      return <div>No Profiles Found</div>;
    }
  }

  render() {
    return <React.Fragment>{this.generateAdminItems(this.props.dataPacket)}</React.Fragment>;
  }
}

AdminItems.propTypes = propTypes;
AdminItems.defaultProps = defaultProps;

export default AdminItems;