import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CONSTANTS, { SettingsInput } from '../assets/CONSTANTS';

const Label = styled.p`
  color: ${(props) => (props.error ? CONSTANTS.color.red : 'black')};
  margin-bottom: 0;
  margin-top: 0;
  padding: 5px 0px;
  text-transform: uppercase;
  font-size: 13px;
`;

const Input = styled(SettingsInput)`
  color: 'black';
  padding-left: 10px;
  font-size: 14px;
`;

const Wrapper = styled.div`
  text-align: left;
  padding: 5px 0px;
`;

function InputGroup({
  label,
  inputName,
  type,
  placeholder,
  onChange,
  onKeyPress,
  value = '',
  error = false,
  disabled,
}) {
  return (
    <Wrapper>
      <Label error={error}>{label}</Label>
      <Input
        onKeyPress={(e) => onKeyPress(e)}
        onChange={(e) => onChange(inputName, e)}
        type={type}
        placeholder={placeholder}
        value={value || ''}
        error={error}
        disabled={disabled}
      />
    </Wrapper>
  );
}

InputGroup.propTypes = {
  label: PropTypes.string,
  inputName: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

InputGroup.defaultProps = {
  onKeyPress: () => {},
  label: '',
  inputName: '',
  placeholder: '',
  onChange: () => {},
  disabled: false,
  error: false,
  id: '',
};

export default InputGroup;
