import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  FlexRow,
  FlexItem,
  VCenteredImage,
  VCenteredContent,
  FormatDate,
  mapStoreToProps,
  CONSTANTS,
} from '../assets/CONSTANTS';

const ActionButton = styled.button`
  background-color: transparent;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  padding: 10px 20px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
`;

const P = styled.p`
  margin: 0;
`;

class EpisodeRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalActive: false,
      parentPod: this.props.parentPod,
    };
  }

  generateLocationsInfo(ep_locations) {
    let counter = 0;
    let locs = Object.keys(ep_locations);
    // TODO: should be map filter, leaving for now
    // eslint-disable-next-line array-callback-return
    let results = locs.map((key) => {
      let value = ep_locations[key];
      counter++;
      if (value !== null && value !== false) {
        switch (key) {
          case 'top_trending': {
            return <P key={counter}>Top Trending</P>;
          }
          case 'recommended': {
            return <P key={counter}>Recommended</P>;
          }
          case 'liked_by_influencer': {
            return <P key={counter}>Verified</P>;
          }
          default: {
            break;
          }
        }
      } else {
        return <P key={counter}></P>;
      }
    });
    return results;
  }

  generateRowData(rowsData) {
    return rowsData !== null && rowsData !== undefined
      ? rowsData.map((row) => {
          return (
            <FlexRow paddedVert tableRowContainer key={row.episode_id} padded>
              <FlexItem textAlign="center">
                <Link to={`/Episode/Reviews/${row.episode_id}`}>
                  <VCenteredImage circleImg width="50px" height="50px" alt="" src={row.image} />
                </Link>
              </FlexItem>
              <FlexItem textAlign="left">
                <VCenteredContent>{row.title}</VCenteredContent>
              </FlexItem>
              <FlexItem textAlign="center">
                <VCenteredContent>{FormatDate(row.pub_date)}</VCenteredContent>
              </FlexItem>
              <FlexItem textAlign="center">
                <VCenteredContent>{row.bookmark_count}</VCenteredContent>
              </FlexItem>
              <FlexItem textAlign="center">
                <VCenteredContent>{row.summary_listen_data.display_listen_count}</VCenteredContent>
              </FlexItem>
              <FlexItem textAlign="center">
                <VCenteredContent>
                  <Link to={`/Episode/Reviews/${row.episode_id}`}>{row.rating_count}</Link>
                </VCenteredContent>
              </FlexItem>
              <FlexItem textAlign="center">
                <VCenteredContent>
                  {row?.is_recommended && <p>Recommended</p>}
                  {row?.is_discussions_happening_now && <p>Discussions Happening Now</p>}
                  {row?.is_friends_of_goodpods && <p>Friends of Goodpods</p>}
                </VCenteredContent>
              </FlexItem>
              {/* <FlexItem textAlign="center">
                <VCenteredContent>{row.shares_count}</VCenteredContent>
              </FlexItem> */}
              {/* <FlexItem textAlign="center">
                {this.generateLocationsInfo(row.episode_locations)}
              </FlexItem> */}
              <FlexItem>
                <VCenteredContent>
                  <ActionButton id={row.episode_id} onClick={(e) => this.props.openModal(e)}>
                    Assign to feature
                  </ActionButton>
                </VCenteredContent>
              </FlexItem>
            </FlexRow>
          );
        })
      : '';
  }

  render() {
    console.log(this.props.dataPacket);
    return <div>{this.generateRowData(this.props.dataPacket)}</div>;
  }
}

EpisodeRow.propTypes = {
  dataPacket: PropTypes.array,
  openModal: PropTypes.func,
};

export default connect(mapStoreToProps)(EpisodeRow);
