import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CONSTANTS, FlexRow, FlexCol, FlexItem, FormatDate } from '../assets/CONSTANTS';

let defaultProps = {
  dataPacket: {}
};
let propTypes = {
  dataPacket: PropTypes.array,
  onClick: PropTypes.func
};

const Wrapper = styled(FlexRow)`
  cursor: pointer;
  transition: 0.15s all ease-in-out;
  &:hover {
    transition: 0.15s all ease-in-out;
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.25);
  }
  &:focus {
    transition: 0.15s all ease-in-out;
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.25);
  }
`;
const Date = styled(FlexCol)`
  text-align: center;
`;

const Day = styled(FlexItem)`
  font-size: 1.5rem;
  color: ${CONSTANTS.color.greyP};
`;
const Month = styled(FlexItem)`
  color: ${CONSTANTS.color.greyP};
`;

export default function ReportedUserItem({ dataPacket, onClick }) {
  let date = FormatDate(dataPacket.created_date, 'dm');
  let splitDate = date.split(' ');
  let day = splitDate[0];
  let month = splitDate[1];

  if (dataPacket.reported_user != null) {
    return (
      <Wrapper
        className="itemWrapper"
        id={dataPacket.report_id}
        onClick={e => onClick(e)}
        padding="padding: 10px 35px 10px 35px;"
        tableRowContainer
        key={dataPacket.id}
      >
        <FlexItem flex={1} textAlign="left">
          <Date>
            <Month textAlign="left">{month}</Month>
            <Day textAlign="left">{day}</Day>
          </Date>
        </FlexItem>
        <FlexItem flex={5}>
          {dataPacket.reporting_user.first_name} <br></br> {dataPacket.comment}
        </FlexItem>
        <FlexItem textAlign="right" flex={3}>
          {dataPacket.reported_user.first_name}
          <br></br>
          {dataPacket.reported_user.last_name}
        </FlexItem>
      </Wrapper>
    );
  } else {
    return null;
  }
}

ReportedUserItem.propTypes = propTypes;
ReportedUserItem.defaultProps = defaultProps;
