//React & React Router
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { AddClickedRCItem, AddClickedRepUser } from '../redux/actions';
//Custom Components
import UnreadRC from '../components/UnreadRC';
import ReportedContentDisplay from '../components/ReportedContentDisplay';
//Assets & Styles
import {
  CONSTANTS,
  FlexRow,
  FlexItem,
  server,
  ContentContainer,
  mapStoreToProps
} from '../assets/CONSTANTS';
import { apiGetRequest } from '../lib/api/utils';

// const reportedItemsURL = `${server}reported_items`;
const host = `${server}v2/admin/reported-items`;
const query = "?limit=100&sort_field=created&descending=true";
const reportedItemsURL = `${host}${query}`;

const Wrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 600;
`;
const Modal = styled.div`
  background-color: ${CONSTANTS.color.whiteP};
  position: absolute;
  top: calc(50vh - 150px);
  left: calc(50vw - 20%);
  max-width: 40%;
  width: 40%;
  min-width: 280px;
  padding: 40px;
  text-align: center;
`;
const Heading = styled.h1`
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.4rem;
`;
const Message = styled.p``;
const CloseButton = styled.button`
  padding: 10px 25px;
  border-radius: 4px;
  border: none;
  background-color: ${CONSTANTS.color.yellowP};
  font-weight: bold;
  width: 80%;
  outline: none;
  cursor: pointer;
`;

function ConfirmModal({ onClick }) {
  return (
    <Wrapper>
      <Modal>
        <Heading>Success!</Heading>
        <Message>The changes have been saved</Message>
        <CloseButton onClick={e => onClick(e)}>Got it!</CloseButton>
      </Modal>
    </Wrapper>
  );
}

const RightWrapper = styled(ContentContainer)`
  margin-left: 5px;
`;

function RightSection({ openModal, updateList }) {
  return (
    <RightWrapper>
      <ReportedContentDisplay updateList={updateList} openModal={openModal} />
    </RightWrapper>
  );
}

class ReportedContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      clickedResult: null,
      modalActive: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.UpdateState = this.UpdateState.bind(this);
  }

  handleModalClose(e) {
    this.setState({ modalActive: false });
  }

  handleModalOpen() {
    this.setState({ modalActive: true });
  }

  handleAddClickedRCItem = contentData => {
    this.props.AddClickedRCItem(contentData);
  };
  handleAddClickedRepUser = userData => {
    this.props.AddClickedRepUser(userData);
  };

  componentDidMount() {
    this.UpdateState();
  }

  async UpdateState() {
    this.setState({ results: await this.getReportedContent(reportedItemsURL, true) });
  }

  getReportedContent(url, initial) {
    return apiGetRequest(url)
      .then(async data => {
        if (initial) {
          return data
        } else {
          return data;
        }
      })
      .catch(error => console.log(error));
  }

  getUserData(url) {
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: this.props.token
      }
    })
      .then(response => response.json())
      .then(data => {
        return data;
      })
      .catch(error => console.log(error));
  }

  async handleClick(e) {
    let el = e.currentTarget;
    let clickedData = await this.getReportedContent(`${host}?report_id=${el.id}`, false);
    console.log(clickedData);
    let clickedRepUser = clickedData[0].reported_user;
    // Currently only comment and review reports are not being used
    // if (clickedData[0].reported_comment_data !== null) {
    //   clickedRepUser = await this.getUserData(
    //     `${server}user_profiles/${clickedData.reported_comment_data.user}`
    //   );
    // } else if (clickedData[0].reported_review_data !== null) {
    //   clickedRepUser = await this.getUserData(
    //     `${server}user_profiles/${clickedData.reported_review_data.user}`
    //   );
    // } else if (clickedData[0].reported_user_data !== null) {
    //   clickedRepUser = await this.getUserData(
    //     `${server}user_profiles/${clickedData.reported_user.profile_id}`
    //   );
    // }

    this.handleAddClickedRCItem(clickedData[0]);
    this.handleAddClickedRepUser(clickedRepUser);
  }

  render() {
    return (
      <div>
        {this.state.modalActive ? <ConfirmModal onClick={this.handleModalClose} /> : ''}
        <FlexRow>
          <FlexItem>
            <UnreadRC
              onClick={this.handleClick}
              title="Unread"
              linkPath=""
              dataPacket={this.state.results}
            />
          </FlexItem>
          <FlexItem>
            {this.props.reported_content !== null}
            <RightSection openModal={this.handleModalOpen} updateList={this.UpdateState} />
          </FlexItem>
        </FlexRow>
      </div>
    );
  }
}

ReportedContent.defaultProps = {
  reported_content: null
};

export default connect(
  mapStoreToProps,
  { AddClickedRCItem, AddClickedRepUser }
)(ReportedContent);
