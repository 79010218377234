import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  FlexRow, FlexItem, VCenteredImage, VCenteredContent,
} from '../assets/CONSTANTS';

class PodcastRow extends Component {
  generateRowData(rowsData) {
    return rowsData.map((row) => (
      <FlexRow paddedVert tableRowContainer key={row.id}>
        <FlexItem textAlign="center">
          <Link to={`/Episode/${row.podcast_id}`}>
            <VCenteredImage circleImg width="50px" height="50px" alt="" src={row.image} />
          </Link>
        </FlexItem>
        <FlexItem textAlign="left">
          <VCenteredContent>
            {row.title}
            <br />
            {row.artist}
          </VCenteredContent>
        </FlexItem>
        <FlexItem textAlign="center">
          <VCenteredContent>{row.bookmark_count}</VCenteredContent>
        </FlexItem>
        <FlexItem textAlign="center">
          <VCenteredContent>{row.review_count}</VCenteredContent>
        </FlexItem>
        <FlexItem textAlign="center">
          <VCenteredContent>{row.subscription_count}</VCenteredContent>
        </FlexItem>
      </FlexRow>
    ));
  }

  render() {
    return <div>{this.generateRowData(this.props.dataPacket)}</div>;
  }
}

PodcastRow.propTypes = {
  dataPacket: PropTypes.array,
};

export default PodcastRow;
