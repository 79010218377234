import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CONSTANTS, FlexRow, FlexItem, FlexCol } from '../assets/CONSTANTS';

const propTypes = {
  commentData: PropTypes.array,
  onDelete: PropTypes.func
};

const defaultProps = {};

const FlexColWhiteBG = styled(FlexCol)`
  background-color: ${CONSTANTS.color.whiteP};
  padding-bottom: 20px;
  margin-bottom: 50px;
  border-radius: 4px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
`;

const Heading = styled.h1`
  margin: 0px;
  padding: 25px 0px 25px 50px;
  border-bottom: 1px solid ${CONSTANTS.color.borderGrey};
`;

const ItemWrapper = styled(FlexItem)`
  padding: 15px 0px 15px 50px;
  border-bottom: 1px solid ${CONSTANTS.color.borderGrey};
`;

const RowWrapper = styled(FlexRow)`
  min-height: 0px;
`;

const PodcastEpisodeTitle = styled.h4`
  margin: 0px;
`;

const CommentText = styled.p`
  color: ${CONSTANTS.color.sidebarP};
  margin: 0;
  font-weight: 200;
`;

const ColPadded = styled(FlexItem)`
  padding: 5px 100px 5px 0px;
`;

const DeleteBtn = styled.button`
  padding: 10px 25px;
  margin-right: 25px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  border-radius: 4px;
  background-color: transparent;
  font-size: 14px;
  border-bottom: 2px solid ${CONSTANTS.color.borderGrey};
  outline: none;
  &:active {
    border-bottom: 1px solid ${CONSTANTS.color.borderGrey};
  }
`;

class ProfileComments extends Component {
  generateRows(data) {
    return data.map(comment => {
      return (
        <ItemWrapper key={comment?.id}>
          <RowWrapper>
            <FlexItem flex={2}>
              <PodcastEpisodeTitle>
                {comment?.episode_data?.podcast_title} - {comment?.episode_data?.title}
              </PodcastEpisodeTitle>
            </FlexItem>
            <ColPadded flex={1}>
              <CommentText>{comment?.user}</CommentText>
            </ColPadded>
            <ColPadded flex={3}>
              <CommentText>{comment?.comment}</CommentText>
            </ColPadded>
            <FlexItem textAlign="right" flex={1}>
              <DeleteBtn id={comment?.id} onClick={e => this.props.onDelete(e)}>
                Delete Comment
              </DeleteBtn>
            </FlexItem>
          </RowWrapper>
        </ItemWrapper>
      );
    });
  }

  render() {
    return (
      <FlexColWhiteBG margin="margin:20px 0px;">
        <FlexItem>
          <Heading>Comments</Heading>
        </FlexItem>
        {this.generateRows(this.props.commentData)}
      </FlexColWhiteBG>
    );
  }
}

ProfileComments.propTypes = propTypes;
ProfileComments.defaultProps = defaultProps;

export default ProfileComments;
