import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import {
  CONSTANTS,
  server,
  FlexRow,
  FlexItem,
  FlexCol,
  mapStoreToProps,
} from '../assets/CONSTANTS';

const FlexColWhiteBG = styled(FlexCol)`
  background-color: ${CONSTANTS.color.whiteP};
  padding-bottom: 20px;
  margin-bottom: 50px;
  border-radius: 4px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  border-top: 0px;
`;

const FlexRowWhiteBG = styled(FlexRow)`
  background-color: ${CONSTANTS.color.whiteP};
  border-radius: 4px;

  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
`;

const Heading = styled.h1`
  margin: 0px;
  padding: 25px 0px 25px 50px;
`;

const ItemWrapper = styled(FlexItem)`
  padding: 15px 0px 15px 50px;
  border-bottom: 1px solid ${CONSTANTS.color.borderGrey};
`;

const RowWrapper = styled(FlexRow)`
  min-height: 0px;
`;

const PodcastEpisodeTitle = styled.h4`
  margin: 0px;
`;

const PodcastArtist = styled.h4`
  margin: 0px;
  color: ${CONSTANTS.color.greyP};
  font-weight: 200;
  font-size: 14px;
`;

const NoVPods = styled.h4`
  padding: 25px 0px 25px 50px;
  margin: 0;
`;

const ColPadded = styled(FlexItem)`
  padding: 5px 100px 5px 0px;
`;

const DeleteBtn = styled.button`
  padding: 10px 25px;
  margin-right: 25px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  border-radius: 4px;
  background-color: transparent;
  font-size: 14px;
  border-bottom: 2px solid ${CONSTANTS.color.borderGrey};
  outline: none;
  cursor: pointer;
`;

const VerifyBtn = styled(DeleteBtn)``;

const AddPodcastBtn = styled.button`
  padding: 10px 25px;
  margin-right: 25px;
  border: 1px solid #fcdc01;
  border-radius: 4px;
  background-color: #fcdc01;
  font-size: 14px;
  border-bottom: 2px solid #e9e9e9;
  outline: none;
  cursor: pointer;
  margin-top: 25px;
`;

const RedTextVStatus = styled.p`
  color: ${CONSTANTS.color.red};
  margin: 0;
  font-weight: 200;
`;

const VStatus = styled.p`
  color: ${CONSTANTS.color.sidebarP};
  margin: 0;
  font-weight: 200;
`;

class ProfileVerifiedPods extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleRemoveClaimedPod = (e) => {
    const { userData, updateVerifiedPodsList } = this.props;
    const uID = userData.id;
    const podID = e.target.attributes['data-pod-id'].value;
    const url = `${server}delete-podcast-claim`;
    const formData = new FormData();
    formData.append('user_id', uID);
    formData.append('podcast_id', podID);

    console.log(`Removing Podcast ${podID} Claim from User:${uID}`);
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: this.props.token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          updateVerifiedPodsList(data.claimed_podcasts);
        }
      })
      .catch((err) => console.log(err));
  };

  handleAddVerifiedPod = (e) => {
    const { userData, updateVerifiedPodsList } = this.props;
    console.log(userData);
    const uID = userData.id;
    const podID = e.target.attributes['data-pod-id'].value;
    const url = `${server}add-podcast-claim`;
    const formData = new FormData();
    formData.append('user_id', uID);
    formData.append('podcast_id', podID);
    formData.append('skip_email', true);
    formData.append('verified', true);

    console.log(`Adding Claimed & Verified to Podcast ${podID}, from User: ${uID}`);
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: this.props.token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          updateVerifiedPodsList(data.claimed_podcasts);
        }
      })
      .catch((err) => console.log(err));
  };

  generateRows(data) {
    if (data) {
      console.log(data);
      return data.map((verifiedPod) => {
        return (
          <ItemWrapper key={verifiedPod.id}>
            <RowWrapper>
              <ColPadded flex={3}>
                <PodcastEpisodeTitle>{verifiedPod.title}</PodcastEpisodeTitle>
                <PodcastArtist>{verifiedPod.artist}</PodcastArtist>
              </ColPadded>
              <ColPadded flex={5}>
                {verifiedPod.verified ? (
                  <VStatus>Status: Verified</VStatus>
                ) : (
                  <RedTextVStatus>Status: Un-Verified</RedTextVStatus>
                )}
              </ColPadded>
              <FlexItem textAlign="right" flex={1}>
                {/* TODO: ADD V2 */}
                <DeleteBtn
                  onClick={(e) => this.handleRemoveClaimedPod(e)}
                  data-pod-id={verifiedPod.id}
                  disabled
                >
                  Remove
                </DeleteBtn>
              </FlexItem>
              {!verifiedPod.verified && (
                <FlexItem textAlign="right" flex={1}>
                  {/* TODO: ADD V2 */}
                  <VerifyBtn
                    onClick={(e) => this.handleAddVerifiedPod(e)}
                    data-pod-id={verifiedPod.id}
                    disabled
                  >
                    Verify
                  </VerifyBtn>
                </FlexItem>
              )}
            </RowWrapper>
          </ItemWrapper>
        );
      });
    }
    return <NoVPods>No Verified Podcasts</NoVPods>;
  }

  render() {
    const { verifiedPodsData, onAddVerifiedPod } = this.props;
    return (
      <>
        <FlexRowWhiteBG>
          <FlexItem flex={1}>
            <Heading>Users Podcasts</Heading>
          </FlexItem>
          <FlexItem textAlign="right" flex={1}>
            {/* TODO: ADD V2 */}
            <AddPodcastBtn onClick={() => onAddVerifiedPod()} disabled>Add a Podcast</AddPodcastBtn>
          </FlexItem>
        </FlexRowWhiteBG>
        <FlexColWhiteBG>{this.generateRows(verifiedPodsData)}</FlexColWhiteBG>
      </>
    );
  }
}

export default connect(mapStoreToProps)(ProfileVerifiedPods);
