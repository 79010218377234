// React & React Router
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

// Custom Components
import TableView from '../components/TableView';
import FeaturedModal from '../components/FeaturedModal';

// Assets & Styles
import TestData from '../data.json';
import BackArrow from '../assets/BackArrow.svg';
import {
  CONSTANTS,
  mapStoreToProps,
  server,
  FormatDate,
  FlexRow,
  FlexItem,
  VCenteredContent,
  sortPodcastComparison,
} from '../assets/CONSTANTS';
import Checkbox from '../components/Checkbox';

import {
  apiGetRequest, apiPostRequest, getNextPageFromURL, getPrevPageFromURL,
} from '../lib/api/utils';

const TableHeader = styled(FlexRow)`
  background-color: ${CONSTANTS.color.whiteP};
  border: 1px solid ${CONSTANTS.color.borderGrey};
  padding: 25px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const BackWrapper = styled(FlexItem)`
  display: flex;
  align-items: center;
`;

const PodTitle = styled.h4`
  margin: 0;
  font-weight: bold;
  font-size: 1.5rem;
  padding-left: 20px;
`;
const PodArtist = styled.p`
  font-weight: 300;
  padding-left: 20px;
  margin: 0;
  color: ${CONSTANTS.color.blackA50};
`;

const PrevNextBtn = styled.button`
  padding: 15px;
  background-color: #fff;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  cursor: pointer;
  outline: none;
  margin: 5px;
  font-weight: bold;
  transition: 0.15s all ease-in-out;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  &:hover {
    background-color: ${CONSTANTS.color.yellowP};
  }
`;

const CenterAligned = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${CONSTANTS.color.whiteP};
  padding: 20px 0px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
`;

const FilterButton = styled.button`
  padding: 10px 25px;
  border: 1px solid ${CONSTANTS.color.yellowP};
  border-radius: 4px;
  width: 100px;
  outline: none;
  cursor: pointer;
  font-size: 1.05rem;
  transition: 0.15s all ease-in-out;
  &:hover {
    background-color: ${CONSTANTS.color.yellowP};
  }
`;
const FilterInput = styled.input`
  padding: 10px 15px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  border-radius: 4px;
  margin-right: 15px;
  width: 200px;
  font-size: 1.05rem;
  outline: none;
`;

const PruneButton = styled.button`
  padding: 10px 25px;
  border-radius: 4px;
  width: 200px;
  outline: none;
  cursor: pointer;
  font-size: 1.05rem;
  transition: 0.15s all ease-in-out;
  &:hover {
    background-color: ${CONSTANTS.color.redDarkDB};
  }`;

const Wrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 600;
`;

const Modal = styled.div`
  background-color: ${CONSTANTS.color.whiteP};
  position: absolute;
  top: calc(50vh - 150px);
  left: calc(50vw - 20%);
  max-width: 40%;
  width: 40%;
  min-width: 280px;
  padding: 40px;
  text-align: center;
`;

const Heading = styled.h1`
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.4rem;
`;

const Message = styled.p``;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 50px;
  margin-top: 30px;
`;

const CloseButton = styled.button`
  padding: 10px 25px;
  border-radius: 4px;
  border: none;
  background-color: ${CONSTANTS.color.yellowP};
  font-weight: bold;
  width: 80%;
  outline: none;
  cursor: pointer;
`;

const ConfirmButton = styled(CloseButton)`
  background-color: ${CONSTANTS.color.redDarkDB};
`;

const host = `${server}`;

const episodePath = 'v2/podcast/episode-list';
const episodeURL = `${host}${episodePath}`;

class Podcasts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalActive: false,
      modalPruneActive: false,
      pruneConfirm: false,
      episodes_next: '',
      episodes_prev: '',
      episodes: [],
      influencers: [],
      chosenEpisode: {},
      podcast_id: this.props.match.params.podID,
      episodes_search: '',
      sort: {
        currentField: '',
        sortOrder: '',
      },
    };

    this.handleSortClick = this.handleSortClick.bind(this);
    this.loadNextPage = this.loadNextPage.bind(this);
    this.loadPrevPage = this.loadPrevPage.bind(this);
    this.openEpModal = this.openEpModal.bind(this);
    this.closeEpModal = this.closeEpModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateFeaturedStatus = this.updateFeaturedStatus.bind(this);
    this.handleFilterInput = this.handleFilterInput.bind(this);
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
  }

  componentDidMount() {
    this.initialize();
  }

  async initialize() {
    this.setState({
      // parent_pod: await this.getParentPod(`${podcastURL}${this.state.podcast_id}`),
      episodes: await this.getEpisodes(`${episodeURL}?podcastId=${this.state.podcast_id}&limit=10&offset=0`),
      // influencers: await this.getInfluencers(`${host}v2/admin/user-search?is_influencer=true&limit=20`),
    });
  }

  async loadNextPage() {
    const { episodes_next } = this.state;
    if (episodes_next) {
      this.setState({ episodes: await this.getEpisodes(episodes_next) });
    }
  }

  async loadPrevPage() {
    const { episodes_prev } = this.state;
    if (episodes_prev) {
      this.setState({ episodes: await this.getEpisodes(episodes_prev) });
    }
  }

  async openEpModal(e) {
    const ep_id = e.currentTarget.id;
    const ep = await this.getSingleEpisode(ep_id, this.state.episodes);
    console.log(ep);

    this.setState(
      {
        modalActive: true,
        chosenEpisode: ep,
      },
    );
  }

  closeEpModal() {
    this.setState({ modalActive: false });
  }

  handleSubmit() {
    this.submitEpModal(this.state.chosenEpisode);
  }

  submitEpModal(singleEp) {
    const ep = singleEp;
    const url = `${server}v2/admin/set-episode-details`;
    const reqData = {
      episode_id: ep.ep_id,
      recommended: ep.is_recommended,
      discussions_happening_now: ep.is_discussions_happening_now,
      friends_of_goodpods: ep.is_friends_of_goodpods,
    };

    return apiPostRequest(url, reqData)
      .then(async (data) => {
        console.log(data);
        this.setState({
          episodes: await this.getEpisodes(`${episodeURL}?podcastId=${this.state.podcast_id}`),
          modalActive: false,
        });
        return data;
      })
      .catch((error) => console.log(error));
  }

  updateFeaturedStatus(e, toggle) {
    const clicked = e.currentTarget.id;
    switch (clicked) {
      case 'rec': {
        const ep = { ...this.state.chosenEpisode };
        ep.is_recommended = !ep.is_recommended;
        console.log(ep);
        this.setState({ chosenEpisode: ep });
        break;
      }
      case 'fog': {
        const ep = { ...this.state.chosenEpisode };
        ep.is_friends_of_goodpods = !ep.is_friends_of_goodpods;
        console.log(ep);
        this.setState({ chosenEpisode: ep });
        break;
      }
      case 'dhn': {
        const ep = { ...this.state.chosenEpisode };
        ep.is_discussions_happening_now = !ep.is_discussions_happening_now;
        console.log(ep);
        this.setState({ chosenEpisode: ep });
        break;
      }
      default: {
        break;
      }
    }
  }

  getEpisodes(url) {
    return apiGetRequest(url)
      .then((data) => {
        this.setState({ episodes_next: getNextPageFromURL(url), episodes_prev: getPrevPageFromURL(url) });

        return data;
      })
      .catch((error) => console.log(error));
  }

  getSingleEpisode(clicked_id, data) {
    let results = {};
    console.log(clicked_id);
    console.log(data);
    // TODO: should be map filter, leaving for now
    // eslint-disable-next-line array-callback-return
    data.map((ep) => {
      // eslint-disable-next-line eqeqeq
      if (ep.episode_id == clicked_id) {
        console.log('matched');
        const singleEp = {
          ep_id: ep.episode_id,
          title: ep.title,
          image: ep.image,
          date: FormatDate(ep.pub_date, 'dmy'),
          parent_pod: ep.parent_podcast_title,
          goodpods_recommended: ep.goodpods_recommended,
          is_recommended: ep.is_recommended,
          is_friends_of_goodpods: ep.is_friends_of_goodpods,
          is_discussions_happening_now: ep.is_discussions_happening_now
        };
        results = { ...singleEp };
      }
    });
    return results;
  }

  handleSortClick(e) {
    const sortType = e.currentTarget.getAttribute('data-sorttype');
    if (sortType === this.state.sort.currentField) {
      this.state.sort.sortOrder === 'ASC'
        ? this.setState({ sort: { currentField: sortType, sortOrder: 'DESC' } }, () => {
          this.setState({
            episodes: sortPodcastComparison(
              this.state.episodes,
              this.state.sort.currentField,
              this.state.sort.sortOrder,
            ),
          });
        })
        : this.setState({ sort: { currentField: sortType, sortOrder: 'ASC' } }, () => {
          this.setState({
            episodes: sortPodcastComparison(
              this.state.episodes,
              this.state.sort.currentField,
              this.state.sort.sortOrder,
            ),
          });
        });
    } else {
      this.setState({ sort: { currentField: sortType, sortOrder: 'ASC' } }, () => {
        this.setState({
          episodes: sortPodcastComparison(
            this.state.episodes,
            this.state.sort.currentField,
            this.state.sort.sortOrder,
          ),
        });
      });
    }
  }

  handleFilterInput(e) {
    const val = e.target.value;
    this.setState({ episodes_search: val });
  }

  async handleFilterSubmit() {
    const search = this.state.episodes_search;
    const podID = this.state.podcast_id;
    const url = `${episodeURL}?podcastId=${podID}&title_search=${search}&limit=10&offset=0`;
    this.setState({ episodes: await this.getEpisodes(url) });
  }

  async handlePruneConfirm() {
    const confirm = this.state.pruneConfirm;
    if (!confirm) return;

    const podID = this.state.podcast_id;
    const url = `${server}admin/set-podcast-details`;
    try {
      const data = {
        podcast_id: podID,
        hidden: true,
      };
      await apiPostRequest(url, data);
      this.setState({ pruneConfirm: false, modalPruneActive: false });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { match } = this.props;
    const { episodes } = this.state;
    return (
      <div>
        <TableHeader>
          <BackWrapper flex={1} textAlign="left">
            <img alt="backArrow" src={BackArrow} onClick={() => this.props.history.goBack()} />
          </BackWrapper>
          <FlexItem flex={20} textAlign="left">
            <VCenteredContent>
              {episodes.length > 0 ? (
                <>
                  <PodTitle>{episodes[0].parent_podcast_title}</PodTitle>
                  {/* TODO: change to artist when endpoint is updated */}
                  <PodArtist>{episodes[0]?.parent_podcast_artist}</PodArtist>
                </>
              ) : null}
            </VCenteredContent>
          </FlexItem>
          <FlexItem>
            <VCenteredContent>
              <PruneButton
                onClick={() => this.setState({ modalPruneActive: true })}
              >
                Hide Podcast
              </PruneButton>
            </VCenteredContent>
          </FlexItem>
          <FlexItem flex={20} textAlign="right">
            <VCenteredContent>
              <FilterInput
                onChange={(e) => this.handleFilterInput(e)}
                type="text"
                placeholder="Search Episodes"
              />
              <FilterButton onClick={() => this.handleFilterSubmit()}>Filter</FilterButton>
            </VCenteredContent>
          </FlexItem>
        </TableHeader>
        <div>
          <TableView
            tableTitle=""
            linkText=""
            linkPath=""
            tableTopBar={false}
            episodeSearch
            rowData={this.state.episodes}
            headerData={TestData.headers.episodes}
            rowType="episode"
            parentPod={match.params.podID}
            openModal={this.openEpModal}
            chosenEp={this.state.chosenEpisode}
            sortFunction={this.handleSortClick}
            sortData={this.state.sort}
          />
          <CenterAligned>
            <PrevNextBtn onClick={this.loadPrevPage}>&lt;</PrevNextBtn>
            <PrevNextBtn onClick={this.loadNextPage}>&gt;</PrevNextBtn>
          </CenterAligned>
        </div>
        {this.state.modalActive ? (
          <FeaturedModal
            onSubmit={this.handleSubmit}
            onToggle={this.updateFeaturedStatus}
            data={this.state.chosenEpisode}
            influencers={this.state.influencers}
            onClick={this.closeEpModal}
          />
        ) : (
          ''
        )}
        {this.state.modalPruneActive && (
          <Wrapper>
            <Modal>
              <Heading>Warning</Heading>
              <Message>Confirming will set the podcast to hidden and prevent users from seeing the podcast anywhere on the platform (except for search until we remove the itunes dependency). Are you sure you want to proceed?</Message>
              <Checkbox
                label="Check to Confirm"
                onClick={() => this.setState((state) => ({ pruneConfirm: !state.pruneConfirm }))}
                checked={this.state.pruneConfirm}
                id="prune-checkbox"
              />
              <ButtonWrapper>
                <CloseButton onClick={() => this.setState({ modalPruneActive: false, pruneConfirm: false })}>Cancel</CloseButton>
                <ConfirmButton onClick={() => {}}>Hide</ConfirmButton>
              </ButtonWrapper>
            </Modal>
          </Wrapper>
        )}
      </div>
    );
  }
}

export default connect(mapStoreToProps)(Podcasts);
