import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { logout } from '../redux/actions';

import InputGroup from './InputGroup';

import {
  mapStoreToProps, CONSTANTS, server, FlexItem,
} from '../assets/CONSTANTS';
import { apiPostRequest } from '../lib/api/utils';

const { color } = CONSTANTS;

const ErrorMsg = styled.p`
  color: ${CONSTANTS.color.red};
  text-align: left;
`;

const SectionHeading = styled.h1`
  font-weight: bold;
  font-size: 18px;
  padding: ${(props) => (props.padding ? props.padding : '5px 0px;')};
  margin: 20px 0px 20px 0px;
  text-align: left;
`;

const ActionButton = styled.button`
  color: black;
  width: 100%;
  background-color: ${color.yellow};
  border: 1px solid ${color.yellow};
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  min-height: 45px;
  font-weight: bold;
  margin: 20px auto;
  padding: ${(props) => (props.padding ? props.padding : '5px 0px;')};
  transition: 0.15s all ease-in-out;
  &:hover {
    background-color: ${color.yellowP};
    border: 1px solid ${color.yellowP};
  }
`;

const FlexItemPadded = styled(FlexItem)`
  padding: 10px 15px !important;
  box-sizing: border-box;
`;

class PasswordChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPass: '',
      confPass: '',
      newPassError: false,
      confPassError: false,
      errorMsg: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  changePassword(password) {
    const url = `${server}v2/admin/edit-user`;
    const reqBody = {
      new_password: password,
    };
    return apiPostRequest(url, reqBody)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => console.log(error));
  }

  onChange(inputName, e) {
    if (inputName === 'newPassword') {
      const newPass = e.target.value;
      this.setState({ newPass });
    } else {
      const confPass = e.target.value;
      this.setState({ confPass });
    }
  }

  onClick() {
    const np = this.state.newPass;
    const cp = this.state.confPass;

    if (np !== cp) {
      this.setState({ newPassError: true, confPassError: true, errorMsg: 'Passwords dont match.' });
    } else if (np.length < 6 || cp.length < 6) {
      this.setState({
        newPassError: true,
        confPassError: true,
        errorMsg: 'Password is too short, minimum 6 characters.',
      });
    } else if (np.length >= 50 || cp.length >= 50) {
      this.setState({
        newPassError: true,
        confPassError: true,
        errorMsg: 'Password exceeds maximum of 50 characters',
      });
    } else if (np === cp) {
      this.changePassword(np);

      this.props.onSubmit();
      this.setState({
        newPassError: false,
        confPassError: false,
        errorMsg: null,
        newPass: '',
        confPass: '',
      });
    }
  }

  render() {
    const {
      newPass, confPass, newPassError, confPassError, errorMsg,
    } = this.state;
    console.log(this.props.user_info);
    return (
      <FlexItemPadded
        textAlign="center"
        bgColor={color.whiteP}
        margin="margin: 0 0 20px 10px"
      >
        <SectionHeading>Change password</SectionHeading>
        <form onSubmit={(e) => e.preventDefault()}>
          <InputGroup
            onChange={this.onChange}
            placeholder="New Password"
            inputName="newPassword"
            type="password"
            label="New Password"
            value={newPass}
            error={newPassError}
          />
          <InputGroup
            onChange={this.onChange}
            placeholder="Confirm Password"
            inputName="confPassword"
            type="password"
            label="Confirm Password"
            value={confPass}
            error={confPassError}
          />
          {errorMsg ? (
            <ErrorMsg>
              *
              {errorMsg}
            </ErrorMsg>
          ) : ''}
          <ActionButton onClick={this.onClick}>Save new password</ActionButton>
        </form>
      </FlexItemPadded>
    );
  }
}

export default connect(
  mapStoreToProps,
  { logout },
)(PasswordChange);
