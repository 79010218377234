import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  CONSTANTS,
  FlexRow,
  FlexItem,
  VCenteredImage,
  VCenteredContent,
  FormatDate,
} from '../assets/CONSTANTS';

const ButtonWrapper = styled(FlexItem)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionButton = styled.button`
  background-color: transparent;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  padding: 10px 20px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
`;
const P = styled.p`
  margin: 0;
`;

class FPodcastRow extends Component {
  generateLocationsInfo(ep_locations) {
    let counter = 0;
    let locs = Object.keys(ep_locations);
    // TODO: should use filter then map but we'll ignore for now
    // eslint-disable-next-line array-callback-return
    let results = locs.map((key) => {
      let value = ep_locations[key];
      counter++;
      if (value !== null && value !== false) {
        switch (key) {
          case 'top_trending': {
            return <P key={counter}>Top Trending</P>;
          }
          case 'recommended': {
            return <P key={counter}>Recommended</P>;
          }
          case 'liked_by_influencer': {
            return <P key={counter}>Verified</P>;
          }
          default: {
            break;
          }
        }
      } else {
        return <P key={counter}></P>;
      }
    });
    return results;
  }

  generateRowData(rowsData) {
    return rowsData.map((row) => {
      return (
        <FlexRow paddedVert tableRowContainer key={row.episode_id} padded>
          <FlexItem textAlign="center">
            <Link to={`/Episode/Reviews/${row.episode_id}`}>
              <VCenteredImage
                circleImg
                width="50px"
                height="50px"
                alt=""
                src={row.podcast_image}
              />
            </Link>
          </FlexItem>
          <FlexItem textAlign="left">
            <VCenteredContent>{row.parent_podcast_title}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>{FormatDate(row.episode_pub_date)}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>{row.episode_title}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>
              {row?.is_recommended && <p>Recommended</p>}
              {row?.is_discussions_happening_now && <p>Discussions Happening Now</p>}
              {row?.is_friends_of_goodpods && <p>Friends of Goodpods</p>}
            </VCenteredContent>
          </FlexItem>
          <ButtonWrapper textAlign="center">
            <ActionButton id={row.episode_id} onClick={(e) => this.props.onRemove(e)}>
              Remove
            </ActionButton>
          </ButtonWrapper>

          <ButtonWrapper textAlign="center">
            <ActionButton id={row.episode_id} onClick={(e) => this.props.openModal(e)}>
              Assign to feature
            </ActionButton>
          </ButtonWrapper>
        </FlexRow>
      );
    });
  }

  render() {
    return (
      <div>
        {this.props.dataPacket === undefined ? '' : this.generateRowData(this.props.dataPacket)}
      </div>
    );
  }
}

FPodcastRow.propTypes = {
  dataPacket: PropTypes.array,
};

export default FPodcastRow;
