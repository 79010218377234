//React & React Router
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

//Custom Components
import TableView from '../components/TableView';

//Assets & Styles
import { mapStoreToProps, server } from '../assets/CONSTANTS';
import TestData from '../../src/data.json';
import { getAuthUser } from '../firebase';

const host = `${server}`;
const path = 'v2/admin/user-search?limit=10';
const deactivatedParam = '&is_deactivated=true';
const endpoint = `${host}${path}`;

const Spacer = styled.div`
  padding: 10px 0px;
  margin: 5px 0px;
  min-height: 1px;
`;

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users_prev: '',
      users_next: '',
      users: [],
      deactivated_users: [],
    };
  }

  componentDidMount() {
    this.initialFetch();
  }

  async initialFetch() {
    this.setState({ users: await this.getUsers(`${endpoint}`) });
    this.setState({ deactivated_users: await this.getUsers(`${endpoint}${deactivatedParam}`) });
  }

  async getUsers(ep) {
    const currUser = getAuthUser();
    const firebaseToken = await currUser.getIdToken(false);
    return fetch(ep, {
      method: 'get',
      headers: {
        DjangoAuth: `Bearer ${firebaseToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ users_prev: data.next });
        this.setState({ users_next: data.previous });
        return data.results;
      })
      .catch((error) => console.log(error));
  }

  render() {
    return (
      <div>
        <TableView
          tableTitle="Newest Users"
          linkText="View All"
          linkPath="/All-Users"
          tableTopBar={true}
          rowData={this.state.users}
          headerData={TestData.headers.users}
          rowType="users"
        />
        <Spacer />
        <TableView
          tableTitle="Users Deactivated by Admin"
          linkText="View All"
          linkPath="/Deactivated-Users"
          tableTopBar={true}
          rowData={this.state.deactivated_users}
          headerData={TestData.headers.users}
          rowType="users"
        />
      </div>
    );
  }
}

export default connect(mapStoreToProps)(Users);
