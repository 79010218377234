import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Checkbox from './Checkbox';
import InputGroup from './InputGroup';

import {
  CONSTANTS,
  server,
  mapStoreToProps,
  FlexRow,
  FlexItem,
  FlexCol,
} from '../assets/CONSTANTS';
import { getAuthUser } from '../firebase';

const SaveChanges = styled.button`
  background-color: ${CONSTANTS.color.yellowP};
  padding: 10px 20px;
  margin: 10px 0px;
  color: black;
  font-weight: bold;
  width: 100%;
  border: none;
  border-radius: 6px;
  font-size: 20px;
  transition: 0.15s all ease-in-out;
  &:hover {
    background-color: ${CONSTANTS.color.yellow};
  }
`;

const FlexItemWhiteBG = styled(FlexItem)`
  background-color: ${CONSTANTS.color.whiteP};
`;

const Wrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 600;
`;

const Modal = styled.div`
  background-color: ${CONSTANTS.color.whiteP};
  position: absolute;
  top: calc(50vh - 150px);
  left: calc(50vw - 20%);
  max-width: 40%;
  width: 40%;
  min-width: 280px;
  padding: 40px;
  text-align: center;
`;

const Heading = styled.h1`
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.4rem;
`;

const Message = styled.p``;

const CloseButton = styled.button`
  padding: 10px 25px;
  border-radius: 4px;
  border: none;
  background-color: ${CONSTANTS.color.yellowP};
  font-weight: bold;
  width: 80%;
  outline: none;
  cursor: pointer;
`;

const StatusUpdateURL = `${server}v2/admin/edit-user`;

function ChangesModal({ onClick }) {
  return (
    <Wrapper>
      <Modal>
        <Heading>Success!</Heading>
        <Message>The changes have been saved</Message>
        <CloseButton onClick={(e) => onClick(e)}>Got it!</CloseButton>
      </Modal>
    </Wrapper>
  );
}

class ProfileStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalActive: false,
      influencer: this.props.influencer,
      podcaster: this.props.podcaster,
      eliteReviewer: this.props.eliteReviewer,
      promoted: this.props.promoted,
      inTheKnow: this.props.inTheKnow,
      featuredPodcaster: this.props.featuredPodcaster,
      userNote: this.props.userNote,
      userGoodFor: this.props.userGoodFor,
      adminScore: this.props.adminScore,
    };
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleChangeStatus(statusType) {
    switch (statusType) {
      case 'influencer': {
        const { influencer } = this.state;
        this.setState({ influencer: !influencer });
        break;
      }
      case 'eliteReviewer': {
        const { eliteReviewer } = this.state;
        this.setState({ eliteReviewer: !eliteReviewer });
        break;
      }
      case 'featuredPodcaster': {
        const { featuredPodcaster } = this.state;
        this.setState({ featuredPodcaster: !featuredPodcaster });
        break;
      }
      case 'promoted': {
        const { promoted } = this.state;
        this.setState({ promoted: !promoted });
        break;
      }
      case 'inTheKnow': {
        const { inTheKnow } = this.state;
        this.setState({ inTheKnow: !inTheKnow });
        break;
      }
      default: {
        break;
      }
    }
  }

  handleSubmit() {
    this.updateUserStatus(StatusUpdateURL);
  }

  handleCloseModal() {
    this.setState({ modalActive: false });
  }

  updateUserStatus(url) {
    let userNote = '';
    if (this.state.userNote !== null) {
      userNote = this.state.userNote;
    }

    let userGoodFor = '';
    if (this.state.userGoodFor !== null) {
      userGoodFor = this.state.userGoodFor;
    }

    let adminScore = 0;
    if (this.state.adminScore !== null) {
      adminScore = this.state.adminScore;
    }

    const sendData = {
      user_id: this.props.userID,
      is_influencer: this.state.influencer,
      is_promoted: this.state.promoted,
      is_featured_podcaster: this.state.featuredPodcaster,
      is_in_the_know: this.state.inTheKnow,
      is_elite_reviewer: this.state.eliteReviewer,
      user_note: userNote,
      user_good_for: userGoodFor,
      admin_score: adminScore,
    };
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      fetch(url, {
        method: 'POST',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
        body: JSON.stringify(sendData),
      })
        .then((res) => {
          if (res.status === 200 && res.statusText === 'OK') {
            this.setState({ modalActive: true });
          } else {
            console.log(res);
          }
        })
        .then((data) => data)
        .catch((error) => console.log(error));
    }).catch((err) => console.log(err));
  }

  render() {
    const {
      influencer, promoted, inTheKnow, modalActive, featuredPodcaster, eliteReviewer,
    } = this.state;
    return (
      <FlexItemWhiteBG padded margin="margin-right:25px;">
        {modalActive ? <ChangesModal onClick={this.handleCloseModal} /> : ''}
        <FlexCol>
          <FlexItem>
            <h4>Profile Status</h4>
          </FlexItem>
          <FlexItem>
            <FlexRow>
              <FlexItem>
                <Checkbox
                  onClick={() => this.handleChangeStatus('eliteReviewer')}
                  label="Mark as Elite Reviewer"
                  type="checkbox"
                  checked={eliteReviewer}
                />
              </FlexItem>
              <FlexItem>
                <Checkbox
                  onClick={() => this.handleChangeStatus('influencer')}
                  label="Mark as Influencer"
                  type="checkbox"
                  checked={influencer}
                />
              </FlexItem>
              <FlexItem>
                <Checkbox
                  onClick={() => this.handleChangeStatus('featuredPodcaster')}
                  label="Mark as Featured Podcaster"
                  type="checkbox"
                  checked={featuredPodcaster}
                />
              </FlexItem>
              <FlexItem>
                <Checkbox
                  onClick={() => this.handleChangeStatus('promoted')}
                  label="Mark as Promoted"
                  type="checkbox"
                  checked={promoted}
                />
              </FlexItem>
              <FlexItem>
                <Checkbox
                  onClick={() => this.handleChangeStatus('inTheKnow')}
                  label="Mark as In The Know"
                  type="checkbox"
                  checked={inTheKnow}
                />
              </FlexItem>
            </FlexRow>
            <FlexRow>
              <FlexItem>
                <InputGroup
                  id="adminScore"
                  label="Admin Score"
                  placeholder="0"
                  type="number"
                  onClick={() => this.handleChangeStatus('adminScore')}
                  value={this.state.adminScore}
                  onChange={(e, input) => this.setState({ adminScore: input.target.value })}
                />
              </FlexItem>
            </FlexRow>
            <FlexRow>
              <FlexItem>
                <InputGroup
                  id="userNote"
                  label="User Note"
                  placeholder="User Note"
                  type="text"
                  onClick={() => this.handleChangeStatus('userNote')}
                  value={this.state.userNote}
                  onChange={(e, input) => this.setState({ userNote: input.target.value })}
                />
              </FlexItem>
            </FlexRow>
            <FlexRow>
              <FlexItem>
                <InputGroup
                  id="userGoodFor"
                  label="User Good For"
                  placeholder="User Good For"
                  type="text"
                  onClick={() => this.handleChangeStatus('userGoodFor')}
                  value={this.state.userGoodFor}
                  onChange={(e, input) => this.setState({ userGoodFor: input.target.value })}
                />
              </FlexItem>
            </FlexRow>
          </FlexItem>
          <FlexItem>
            <SaveChanges onClick={this.handleSubmit}>Save Changes</SaveChanges>
          </FlexItem>
        </FlexCol>
      </FlexItemWhiteBG>
    );
  }
}

export default connect(mapStoreToProps)(ProfileStatus);
