/* eslint-disable react-hooks/exhaustive-deps */
// React & React Router
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Custom Components
import TableView from '../components/TableView';

// Assets & Styles
import TestData from '../data.json';
import { CONSTANTS, mapStoreToProps } from '../assets/CONSTANTS';
import { apiGetRequest, getNextPageFromURL, getPrevPageFromURL } from '../lib/api/utils';

const PrevNextBtn = styled.button`
  padding: 15px;
  background-color: #fff;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  cursor: pointer;
  outline: none;
  margin: 5px;
  font-weight: bold;
  transition: 0.15s all ease-in-out;
  &:hover {
    background-color: ${CONSTANTS.color.yellowP};
  }
`;

const CenterAligned = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${CONSTANTS.color.whiteP};
  padding: 20px 0px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
`;

const Search = (props) => {
  const [searchContent, setSearchContent] = useState([]);
  const [next, setNext] = useState('');
  const [previous, setPrev] = useState('');
  const [headersData, setHeadersData] = useState([]);

  const chooseHeaders = () => {
    const headerType = props.searchType;
    let headerData = '';
    if (headerType === 'podcasts') {
      headerData = TestData.headers.podcasts;
    } else {
      headerData = TestData.headers.users;
    }

    setHeadersData(headerData);
    setNext(props.next);
    setPrev(props.previous);
    setSearchContent(props.searchContent);
  };

  useEffect(() => {
    chooseHeaders();
  }, [props.searchContent]);

  const getSearchResults = async (ep) => {
    return apiGetRequest(ep)
      .then((data) => {
        console.log(props.searchType);
        if (props.searchType === 'podcasts') {
          if (data.length !== 10) {
            setNext(null);
            setPrev(getPrevPageFromURL(ep));
          } else {
            setNext(getNextPageFromURL(ep));
            setPrev(getPrevPageFromURL(ep));
          }
          return data;
        }
        if (data.results.length !== 10) {
          setNext(null);
          setPrev(getPrevPageFromURL(ep));
        } else {
          setNext(getNextPageFromURL(ep));
          setPrev(getPrevPageFromURL(ep));
        }
        return data.results;
      })
      .catch((error) => console.log(error));
  };

  const loadNextPage = async () => {
    if (next) {
      const searchRet = await getSearchResults(next);
      setSearchContent(searchRet);
    }
  };

  const loadPrevPage = async () => {
    if (previous) {
      const searchRet = await getSearchResults(previous);
      setSearchContent(searchRet);
    }
  };
  const { sortData, sortFunction, searchType } = props;
  return (
    <div>
      <TableView
        tableTitle="Search Results"
        linkText=""
        linkPath=""
        tableTopBar
        rowData={searchContent}
        headerData={headersData}
        rowType={searchType}
        sortFunction={sortFunction}
        sortData={sortData}
      />
      <CenterAligned>
        <PrevNextBtn
          disabled={previous === null}
          onClick={() => loadPrevPage()}
        >
          &lt;
        </PrevNextBtn>
        <PrevNextBtn
          disabled={next === null}
          onClick={() => loadNextPage()}
        >
          &gt;
        </PrevNextBtn>
      </CenterAligned>
    </div>
  );
};

export default connect(mapStoreToProps)(Search);
