import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

//Custom Components

//Assets & Styles
import dashboardIcon from '../assets/SideNav/dashboardIcon.svg';
import usersIcon from '../assets/SideNav/usersIcon.svg';
import podcastsIcon from '../assets/SideNav/podcastsIcon.svg';
import settingsIcon from '../assets/SideNav/settingsIcon.svg';
import repContentIcon from '../assets/SideNav/repContentIcon.svg';
import dashboardIconAlt from '../assets/SideNav/dashboardIconAlt.svg';
import usersIconAlt from '../assets/SideNav/usersIconAlt.svg';
import podcastsIconAlt from '../assets/SideNav/podcastsIconAlt.svg';
import settingsIconAlt from '../assets/SideNav/settingsIconAlt.svg';
import repContentIconAlt from '../assets/SideNav/repContentIconAlt.svg';

import { CONSTANTS } from '../assets/CONSTANTS';

const HoverLink = styled(Link)`
  background-color: transparent;
  transition: 0.15s all ease-in-out;
  min-height: 45px;
  padding: 15px 0px 15px 25px;
  color: ${CONSTANTS.color.sidebarText};
  text-decoration: none;
  display: flex;
  flex-direction: row;
  &:hover {
    border-left: 4px solid ${CONSTANTS.color.yellowP};
    color: ${CONSTANTS.color.whiteP};
    background-color: ${CONSTANTS.color.blackA50};
  }
`;

const SubHoverLink = styled(Link)`
  transition: 0.15s all ease-in-out;
  min-height: 45px;
  padding: 15px 0px 15px 25px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  background-color: ${CONSTANTS.color.blackA50};
  color: ${CONSTANTS.color.sidebarText};
  &:hover {
    border-left: 4px solid ${CONSTANTS.color.yellowP};
    color: ${CONSTANTS.color.whiteP};
  }
`;

const ActiveHoverLink = styled(Link)`
  transition: 0.15s all ease-in-out;
  min-height: 45px;
  padding: 15px 0px 15px 25px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  border-left: 4px solid ${CONSTANTS.color.yellowP};
  color: ${CONSTANTS.color.whiteP};
  background-color: ${CONSTANTS.color.blackA50};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 5;
  background-color: ${CONSTANTS.color.sidebarP};
  max-width: 250px;
  text-align: left;
  padding: 25px 0px;
  height: calc(100vh - 100px);
`;

const IconWrapper = styled.div`
  flex: 1;
`;

const LinkLabel = styled.p`
  flex: 5;
  margin: 0;
`;

class SideNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onClick, currentLoc } = this.props;
    return (
      <Wrapper>
        {currentLoc.name === 'Home' ? (
          <ActiveHoverLink onClick={() => onClick('Home')} to="/">
            <IconWrapper className="icon-wrapper">
              <img alt="dashboard" src={dashboardIconAlt} />
            </IconWrapper>
            <LinkLabel>Dashboard</LinkLabel>
          </ActiveHoverLink>
        ) : (
          <HoverLink onClick={() => onClick('Home')} to="/">
            <IconWrapper className="icon-wrapper">
              <img alt="dashboard" src={dashboardIcon} />
            </IconWrapper>
            <LinkLabel>Dashboard</LinkLabel>
          </HoverLink>
        )}
        {currentLoc.name === 'Users' ? (
          <ActiveHoverLink onClick={() => onClick('Users')} to="/Users">
            <IconWrapper className="icon-wrapper">
              <img alt="users" src={usersIconAlt} />
            </IconWrapper>
            <LinkLabel>Users</LinkLabel>
          </ActiveHoverLink>
        ) : (
          <HoverLink onClick={() => onClick('Users')} to="/Users">
            <IconWrapper className="icon-wrapper">
              <img alt="users" src={usersIcon} />
            </IconWrapper>
            <LinkLabel>Users</LinkLabel>
          </HoverLink>
        )}
        {currentLoc.name === 'Podcasts' || currentLoc.name === 'All Podcasts' ? (
          <>
            <SubHoverLink onClick={() => onClick('Podcasts')} to="/Podcasts">
              <IconWrapper className="icon-wrapper">
                <img alt="podcasts" src={podcastsIconAlt} />
              </IconWrapper>
              <LinkLabel>Podcasts</LinkLabel>
            </SubHoverLink>
            <ActiveHoverLink onClick={() => onClick('All Podcasts')} to="/Podcasts">
              <IconWrapper className="icon-wrapper"></IconWrapper>
              <LinkLabel>All Podcasts</LinkLabel>
            </ActiveHoverLink>
            <SubHoverLink onClick={() => onClick('Featured Podcasts')} to="/Featured-Podcasts">
              <IconWrapper className="icon-wrapper"></IconWrapper>
              <LinkLabel>Featured Podcasts</LinkLabel>
            </SubHoverLink>
          </>
        ) : (
          <>
            {currentLoc.name === 'Featured Podcasts' ? (
              <>
                <SubHoverLink onClick={() => onClick('Podcasts')} to="/Podcasts">
                  <IconWrapper className="icon-wrapper">
                    <img alt="podcasts" src={podcastsIconAlt} />
                  </IconWrapper>
                  <LinkLabel>Podcasts</LinkLabel>
                </SubHoverLink>
                <SubHoverLink onClick={() => onClick('All Podcasts')} to="/Podcasts">
                  <IconWrapper className="icon-wrapper"></IconWrapper>
                  <LinkLabel>All Podcasts</LinkLabel>
                </SubHoverLink>
                <ActiveHoverLink
                  onClick={() => onClick('Featured Podcasts')}
                  to="/Featured-Podcasts"
                >
                  <IconWrapper className="icon-wrapper"></IconWrapper>
                  <LinkLabel>Featured Podcasts</LinkLabel>
                </ActiveHoverLink>
              </>
            ) : (
              <HoverLink onClick={() => onClick('Podcasts')} to="/Podcasts">
                <IconWrapper className="icon-wrapper">
                  <img alt="podcasts" src={podcastsIcon} />
                </IconWrapper>
                <LinkLabel>Podcasts</LinkLabel>
              </HoverLink>
            )}
          </>
        )}
        {currentLoc.name === 'Settings' ? (
          <ActiveHoverLink onClick={() => onClick('Settings')} to="/Settings">
            <IconWrapper className="icon-wrapper">
              <img alt="settings" src={settingsIconAlt} />
            </IconWrapper>
            <LinkLabel>Settings</LinkLabel>
          </ActiveHoverLink>
        ) : (
          <HoverLink onClick={() => onClick('Settings')} to="/Settings">
            <IconWrapper className="icon-wrapper">
              <img alt="settings" src={settingsIcon} />
            </IconWrapper>
            <LinkLabel>Settings</LinkLabel>
          </HoverLink>
        )}
        {currentLoc.name === 'Reported Content' ? (
          <ActiveHoverLink onClick={() => onClick('Reported Content')} to="/ReportedContent">
            <IconWrapper className="icon-wrapper">
              <img alt="reportedContent" src={repContentIconAlt} />
            </IconWrapper>
            <LinkLabel>Reported Content</LinkLabel>
          </ActiveHoverLink>
        ) : (
          <HoverLink onClick={() => onClick('Reported Content')} to="/ReportedContent">
            <IconWrapper className="icon-wrapper">
              <img alt="reportedContent" src={repContentIcon} />
            </IconWrapper>
            <LinkLabel>Reported Content</LinkLabel>
          </HoverLink>
        )}
        {currentLoc.name === 'Dynamic Emails' ? (
          <ActiveHoverLink onClick={() => onClick('Dynamic Emails')} to="/DynamicEmails">
            <IconWrapper className="icon-wrapper">
              <img alt="reportedContent" src={repContentIconAlt} />
            </IconWrapper>
            <LinkLabel>Weekly Emails</LinkLabel>
          </ActiveHoverLink>
        ) : (
          <HoverLink onClick={() => onClick('Dynamic Emails')} to="/DynamicEmails">
            <IconWrapper className="icon-wrapper">
              <img alt="reportedContent" src={repContentIcon} />
            </IconWrapper>
            <LinkLabel>Weekly Emails</LinkLabel>
          </HoverLink>
        )}
        {currentLoc.name === 'Notifications' ? (
          <ActiveHoverLink onClick={() => onClick('Notifications')} to="/Notifications">
            <IconWrapper className="icon-wrapper">
              <img alt="reportedContent" src={usersIconAlt} />
            </IconWrapper>
            <LinkLabel>Notifications</LinkLabel>
          </ActiveHoverLink>
        ) : (
          <HoverLink onClick={() => onClick('Notifications')} to="/Notifications">
            <IconWrapper className="icon-wrapper">
              <img alt="reportedContent" src={usersIcon} />
            </IconWrapper>
            <LinkLabel>Notifications</LinkLabel>
          </HoverLink>
        )}
        {currentLoc.name === 'Groups' ? (
          <ActiveHoverLink onClick={() => onClick('Groups')} to="/Groups">
            <IconWrapper className="icon-wrapper">
              <img alt="reportedContent" src={usersIconAlt} />
            </IconWrapper>
            <LinkLabel>Groups</LinkLabel>
          </ActiveHoverLink>
        ) : (
          <HoverLink onClick={() => onClick('Groups')} to="/Groups">
            <IconWrapper className="icon-wrapper">
              <img alt="reportedContent" src={usersIcon} />
            </IconWrapper>
            <LinkLabel>Groups</LinkLabel>
          </HoverLink>
        )}
        {currentLoc.name === 'CuratedLists' ? (
          <ActiveHoverLink onClick={() => onClick('CuratedLists')} to="/CuratedLists">
            <IconWrapper className="icon-wrapper">
              <img alt="reportedContent" src={usersIconAlt} />
            </IconWrapper>
            <LinkLabel>Curated Lists</LinkLabel>
          </ActiveHoverLink>
        ) : (
          <HoverLink onClick={() => onClick('CuratedLists')} to="/CuratedLists">
            <IconWrapper className="icon-wrapper">
              <img alt="reportedContent" src={usersIcon} />
            </IconWrapper>
            <LinkLabel>Curated Lists</LinkLabel>
          </HoverLink>
        )}
        {currentLoc.name === 'TipJars' ? (
          <ActiveHoverLink onClick={() => onClick('TipJars')} to="/TipJars">
            <IconWrapper className="icon-wrapper">
              <img alt="reportedContent" src={usersIconAlt} />
            </IconWrapper>
            <LinkLabel>Tip Jars</LinkLabel>
          </ActiveHoverLink>
        ) : (
          <HoverLink onClick={() => onClick('TipJars')} to="/TipJars">
            <IconWrapper className="icon-wrapper">
              <img alt="reportedContent" src={usersIcon} />
            </IconWrapper>
            <LinkLabel>Tip Jars</LinkLabel>
          </HoverLink>
        )}
        {currentLoc.name === 'PodcastHealth' ? (
          <ActiveHoverLink onClick={() => onClick('PodcastHealth')} to="/PodcastHealth">
            <IconWrapper className="icon-wrapper">
              <img alt="reportedContent" src={usersIconAlt} />
            </IconWrapper>
            <LinkLabel>Podcast Health</LinkLabel>
          </ActiveHoverLink>
        ) : (
          <HoverLink onClick={() => onClick('PodcastHealth')} to="/PodcastHealth">
            <IconWrapper className="icon-wrapper">
              <img alt="reportedContent" src={usersIcon} />
            </IconWrapper>
            <LinkLabel>Podcast Health</LinkLabel>
          </HoverLink>
        )}
      </Wrapper>
    );
  }
}

export default SideNavBar;
