import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styled from 'styled-components';

import {
  server,
  mapStoreToProps,
  CONSTANTS,
  FlexRow,
  FlexItem,
  checkData,
  FlexCol
} from '../assets/CONSTANTS';

const propTypes = {
  data: PropTypes.object
};

const defaultProps = {};

const RoudedImg = styled.img`
  border-radius: 50%;
`;

const UserReporting = styled.h4`
  font-weight: 400;
  font-size: 18px;
  margin: 20px 0px 0px 0px;
`;

const LabelGrey = styled.p`
  margin: 10px 0px 10px 0px;
  color: ${CONSTANTS.color.greyP};
  font-size: 12px;
`;

const MessageContent = styled.p`
  margin: 0px;
`;

const ImgWrapper = styled(FlexItem)`
  max-width: 100px;
`;

const ReportedComment = styled.p`
  margin: 5px 0px 0px 0px;
`;

function CommentContent({ data, userData }) {
  if (userData === null || data === null || userData === undefined || data === undefined) {
    return <></>;
  } else {
    return (
      <FlexCol>
        <UserReporting>
          {data.user_data.first_name} {data.user_data.last_name}
        </UserReporting>
        <LabelGrey>Reported comment</LabelGrey>
        <FlexRow>
          <ImgWrapper flex={1}>
            <RoudedImg
              alt=""
              width="70%"
              src={userData ? `${server}${userData.profile_url}` : ''}
            />
          </ImgWrapper>
          <FlexItem flex={5}>
            <FlexCol>
              <FlexItem>
                <ReportedComment>{data.reported_comment_data.comment}</ReportedComment>
              </FlexItem>
              <FlexItem>
                <LabelGrey>
                  User - {userData.first_name} {userData.last_name}
                </LabelGrey>
              </FlexItem>
            </FlexCol>
          </FlexItem>
        </FlexRow>
        <LabelGrey>Message Content</LabelGrey>
        <MessageContent>{data.comment}</MessageContent>
      </FlexCol>
    );
  }
}

function ReviewContent({ data, userData }) {
  if (userData === null || data === null || userData === undefined || data === undefined) {
    return <></>;
  } else {
    return (
      <FlexCol>
        <UserReporting>
          {data.user_data.first_name} {data.user_data.last_name}
        </UserReporting>
        <LabelGrey>Reported review</LabelGrey>
        <FlexRow>
          <ImgWrapper flex={1}>
            <RoudedImg alt="" width="70%" src={`${server}${userData.profile_url}`} />
          </ImgWrapper>
          <FlexItem flex={5}>
            <FlexCol>
              <FlexItem>
                <ReportedComment>{data.reported_review_data.review_text}</ReportedComment>
              </FlexItem>
              <FlexItem>
                <LabelGrey>
                  User - {userData.first_name} {userData.last_name}
                </LabelGrey>
              </FlexItem>
            </FlexCol>
          </FlexItem>
        </FlexRow>
        <LabelGrey>Message Content</LabelGrey>
        <MessageContent>{data.comment}</MessageContent>
      </FlexCol>
    );
  }
}

function UserContent({ data, userData }) {
  if (userData === null || data === null || userData === undefined || data === undefined) {
    return <></>;
  } else {
    return (
      <FlexCol>
        <UserReporting>
          {data.reporting_user.first_name} {data.reporting_user.last_name}
        </UserReporting>
        <LabelGrey>Reported User</LabelGrey>
        <FlexRow>
          <ImgWrapper flex={1}>
            <RoudedImg alt="" width="70%" src={data.reported_user.image} />
          </ImgWrapper>
          <FlexItem flex={5}>
            <FlexCol>
              <FlexItem>
                <ReportedComment>
                  {data.reported_user.first_name} {data.reported_user.last_name}
                </ReportedComment>
              </FlexItem>
              <FlexItem>
                <LabelGrey>User email - {data.reported_user.email}</LabelGrey>
              </FlexItem>
            </FlexCol>
          </FlexItem>
        </FlexRow>
        <LabelGrey>Message Content</LabelGrey>
        <MessageContent>{data.comment}</MessageContent>
      </FlexCol>
    );
  }
}

class SelectedContentInfo extends React.Component {
  render() {
    if (this.props.reported_content.clickedResult !== null && this.props.reported_user !== null) {
      var { data } = this.props.reported_content.clickedResult;
      if (this.props.reported_user !== null) {
        var userData = this.props.reported_user;
      }
      let dataType = checkData(data);
      switch (dataType) {
        case 'comment': {
          return <CommentContent data={data} userData={userData} />;
        }
        case 'user': {
          return <UserContent data={data} userData={userData} />;
        }
        case 'review': {
          return <ReviewContent data={data} userData={userData} />;
        }
        default: {
          return <div>No Content Found</div>;
        }
      }
    } else {
      return <></>;
    }
  }
}

export default connect(mapStoreToProps)(SelectedContentInfo);

SelectedContentInfo.propTypes = propTypes;
SelectedContentInfo.defaultProps = defaultProps;
