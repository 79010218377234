import React, { useState } from 'react';
import styled from 'styled-components';

import InputGroup from './InputGroup';

import {
  CONSTANTS, server, FlexItem, SettingsDropdown, isValidEmail,
} from '../assets/CONSTANTS';
import { getAuthUser } from '../firebase';

const { color } = CONSTANTS;

const ErrorMsg = styled.p`
  color: ${CONSTANTS.color.red};
  text-align: left;
`;

const SectionHeading = styled.h1`
  font-weight: bold;
  font-size: 18px;
  padding: ${(props) => (props.padding ? props.padding : '5px 0px;')};
  margin: 20px 0px 20px 0px;
  text-align: left;
`;

const ActionButton = styled.button`
  color: black;
  width: 100%;
  background-color: ${color.yellow};
  border: 1px solid ${color.yellow};
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  min-height: 45px;
  font-weight: bold;
  margin: 20px auto;
  padding: ${(props) => (props.padding ? props.padding : '5px 0px;')};
  transition: 0.15s all ease-in-out;
  &:hover {
    background-color: ${color.yellowP};
    border: 1px solid ${color.yellowP};
  }
`;

const FlexItemPadded = styled(FlexItem)`
  padding: 10px 15px !important;
  box-sizing: border-box;
  margin-bottom: 25px;
`;
const Wrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 600;
`;
const Modal = styled.div`
  background-color: ${CONSTANTS.color.whiteP};
  position: absolute;
  top: calc(50vh - 150px);
  left: calc(50vw - 20%);
  max-width: 40%;
  width: 40%;
  min-width: 280px;
  padding: 40px;
  text-align: center;
`;
const Heading = styled.h1`
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.4rem;
`;
const Message = styled.p``;

const CloseButton = styled.button`
  padding: 10px 25px;
  border-radius: 4px;
  border: none;
  background-color: ${CONSTANTS.color.yellowP};
  font-weight: bold;
  width: 80%;
  outline: none;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 50px;
  margin-top: 30px;
`;

const ConfirmButton = styled(CloseButton)`
  background-color: ${CONSTANTS.color.redDarkDB};
`;

const ReplaceUserAuth = ({ userId }) => {
  const [searchTerm, setSearchTerm] = useState({ value: '', error: '' });
  const [emailConfirm, setEmailConfirm] = useState({ value: '', error: '' });
  const [replaceUserAuthError, setReplaceUserAuthError] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const [userList, setUserList] = useState(null);
  const [userListLoading, setUserListLoading] = useState(null);

  const handleUpdateAuth = () => {
    if (selectedUser) {
      const goodUserId = userId;
      const badUserId = selectedUser.id;
      if (!goodUserId || !badUserId) {
        setReplaceUserAuthError('Select User.');
      } else if (goodUserId === badUserId) {
        setReplaceUserAuthError('Can\'t select the same user.');
      } else {
        setModalActive(true);
        setReplaceUserAuthError('');
      }
    }
  };

  const getUsers = (term) => {
    setUserListLoading(true);
    const currUser = getAuthUser();
    currUser.getIdToken(false).then((firebaseToken) => {
      const url = `${server}v2/admin/user-search?limit=1&searchterm=${term}`;
      return fetch(url, {
        method: 'GET',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.results) {
            setUserList(data.results);
            setUserListLoading(false);
          }
        })
        .catch((error) => {
          setUserListLoading(false);
          console.log(error);
        });
    }).catch((err) => {
      setUserListLoading(false);
      console.log(err);
    });
  };

  const replaceUserAuth = async (goodUserId, badUserId) => {
    const currUser = getAuthUser();
    const firebaseToken = await currUser.getIdToken(false);
    const url = `${server}v2/admin/replace-user-login?bad_user_profile_id=${badUserId}&good_user_profile_id=${goodUserId}`;

    return fetch(url, {
      method: 'GET',
      headers: {
        DjangoAuth: `Bearer ${firebaseToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => console.log(error));
  };

  const handleConfirmAuthChange = async () => {
    if (emailConfirm.value !== selectedUser.user_email) {
      setEmailConfirm((prevState) => ({ ...prevState, error: 'Email does not match' }));
    } else {
      setEmailConfirm((prevState) => ({ ...prevState, error: '' }));
      await replaceUserAuth(userId, selectedUser.id).catch(() => setEmailConfirm((prevState) => ({ ...prevState, error: 'Something went wrong' })));
      setModalActive(false);
    }
  };

  const handleGetUsers = () => {
    if (!isValidEmail(searchTerm.value)) {
      setSearchTerm((prevState) => ({ ...prevState, error: 'Not a valid email.' }));
    } else {
      setSearchTerm((prevState) => ({ ...prevState, error: '' }));
      getUsers(searchTerm.value);
    }
  };

  const handleInputChange = (inputName, e) => {
    if (inputName === 'searchTerm') {
      const newValue = e.target.value;
      setSearchTerm((prevState) => ({ ...prevState, value: newValue }));
    }
    if (inputName === 'confirmEmail') {
      const newValue = e.target.value;
      setEmailConfirm((prevState) => ({ ...prevState, value: newValue }));
    }
  };

  const handleOptionClick = (user) => {
    setSelectedUser(user);
  };

  return (
    <FlexItemPadded
      textAlign="center"
      bgColor={color.whiteP}
    >
      {modalActive
          && (
            <Wrapper>
              <Modal>
                <Heading>Warning !</Heading>
                <Message>
                  Doing this will take the authentication off the account of the email you just entered and add it to the account for the page you are on.  This will keep all the listens, content and the username for the persons page you are now on, and they will then be able to sign in with the authentication method from this other account you are now entering ie., Apple ID, twitter etc.,
                </Message>
                <Message>
                  Re Enter the users email to confirm.
                </Message>
                <InputGroup
                  onChange={handleInputChange}
                  placeholder="user@goodpods.com"
                  inputName="confirmEmail"
                  type="text"
                  label="Email"
                  value={emailConfirm.value}
                  error={!!emailConfirm.error}
                />
                {emailConfirm.error && (
                <ErrorMsg>
                  *
                  {emailConfirm.error}
                </ErrorMsg>
                )}
                <ButtonWrapper>
                  <CloseButton
                    onClick={() => setModalActive(false)}
                  >
                    Cancel
                  </CloseButton>
                  <ConfirmButton onClick={handleConfirmAuthChange}>Yes. Update Their Authentication</ConfirmButton>
                </ButtonWrapper>
              </Modal>
            </Wrapper>
          )}
      <SectionHeading>Replace Authentication</SectionHeading>
      <form onSubmit={(e) => e.preventDefault()}>
        <InputGroup
          onChange={handleInputChange}
          placeholder="user@goodpods.com"
          inputName="searchTerm"
          type="text"
          label="Email"
          value={searchTerm.value}
          error={!!searchTerm.error}
        />
        {userListLoading && <p>Loading...</p>}
        <ActionButton onClick={handleGetUsers}>Search User</ActionButton>
        {searchTerm.error ? (
          <ErrorMsg>
            *
            {searchTerm.error}
          </ErrorMsg>
        ) : ''}
        <SectionHeading>Choose User</SectionHeading>
        <SettingsDropdown>
          <option>{userList && userList.length > 0 ? 'Choose User' : 'Enter Email Above'}</option>
          {userList && userList.length > 0
            && userList.map((user) => (
              <option key={user.id} onClick={() => handleOptionClick(user)}>
                {user.user_email}
              </option>
            ))}
        </SettingsDropdown>
        <ActionButton onClick={handleUpdateAuth}>Update Authentication</ActionButton>
        {replaceUserAuthError && (
          <ErrorMsg>
            *
            {replaceUserAuthError}
          </ErrorMsg>
        )}
      </form>
    </FlexItemPadded>
  );
};

export default ReplaceUserAuth;
