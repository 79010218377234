// React & React Router
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Custom Components

// Assets & Styles
import CONSTANTS, { mapStoreToProps, FlexRow } from '../assets/CONSTANTS';
import logo from '../assets/HeaderBar/logo.jpg';
import searchIcon from '../assets/HeaderBar/search.png';

// import "./css/HeaderBar.scss";

const Wrapper = styled.div`
  min-height: 50px;
  height: 100px;
  max-height: 100px;
`;

const HeaderLeft = styled.div`
  display: flex;
  flex: 5;
  background-color: ${CONSTANTS.color.whiteP};
`;

const HeaderRight = styled.div`
  flex: 8;
  background-color: ${CONSTANTS.color.whiteP};
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 20px;
`;

const LogoWrapper = styled.div`
  flex: 1;
  justify-content: center;
  vertical-align: middle;
  max-width: 250px !important;
`;
const BreadcrumbWrapper = styled.div`
  padding-left: 25px;
  flex: 1;
  justify-content: center;
  vertical-align: middle;
`;
const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  color: ${CONSTANTS.color.darkP};
`;
const Breadcrumb = styled.h3`
  line-height: 100px;
  margin: 0px;
  padding: 0px 0px;
  font-weight: 300;
`;

const SearchIcon = styled.img`
  position: absolute;
  top: 6px;
  left: 6px;
`;

const SearchInput = styled.input`
  height: 30px;
  border: none;
  padding-left: 32px;
  padding-right: 20px;
  background-color: transparent;
  outline: none;
  width: calc(100% - 45px);
`;
const InputWrapper = styled.div`
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 55px;
  min-width: 225px;
  margin-right: 25px;
`;
const FadedText = styled.span`
  color: rgba(0, 0, 0, 0.5);
`;

const ProfileLink = styled(Link)`
  margin: 0px 30px;
`;

const LoggedInUser = styled.p``;
const Logo = styled.img`
  padding: 20px;
  margin-top: 2px;
  max-width: 250px;
  min-width: 250px;
  `;

const Spinner = styled.div`
  margin-right: 16px;
  border: 4px solid ${CONSTANTS.color.greyP};
  border-top: 4px solid ${CONSTANTS.color.whiteP};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin .6s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

class HeaderBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchVal: this.props.searchValue,
    };
  }

  render() {
    const { name = '', path } = this.props.breadcrumb;
    const {
      user_info,
      onSearchChange,
      onSearchSubmit,
      onHeaderNavClick,
      searchType,
      searchLoading,
    } = this.props;

    return (
      <Wrapper>
        <FlexRow>
          <HeaderLeft>
            <LogoWrapper>
              <Link onClick={() => onHeaderNavClick('Home')} to="/">
                <Logo src={logo} alt="goodpods" />
              </Link>
            </LogoWrapper>
            <BreadcrumbWrapper>
              <BreadcrumbLink to={path}>
                <Breadcrumb>{name}</Breadcrumb>
              </BreadcrumbLink>
            </BreadcrumbWrapper>
          </HeaderLeft>
          <HeaderRight>
            <ProfileLink onClick={() => onHeaderNavClick('Settings')} to="/Settings">
              Profile
            </ProfileLink>
            <LoggedInUser>
              Logged in as:
              {' '}
              <FadedText>
                { user_info ? `${user_info.first_name} ${user_info.last_name}` : ''}
              </FadedText>
            </LoggedInUser>
            <InputWrapper>
              <SearchIcon src={searchIcon} alt="search" />
              <SearchInput
                onChange={(e) => onSearchChange(e)}
                onKeyPress={(e) => onSearchSubmit(e)}
                placeholder={`Search ${searchType}`}
                type="text"
              />
            </InputWrapper>
            {searchLoading && <Spinner />}
          </HeaderRight>
        </FlexRow>
      </Wrapper>
    );
  }
}

HeaderBar.defaultProps = {
  user_info: null,
};

export default connect(mapStoreToProps)(HeaderBar);
