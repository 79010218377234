import React from 'react';
import { connect } from 'react-redux';
// Custom components
import styled from 'styled-components';

// Assets & Styles
import {
  server,
  mapStoreToProps,
} from '../assets/CONSTANTS';


// Custom Components
import TableView from '../components/TableView';

// Assets & Styles
import TestData from '../../src/data.json';
import { getAuthUser } from '../firebase';

const Spacer = styled.div`
  padding: 10px 0px;
  margin: 5px 0px;
  min-height: 1px;
`;

class CuratedLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        allCuratedLists: [
          {
            "id": 0,
            "title": "Loading...",
            "user_full_name": "Loading...",
            "user_id": 0,
            "is_recommended": false,
            "is_featured": false,
          }
        ]
    };

    this.refreshCuratedListData = this.refreshCuratedListData.bind(this);
  }

  componentDidMount() {
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {

      const detailedUrl = `${server}v2/curated-list/search?sort_order=recent&limit=1000`;
      fetch(detailedUrl, {
        method: 'GET',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => this.setState({ allCuratedLists: data }))
        .catch((err) => console.log(err));
    }).catch((err) => console.log(err));
  }

  refreshCuratedListData() {
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      const detailedUrl = `${server}v2/curated-list/search?sort_order=recent&limit=1000`;
      fetch(detailedUrl, {
        method: 'GET',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => this.setState({ allCuratedLists: data }))
        .catch((err) => console.log(err));
    }).catch((err) => console.log(err));
  }

  render() {
    return (
      <div>
        <TableView
          tableTitle="Manage Curated Lists"
          linkText=""
          linkPath="/"
          tableTopBar={true}
          rowData={this.state.allCuratedLists}
          headerData={TestData.headers.curatedlists}
          rowType="curatedlists"
          token={this.props.token}
          changeNotificationData={this.refreshCuratedListData}
        />

        <Spacer />

      </div>
    );
  }
}

export default connect(mapStoreToProps)(CuratedLists);
