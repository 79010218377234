import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { SettingsTextarea } from '../assets/CONSTANTS';

const Label = styled.p`
  color: black;
  margin-bottom: 0;
  margin-top: 0;
  padding: 5px 0px;
  text-transform: uppercase;
  font-size: 13px;
`;

const Textarea = styled(SettingsTextarea)`
  color: 'black';
  padding-left: 10px;
  font-size: 14px;
`;

const Wrapper = styled.div`
  text-align: left;
  padding: 5px 0px;
`;

function TextareaGroup({
  label,
  inputName,
  placeholder,
  minlength,
  maxlength,
  onChange,
  onKeyPress,
  value = '',
  rows,
}) {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <Textarea
        onKeyPress={(e) => onKeyPress(e)}
        onChange={(e) => onChange(inputName, e)}
        placeholder={placeholder}
        value={value}
        rows={rows}
      />
    </Wrapper>
  );
}

TextareaGroup.propTypes = {
  label: PropTypes.string,
  inputName: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  value: PropTypes.string,
  rows: PropTypes.number,
};

TextareaGroup.defaultProps = {
  onKeyPress: () => {},
};

export default TextareaGroup;
