import React, { Component } from 'react';

import SelectedContentHeader from './SelectedContentHeader';
import SelectedContentInfo from './SelectedContentInfo';

import SelectedContentActions from './SelectedContentActions';

class ReportedContentDisplay extends Component {
  render() {
    return (
      <div>
        <SelectedContentHeader />
        <SelectedContentInfo />
        <SelectedContentActions
          openModal={this.props.openModal}
          updateList={this.props.updateList}
        />
      </div>
    );
  }
}

export default ReportedContentDisplay;
