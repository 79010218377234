import React from 'react';
import { connect } from 'react-redux';
// Custom components
import NotifyNewFeature from '../components/NotifyNewFeature';
import styled from 'styled-components';
import DataBanner from '../components/DataBanner';

//Assets & Styles
import {
  FlexRow,
  server,
  mapStoreToProps,
  CONSTANTS,
  Flex3,
  FlexMid,
  MobileStackFlexWrapper
} from '../assets/CONSTANTS';

import personIcon from '../assets/SideNav/usersIconAlt.svg';

//Custom Components
import TableView from '../components/TableView';

//Assets & Styles
import TestData from '../../src/data.json';
import { getAuthUser } from '../firebase';

const Spacer = styled.div`
  padding: 10px 0px;
  margin: 5px 0px;
  min-height: 1px;
`;

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationDetailedStats: [
          {
            "name": "Loading...",
            "unique_users": "Loading...",
            "total_sent": "Loading...",
            "read_percent": "Loading..."
          }
        ],
        runningCampaigns: [
          {
            "campaign_id": 1,
            "campaign_name": "Loading...",
            "status": "Loading...",
            "frequency": "Loading...",
            "next_send": "Loading...",
            "last_send": "Loading...",
          }
        ],
        genericStats:
      {
        "total_users": "Loading...",
        "total_device_users": "Loading...",
        "total_non_device_users": "Loading..."
      }
    };

    this.getNewNotificationData = this.getNewNotificationData.bind(this);
    this.refreshCampaignData = this.refreshCampaignData.bind(this);
  }

  componentDidMount() {
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      // Get generic notification statistics
  
      const genericUrl = `${server}v2/admin-notifications/get-generic-notification-statistics`;
      fetch(genericUrl, {
        method: 'GET',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) =>
          this.setState({ genericStats: data })
        )
        .catch((err) => console.log(err));
  
      // Get detailed notification statistics
  
      const detailedUrl = `${server}v2/admin-notifications/get-detailed-notification-statistics`;
      fetch(detailedUrl, {
        method: 'GET',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) =>
          this.setState({ notificationDetailedStats: data })
        )
        .catch((err) => console.log(err));
  
        const url = `${server}v2/admin-notifications/all-notification-campaigns`;
        fetch(url, {
          method: 'GET',
          headers: {
            DjangoAuth: `Bearer ${firebaseToken}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            this.setState({runningCampaigns: data});
          }
            
          )
          .catch((err) => console.log(err));
    }).catch((err) => console.log(err));

  }

  refreshCampaignData() {
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      const url = `${server}v2/admin-notifications/all-notification-campaigns`;
      fetch(url, {
        method: 'GET',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.setState({runningCampaigns: data});
        }
          
        )
        .catch((err) => console.log(err));
    }).catch((err) => console.log(err));
  }


  getNewNotificationData(daystoLoad) {

    if (daystoLoad === undefined || daystoLoad === null) {
      daystoLoad = 0;
    }
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      const url = `${server}v2/admin-notifications/get-detailed-notification-statistics?lastXDays=${daystoLoad}`;
      fetch(url, {
        method: 'GET',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.length === undefined) {
            this.setState({
              notificationDetailedStats: [
                {
                  "name": "All Notifications",
                  "unique_users": 0,
                  "total_sent": 0,
                  "read_percent": "0"
                }
              ]
            });
          } else {
            this.setState({ notificationDetailedStats: data });
          }
        }
        )
        .catch((err) => console.log(err));
    }).catch((err) => console.log(err));

  }

  render() {
    return (
      <div>

        <MobileStackFlexWrapper>
          <Flex3>
            <DataBanner
              wrapperBackground={CONSTANTS.color.greenDB}
              iconBackground={CONSTANTS.color.greenDarkDB}
              dataIcon={personIcon}
              dataValue={this.state.genericStats.total_device_users}
              dataLabel="Enabled Push Notifications"
            />
          </Flex3>
          <FlexMid>
          <DataBanner
              wrapperBackground={`${CONSTANTS.color.blueDB}`}
              iconBackground={`${CONSTANTS.color.blueDarkDB}`}
              dataIcon={personIcon}
              dataValue={this.state.genericStats.total_users}
              dataLabel="Total Users"
            />
          </FlexMid>
          <Flex3>
            <DataBanner
              wrapperBackground={CONSTANTS.color.redDB}
              iconBackground={CONSTANTS.color.redDarkDB}
              dataIcon={personIcon}
              dataValue={this.state.genericStats.total_non_device_users}
              dataLabel="Disabled Push Notifications"
            />
          </Flex3>
        </MobileStackFlexWrapper>

        <Spacer />

        <TableView
          tableTitle="Notification Stats"
          linkText=""
          linkPath="/"
          tableTopBar={true}
          rowData={this.state.notificationDetailedStats}
          headerData={TestData.headers.notifications}
          rowType="notifications"
          token={this.props.token}
          changeNotificationData={this.getNewNotificationData}
        />
      <Spacer/>
      <TableView
          tableTitle="Running Campaigns"
          linkText=""
          linkPath="/"
          tableTopBar={true}
          rowData={this.state.runningCampaigns}
          headerData={TestData.headers.campaigns}
          rowType="campaigns"
          token={this.props.token}
          changeNotificationData={this.refreshCampaignData}
        />

        <Spacer />

        <FlexRow>
          <NotifyNewFeature token={this.props.token} />
        </FlexRow>

      </div>
    );
  }
}

export default connect(mapStoreToProps)(Notifications);
