import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SectionTopBar from '../components/SectionTopBar';

import ReportedCommentItem from './ReportedCommentItem';
import ReportedUserItem from './ReportedUserItem';
import ReportedReviewItem from './ReportedReviewItem';

import { CONSTANTS, ContentContainer } from '../assets/CONSTANTS';

const propTypes = {
  dataPacket: PropTypes.array,
  title: PropTypes.string,
  linkPath: PropTypes.string,
  onClick: PropTypes.func
};

const defaultProps = {
  dataPacket: [],
  title: '',
  linkPath: ''
};

const LeftWrapper = styled(ContentContainer)`
  margin-right: 5px;
  border-bottom: 1px solid ${CONSTANTS.color.greyP};
  padding: 0px;
  max-height: 80vh;
  overflow-y: scroll;
`;

const UnreadCommentItem = styled(ReportedCommentItem)`
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  &:hover {
    background-color: red;
    border: 1px solid grey;
  }
`;

const UnreadReviewItem = styled(ReportedReviewItem)`
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  &:hover {
    background-color: red;
    border: 1px solid grey;
  }
`;

const UnreadUserItem = styled(ReportedUserItem)`
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  &:hover {
    background-color: red;
    border: 1px solid grey;
  }
`;

export default class UnreadRC extends React.Component {
  generateItems(data) {
    // TODO: should be map filter, leaving for now
    // eslint-disable-next-line array-callback-return
    let r_data = data.map(item => {
      if (item.unread === true) {
        // currently report type of comment and review are unsupported and will never be returned
        if (item.reported_comment_data != null) {
          return <UnreadCommentItem onClick={this.props.onClick} key={item.id} dataPacket={item} />;
        } else if (item.reported_review_data != null) {
          return <UnreadReviewItem onClick={this.props.onClick} key={item.id} dataPacket={item} />;
        } else if (item.reported_user != null) {
          return <UnreadUserItem onClick={this.props.onClick} key={item.id} dataPacket={item} />;
        }
      } else {
        return '';
      }
    });
    return r_data;
  }

  render() {
    const { title, linkPath, dataPacket } = this.props;
    return (
      <LeftWrapper>
        <SectionTopBar title={title} linkPath={linkPath} />
        {this.generateItems(dataPacket)}
      </LeftWrapper>
    );
  }
}

UnreadRC.propTypes = propTypes;
UnreadRC.defaultProps = defaultProps;
