import React from 'react';
import styled from 'styled-components';

import {
  FlexRow,
  VCenteredContent,
  CONSTANTS,
  VCenteredImage
} from '../assets/CONSTANTS';

import searchIcon from '../assets/HeaderBar/search.png';
import cancelButtonIcon from '../assets/cancelButton.svg' 

import { convertDateToHowLongAgoString } from '../timeUtility';

const TableLink = styled.a`
  text-decoration: none;
  color: ${CONSTANTS.color.linkBlue};
`;

const Row = styled(FlexRow)``;

const Wrapper = styled.div`
  background-color: white;
  padding: 30px;
`;

const Heading = styled.p`
  text-transform: uppercase;
  color: ${CONSTANTS.color.blackA70};
  font-size: 1rem;
  cursor: pointer;
  user-select: none;
`;

const HeaderRow = styled.div`
  margin-top: 25px;
  display: flex;m
  flex-direction: row;
`;

const TableTitle = styled.span`
  font-size: 2.2rem;
  margin-right: 25px;
`;

const SearchInput = styled.input`
  height: 36px;
  border: none;
  padding-left: 35px;
  padding-right: 20px;
  background-color: transparent;
  outline: none;
  width: calc(100% - 45px);
`;

const InputWrapper = styled.div`
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 55px;
  width: 225px;
  margin-right: 25px;
`;

const SearchIcon = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
`;

const UserIssuesTableRow = ({ 
  dataPacket,  
  headerData, 
  tableTitle, 
  onFilterEpisodes, 
  removeEpisodeHandler 
}) => {

  const columnWidth = 100 / headerData.length;

  const FlexItem = styled.div`
    padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '0px')}; 
    padding-right: ${(props) => (props.paddingRight ? props.paddingRight : '0px')}; 
    width: ${(props) => (props.width ? props.width : `${columnWidth}%`)};
    margin-left: 10px;
    text-align: center;
  `;

  return (
    <Wrapper>
      <TableHeaderWrapper>
        <TableTitle>{tableTitle}</TableTitle>
        <InputWrapper>
          <SearchIcon src={searchIcon} alt="search" />
          <SearchInput
            onChange={(e) => null}
            onKeyPress={onFilterEpisodes}
            placeholder={`Filter Episodes`}
            type="text"
          />
        </InputWrapper>
      </TableHeaderWrapper>
      <HeaderRow>     
        {headerData.map((header, index) => (
          <FlexItem textAlign="center" width={index === 4 ? '30%' : `${columnWidth}%`}>
            <Heading>{header}</Heading>
          </FlexItem>
        ))}
      </HeaderRow> 
      {dataPacket?.map(row => (
        <Row paddedVert tableRowContainer key={row.campaign_id} padded>
          <FlexItem textAlign="center">
            <VCenteredContent>{row?.title}</VCenteredContent>     
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>{row?.unique_issue_count}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>{row?.subscriber_count}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>{convertDateToHowLongAgoString(row?.last_reported)}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center" width={"30%"}>
            <VCenteredContent><TableLink href={row?.audio_url} target="_blank">{row?.audio_url}</TableLink></VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredImage  width="25px" height="25px" alt="" src={cancelButtonIcon} onClick={() => removeEpisodeHandler(row?.episode_id)}/>
          </FlexItem>
        </Row>
      ))}
    </Wrapper>
  )
};

export default UserIssuesTableRow;