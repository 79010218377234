import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CONSTANTS, FlexRow, FlexItem } from '../assets/CONSTANTS';

const Heading = styled.p`
  text-transform: uppercase;
  color: ${CONSTANTS.color.blackA70};
  font-size: 0.9rem;
  cursor: pointer;
  user-select: none;
`;

const FlexItemDynamicHide = styled(FlexItem)`
  @media (max-width: 1850px) {
    display: none;
  }
`;

class TableHeader extends Component {

  generateTableHeaders(tabs) {
    const { headerFor, sortFunction, sortData } = this.props;
    let counter = 0;

    return tabs.map((val, index) => {
      const sortType = val.replace(/ /g, '');
      const direction = sortData.sortOrder === 'DESC' ? '▼' : '▲';
      const sortDir = sortType === sortData.currentField && sortType !== '' ? direction : '';
      let headingFlex = 1;
      counter += 1;

      let ta = 'left';
      if (headerFor === 'notifications' || headerFor === 'campaigns' || headerFor === 'groups') { // center all headings for notification tables
        ta = 'center';
      }
      else if (headerFor !== 'users') {
        ta = index === 1 ? 'left' : 'center';
        if (headerFor === 'verifiedPodcasts') {
          switch (index) {
            case 1:
              headingFlex = 6;
              break;
            default:
              headingFlex = 1;
              break;
          }
        }
      }

      return val === 'Email ID' ? (
        <FlexItemDynamicHide textAlign={ta} key={counter}>
          <HeadingText dir={sortDir} sortFunc={sortFunction} val={val} sortType={sortType} />
        </FlexItemDynamicHide>
      ) : (
          <FlexItem flex={headingFlex} textAlign={ta} key={counter}>
            <HeadingText dir={sortDir} sortFunc={sortFunction} val={val} sortType={sortType} />
          </FlexItem>
        );
    });
  }

  render() {
    const { headers } = this.props;
    return <FlexRow>{this.generateTableHeaders(headers)}</FlexRow>;
  }
}

export function HeadingText({ sortType, sortFunc, val, dir }) {
  return (
    <Heading data-sorttype={sortType} onClick={(e) => sortFunc(e)}>
      {`${val} ${dir}`}
    </Heading>
  );
}

HeadingText.propTypes = {
  sortType: PropTypes.string.isRequired,
  sortFunc: PropTypes.func,
  val: PropTypes.string.isRequired,
  dir: PropTypes.string,
};
HeadingText.defaultProps = {
  sortFunc: function sortFunc() {
    return '';
  },
  dir: '',
};

TableHeader.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  headerFor: PropTypes.string,
  sortFunction: PropTypes.func,
  sortData: PropTypes.objectOf(PropTypes.string),
};

TableHeader.defaultProps = {
  headers: [''],
  headerFor: '',
  sortFunction: () => { },
  sortData: {},
};

export default TableHeader;
