import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Custom Components
import TableHeader from './TableHeader';
import PodcastRow from './PodcastRow';
import FPodcastRow from './FPodcastRow';
import EpisodeRow from './EpisodeRow';
import NotificationRow from './NotificationRow';
import CampaignRow from './CampaignRow';
import GroupRow from './GroupRow';
import CuratedListRow from './CuratedListRow';

import UserRow from './UserRow';
import TipJarRow from './TipJarRow';

// Assets & Styles
import {
  CONSTANTS, TabLink, FlexRow, FlexItem,
} from '../assets/CONSTANTS';
import BackArrowImg from '../assets/BackArrow.svg';
import './css/TableView.scss';
import AddVerifiedPodcastRow from './AddVerifiedPodcastRow';

const Wrapper = styled.div`
  margin: 10px 25px;
  background-color: #f1f3f6;
  border-radius: 4px;
  padding-left: 15px !important;
`;

const TableLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  color: ${CONSTANTS.color.linkBlue};
  &:visited {
    color: ${CONSTANTS.color.linkBlue};
  }
`;

const BackArrow = styled.img`
  margin-right: 35px;
`;
const TableTitle = styled.span`
  font-size: 2.2rem;
  margin-right: 25px;
`;
const TableInfo = styled.span`
  font-size: 1rem;
  margin-left: 50px;
  color: ${CONSTANTS.color.greyP};
`;
const RedDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  border: none;
  display: inline-block;
  margin-right: 5px;
`;
const FilterButton = styled.button`
  padding: 10px 25px;
  border: 1px solid ${CONSTANTS.color.yellowP};
  border-radius: 4px;
  width: 15%;
  outline: none;
  cursor: pointer;
  font-size: 1.05rem;
  transition: 0.15s all ease-in-out;
  &:hover {
    background-color: ${CONSTANTS.color.yellowP};
  }
`;

const RefreshButton = styled.button`
  padding: 10px 25px;
  border: 1px solid ${CONSTANTS.color.yellowP};
  border-radius: 4px;
  width: 200px;
  outline: none;
  cursor: pointer;
  font-size: 1.05rem;
  transition: 0.15s all ease-in-out;
  &:hover {
    background-color: ${CONSTANTS.color.yellowP};
  }
`;
const FilterInput = styled.input`
  padding: 10px 15px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  border-radius: 4px;
  margin-right: 15px;
  width: 25%;
  font-size: 1.05rem;
  outline: none;
`;

function TableView({
  headerData,
  rowData,
  singleRowData,
  rowType,
  allUsers,
  episodeSearch,
  chosenEp,
  onInputChange,
  onInputSubmit,
  sortData,
  sortFunction,
  tableTitle,
  tableTopBar,
  openModal,
  onRemove,
  parentPod,
  linkPath,
  linkText,
  addClaimedPod,
  addVerifiedPod,
  changeNotificationData,
  token,
}) {
  const [days, setDays] = useState(0);

  if (tableTopBar === true) {
    return (
      <div className="tableView-wrapper">
        <div className="table-top-header">
          <div>
            {allUsers ? (
              <FlexRow>
                <FlexItem flex={1}>
                  <TabLink to="/Users">
                    <BackArrow alt="backArrow" src={BackArrowImg} />
                  </TabLink>
                  <TableTitle>{tableTitle}</TableTitle>
                  <TableInfo>
                    <RedDot />
                    Users Deactivated by Admin
                  </TableInfo>
                </FlexItem>
              </FlexRow>
            ) : (
              <div>
                {rowType === 'notifications'
                  ? (
                    <div>
                      <TableTitle>{tableTitle}</TableTitle>
                      <FilterInput
                        type="number"
                        placeholder="Filter to last x days..."
                        onChange={(e) => setDays(e.target.value)}
                      />
                      <RefreshButton
                        onClick={() => changeNotificationData()}
                      >
                        See last
                        {' '}
                        {days}
                        {' '}
                        days
                      </RefreshButton>
                    </div>
                  ) : (
                    <div>
                      <TableTitle>{tableTitle}</TableTitle>
                    </div>
                  )}
              </div>
            )}
          </div>
          <div>
            {allUsers || episodeSearch ? (
              <>
                <FilterInput
                  onChange={(e) => onInputChange(e)}
                  type="text"
                  placeholder="Search Users"
                />
                <FilterButton onClick={() => onInputSubmit()}>Filter</FilterButton>
              </>
            ) : (
              <TableLink to={linkPath}>{linkText}</TableLink>
            )}
          </div>
        </div>
        {rowType !== 'users' ? (
          <div className="table-data-header">
            <TableHeader
              sortFunction={sortFunction}
              sortData={sortData}
              headerFor={rowType}
              headers={headerData}
            />
          </div>
        ) : (
          <Wrapper className="table-data-header">
            <TableHeader
              sortFunction={sortFunction}
              sortData={sortData}
              headerFor={rowType}
              headers={headerData}
            />
          </Wrapper>
        )}

        <div className="table-data-rows">
          {(() => {
            switch (rowType) {
              case 'episode':
                return (
                  <EpisodeRow
                    openModal={openModal}
                    parentPod={parentPod}
                    dataPacket={rowData}
                    chosenEp={chosenEp}
                  />
                );
              case 'users':
                return <UserRow dataPacket={rowData} />;
              case 'notifications':
                return <NotificationRow dataPacket={rowData} />;
              case 'campaigns':
                return (
                  <CampaignRow
                    dataPacket={rowData}
                    token={token}
                    changeNotificationData={changeNotificationData}
                  />
                );
              case 'groups':
                return (
                  <GroupRow
                    dataPacket={rowData}
                    token={token}
                    changeNotificationData={changeNotificationData}
                  />
                );
              case 'curatedlists':
                return (
                  <CuratedListRow
                    dataPacket={rowData}
                    token={token}
                    changeNotificationData={changeNotificationData}
                  />
                );
              case 'tipjars':
                return (
                  <TipJarRow
                    dataPacket={rowData}
                    token={token}
                    changeNotificationData={changeNotificationData}
                  />
                );
              case 'podcasts':
                return <PodcastRow parentPod={parentPod} dataPacket={rowData} />;
              case 'verifiedPodcasts':
                return (
                  <AddVerifiedPodcastRow
                    addVerifiedPod={addVerifiedPod}
                    dataPacket={rowData}
                    addClaimedPod={addClaimedPod}
                  />
                );
              case 'fpodcast':
                return (
                  <FPodcastRow onRemove={onRemove} openModal={openModal} dataPacket={rowData} />
                );
              default:
                console.log('default case');
                return null;
            }
          })()}
        </div>
      </div>
    );
  }

  return (
    <div className="tableView-wrapper">

      <div className="table-data-header">
        <TableHeader
          sortFunction={sortFunction}
          sortData={sortData}
          headerFor={rowType}
          headers={headerData}
        />
      </div>

      <div className="table-data-rows">
        {(() => {
          switch (rowType) {
            case 'episode':
              return (
                <EpisodeRow
                  openModal={openModal}
                  parentPod={parentPod}
                  dataPacket={rowData}
                  chosenEp={chosenEp}
                />
              );
            case 'user':
              return <UserRow dataPacket={rowData} />;
            case 'podcast':
              return <PodcastRow parentPod={parentPod} dataPacket={rowData} />;
            case 'verifiedPodcasts':
              return (
                <AddVerifiedPodcastRow
                  dataPacket={rowData}
                  addClaimedPod={addClaimedPod}
                  addVerifiedPod={addVerifiedPod}
                />
              );
            case 'fpodcast':
              return <FPodcastRow onRemove={onRemove} openModal={openModal} dataPacket={rowData} />;
            default:
              console.log('default case');
              return null;
          }
        })()}
      </div>
    </div>
  );
}

TableView.propTypes = {
  tableTitle: PropTypes.string,
  linkPath: PropTypes.string,
  linkText: PropTypes.string,
  tableTopBar: PropTypes.bool,
  headerData: PropTypes.arrayOf(PropTypes.string),
  rowData: PropTypes.arrayOf(PropTypes.object),
  rowType: PropTypes.string,
};

TableView.defaultProps = {
  tableTitle: '',
  linkPath: '',
  linkText: '',
  tableTopBar: false,
  headerData: [],
  rowData: [],
  rowType: '',
};

export default TableView;
