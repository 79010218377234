import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  FlexRow,
  FlexItem,
  VCenteredContent,
  server,
  CONSTANTS,
  VCenteredImage,
} from '../assets/CONSTANTS';

import influImg from '../assets/Influencer.svg';
import influAltImg from '../assets/InfluencerAlt.svg';
import { getAuthUser } from '../firebase';

const TableLink = styled(Link)`
  text-decoration: none;
  color: ${CONSTANTS.color.linkBlue};
  &:visited {
    color: ${CONSTANTS.color.linkBlue};
  }
`;

const Wrapper = styled.div`
  padding: 0px 25px;
  div:first-child {
    border-top: none;
  }
`;

const Row = styled(FlexRow)``;

const ModalWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 600;
`;

const Modal = styled.div`
  background-color: ${CONSTANTS.color.whiteP};
  position: absolute;
  top: calc(50vh - 150px);
  left: calc(50vw - 10%);
  max-width: 40%;
  width: 30%;
  min-width: 280px;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
`;


const PointerArea = styled.div`
  cursor: pointer;
  flex-direction: row;
  align-items: center
  justify-content: center;
`

const Heading = styled.h1`
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 10px auto;
  text-align: center;
`;
const ConfirmBtn = styled.button`
  padding: 5px 0px;
  border-radius: 4px;
  border: none;
  background-color: ${CONSTANTS.color.yellowP};
  font-weight: bold;
  margin: 5px auto;
  width: 100%;
  outline: none;
  cursor: pointer;
  width: 40%
  text-align:center;
  padding: 10px 0px;
`;

const CancelBtn = styled(ConfirmBtn)`
  border: 1px solid ${CONSTANTS.color.borderGrey};
  background-color: transparent;
  margin-right:40px;
`;


const PauseBtn = styled(ConfirmBtn)`
  border: 1px solid ${CONSTANTS.color.red};
  background-color: ${CONSTANTS.color.red};
  width: 30%
  padding: 5px 0px;
`;

const LabelMsg = styled.p`
  color: ${CONSTANTS.color.black};
  text-align: center;
  margin-right:20px;
`;


function ConfirmGroupDeleteModal({onCancel, groupId, groupName, onDelete}) {
    
    return (
      <ModalWrapper>
        <Modal>
          <Heading>
            Please Confirm
          </Heading>
          <LabelMsg>Are you sure you want to DELETE the group {groupName}?</LabelMsg>
          <CancelBtn onClick={() => onCancel()}>
            Cancel
          </CancelBtn>
          <ConfirmBtn onClick={() => onDelete(groupId)}>
            Delete Group
          </ConfirmBtn>
        </Modal>
      </ModalWrapper>
    );
  }


class GroupRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editModal: false,
      groupId: 0,
      groupName: '',
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.toggleGroup = this.toggleGroup.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
  }

  handleOpen(groupId, groupName) {
    this.setState({ editModal: true, groupId: groupId, groupName: groupName});
  }

  handleCancel() {
    this.setState({ editModal: false});
  }

  deleteGroup(groupId) {
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      const url = `${server}v2/groups/admin-delete-group`;
      const data = {
        group_id: groupId,
      };
      return fetch(url, {
        method: 'POST',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          this.setState({ editModal: false });
          this.props.changeNotificationData();
        })
        .catch((error) => console.log(error));
    }).catch((err) => console.log(err));
  }

  toggleGroup(groupId, toggleTo) {
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      const url = `${server}v2/groups/toggle-group-recommendation`;
      const data = {
        group_id: groupId,
        is_recommended: toggleTo,
      };
      return fetch(url, {
        method: 'POST',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
        body: JSON.stringify(data)
      })
        .then((res) => res.json())
        .then((data) => {
          this.props.changeNotificationData();
        })
        .catch((error) => console.log(error));
    }).catch((err) => console.log(err));
  }

  generateRowData(rowsData) {
    if (rowsData !== undefined) {
        // alert(JSON.stringify(rowsData));
      return rowsData.map((row) => (
        <Row paddedVert tableRowContainer key={row.campaign_id} padded>
          <FlexItem textAlign="center">
            <VCenteredContent>{row.name}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>
            {row.group_admins.map((admin) => (<><TableLink to={`/Profile/${admin.user_id}`}>@{admin.username}</TableLink><br/></>))}
            </VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>{row.member_count}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>{row.days_since_created+1}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>{row.post_count}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>
                <PointerArea onClick={() => {
                    row.is_recommended = !row.is_recommended; 
                    this.setState({ editModal: false});
                    this.toggleGroup(row.group_id, !row.is_recommended ? false : true);
                }}>
                {row.is_recommended ? (
                    <VCenteredImage circleImg width="20px" height="20px" alt="" src={influImg} />
                ) : (
                    <VCenteredImage circleImg width="20px" height="20px" alt="" src={influAltImg} />
                )}
                </PointerArea>
            </VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>
                <PauseBtn onClick={() => this.handleOpen(row.group_id, row.name)}>
                    Delete
                </PauseBtn>
            </VCenteredContent>
          </FlexItem>
        </Row>
      ));
    }
    return '';
  }

  render() {
    return (
    <div>
        <Wrapper>{this.generateRowData(this.props.dataPacket)}</Wrapper>

        {this.state.editModal ? (
          <ConfirmGroupDeleteModal
            onCancel={this.handleCancel}
            groupId={this.state.groupId}
            groupName={this.state.groupName}
            onDelete={this.deleteGroup}
          />
        ) : (
          ''
        )}
    </div>
    )
  }
}

export default GroupRow;
