import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  FlexRow,
  FlexItem,
  VCenteredContent,
  server,
  CONSTANTS,
  VCenteredImage,
} from '../assets/CONSTANTS';

import influImg from '../assets/Influencer.svg';
import influAltImg from '../assets/InfluencerAlt.svg';
import { getAuthUser } from '../firebase';

const TableLink = styled(Link)`
  text-decoration: none;
  color: ${CONSTANTS.color.linkBlue};
  &:visited {
    color: ${CONSTANTS.color.linkBlue};
  }
`;

const Wrapper = styled.div`
  padding: 0px 25px;
  div:first-child {
    border-top: none;
  }
`;

const Row = styled(FlexRow)``;

const PointerArea = styled.div`
  cursor: pointer;
  flex-direction: row;
  align-items: center
  justify-content: center;
`

const ConfirmBtn = styled.button`
  padding: 5px 0px;
  border-radius: 4px;
  border: none;
  background-color: ${CONSTANTS.color.yellowP};
  font-weight: bold;
  margin: 5px auto;
  width: 100%;
  outline: none;
  cursor: pointer;
  width: 40%
  text-align:center;
  padding: 10px 0px;
`;

const PauseBtn = styled(ConfirmBtn)`
  border: 1px solid ${CONSTANTS.color.red};
  background-color: ${CONSTANTS.color.red};
  width: 30%
  padding: 5px 0px;
`;

class CuratedListRow extends Component {


  toggleListRecommended(list_id, toggleTo) {
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      const url = `${server}v2/admin/set-curated-list-recommended`;
      const data = {
        list_id: list_id,
        is_recommended: toggleTo,
      };
      return fetch(url, {
        method: 'POST',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
        body: JSON.stringify(data)
      })
        .then((res) => res.json())
        .then((data) => {
          this.props.changeNotificationData();
        })
        .catch((error) => console.log(error));
    }).catch((err) => console.log(err));
  }

  toggleListFeatured(list_id, toggleTo) {
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      const url = `${server}v2/admin/set-curated-list-featured`;
      const data = {
        list_id: list_id,
        is_featured: toggleTo,
      };
      return fetch(url, {
        method: 'POST',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
        body: JSON.stringify(data)
      })
        .then((res) => res.json())
        .then((data) => {
          this.props.changeNotificationData();
        })
        .catch((error) => console.log(error));
    }).catch((err) => console.log(err));
  }



  deleteList(list_id) {
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      const url = `${server}v2/admin/delete-curated-list`;
      const data = {
        list_id: list_id,
      };
      return fetch(url, {
        method: 'POST',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          this.props.changeNotificationData();
        })
        .catch((error) => console.log(error));
    }).catch((err) => console.log(err));
  }



  generateRowData(rowsData) {
    if (rowsData !== undefined) {
        // alert(JSON.stringify(rowsData));
      return rowsData.map((row) => (
        <Row paddedVert tableRowContainer key={row.id} padded>
          <FlexItem textAlign="left">
            <VCenteredContent>{row.title}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="left">
            <VCenteredContent>
            <TableLink to={`/Profile/${row.user_id}`}>{row.user_full_name}</TableLink>
            </VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>
                <PointerArea onClick={() => {
                    row.is_recommended = !row.is_recommended; 
                    this.setState({ editModal: false});
                    this.toggleListRecommended(row.id, !row.is_recommended ? false : true);
                }}>
                {row.is_recommended ? (
                    <VCenteredImage circleImg width="20px" height="20px" alt="" src={influImg} />
                ) : (
                    <VCenteredImage circleImg width="20px" height="20px" alt="" src={influAltImg} />
                )}
                </PointerArea>
            </VCenteredContent>
        </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>
                <PointerArea onClick={() => {
                    row.is_featured = !row.is_featured; 
                    this.setState({ editModal: false});
                    this.toggleListFeatured(row.id, !row.is_featured ? false : true);
                }}>
                {row.is_featured ? (
                    <VCenteredImage circleImg width="20px" height="20px" alt="" src={influImg} />
                ) : (
                    <VCenteredImage circleImg width="20px" height="20px" alt="" src={influAltImg} />
                )}
                </PointerArea>
            </VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>
              <PauseBtn onClick={() => this.deleteList(row.id)}>
                Delete
              </PauseBtn>
              </VCenteredContent>
          </FlexItem>
        </Row>
      ));
    }
    return '';
  }

  render() {
    return (
    <div>
        <Wrapper>{this.generateRowData(this.props.dataPacket)}</Wrapper>
    </div>
    )
  }
}

export default CuratedListRow;
