const loginReducer = (store = { token: '', tokenExpiry: '' }, action) => {
  switch (action.type) {
    case 'ADD_USER_INFO': {
      return { ...store, user_info: action.payload };
    }
    case 'LOGOUT': {
      return { ...store, user_info: null };
    }
    default: {
      return store;
    }
  }
};

const RCReducer = (store = { clickedResult: null, clickedRepUser: null }, action) => {
  switch (action.type) {
    case 'ADD_CLICKED_RESULT': {
      return { ...store, clickedResult: action.payload ? action.payload : 'none' };
    }
    case 'ADD_CLICKED_REPUSER': {
      return { ...store, clickedRepUser: action.payload ? action.payload : 'none' };
    }
    default: {
      return store;
    }
  }
};

export { RCReducer, loginReducer };

export default loginReducer;
