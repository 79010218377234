/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  CONSTANTS,
  FlexRow,
  FlexItem,
  VCenteredImage,
  VCenteredDivContent,
  VCenteredContent,
} from '../assets/CONSTANTS';
import influImg from '../assets/Influencer.svg';
import influAltImg from '../assets/InfluencerAlt.svg';

const TableLink = styled(Link)`
  text-decoration: none;
  color: ${CONSTANTS.color.linkBlue};
  &:visited {
    color: ${CONSTANTS.color.linkBlue};
  }
`;

const PaddedLeftFlex = styled(FlexItem)`
  padding-left: 15px;
`;

// const FlexItemDynamicHide = styled(FlexItem)`
//   @media (max-width: 1850px) {
//     display: none;
//   }
// `;

const RedDot = styled.p`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  border: none;
  display: inline-block;
  margin: 0px;
  margin-right: 5px;
`;

const Wrapper = styled.div`
  padding: 0px 25px;
  div:first-child {
    border-top: none;
  }
`;

const Row = styled(FlexRow)``;

class UserRow extends Component {
  generateRowData(rowsData) {
    if (rowsData !== undefined) {
      // alert(JSON.stringify(rowsData));
      return rowsData.map((row) => (
        <Row paddedVert tableRowContainer key={row.id} padded>
          <PaddedLeftFlex textAlign="left">
            {row.is_influencer ? (
              <VCenteredImage circleImg width="20px" height="20px" alt="" src={influImg} />
            ) : (
              <VCenteredImage circleImg width="20px" height="20px" alt="" src={influAltImg} />
            )}
          </PaddedLeftFlex>
          <PaddedLeftFlex textAlign="left">
            {row.is_promoted ? (
              <VCenteredImage circleImg width="20px" height="20px" alt="" src={influImg} />
            ) : (
              <VCenteredImage circleImg width="20px" height="20px" alt="" src={influAltImg} />
            )}
          </PaddedLeftFlex>
          {/* <PaddedLeftFlex textAlign="left">
            {row.is_featured_podcaster ? (
              <VCenteredImage circleImg width="20px" height="20px" alt="" src={influImg} />
            ) : (
              <VCenteredImage circleImg width="20px" height="20px" alt="" src={influAltImg} />
            )}
          </PaddedLeftFlex> */}
          {/* <FlexItem textAlign="left">
            <VCenteredContent>{row.user_good_for}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="left">
            <VCenteredContent>{row.admin_score}</VCenteredContent>
          </FlexItem> */}
          <FlexItem textAlign="left">
            <VCenteredDivContent>
              <span>
                {row.is_deactivated ? <RedDot /> : ''}

                {row.first_name}
              </span>
            </VCenteredDivContent>
          </FlexItem>
          <FlexItem textAlign="left">
            <VCenteredContent>{row.last_name}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="left">
            <VCenteredContent>{row.username}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="left">
            <VCenteredContent>{row.follower_count}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="left">
            <VCenteredContent>{row.following_count}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="left">
            <VCenteredContent>{row.listen_count}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="left">
            <VCenteredContent>
              <TableLink to={`/Profile/${row.id}`}>View</TableLink>
            </VCenteredContent>
          </FlexItem>
        </Row>
      ));
    }
    return '';
  }

  render() {
    return <Wrapper>{this.generateRowData(this.props.dataPacket)}</Wrapper>;
  }
}

UserRow.propTypes = {
  dataPacket: PropTypes.array,
};

export default UserRow;
