// React & React Router
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { addUserInfo, addToken, logout } from '../redux/actions';

// Custom Components
import InputGroup from '../components/InputGroup';

// Assets & Constants
import {
  CONSTANTS,
  LoginButton,
  DefaultLink,
  Logo,
  isValidEmail,
  mapStoreToProps,
  server,
} from '../assets/CONSTANTS';
import logo2 from '../assets/HeaderBar/logo@2x.jpg';
import loginBG from '../assets/Login/adminImage.jpg';
import { signIn } from '../firebase';
import { apiGetRequest } from '../lib/api/utils';

// Styled-components
const LogBTN = styled.button`
  font-weight: bold;
  ${LoginButton}
`;

const ErrorMsg = styled.p`
  color: ${CONSTANTS.color.red};
`;

const Background = styled.div`
  background-image: url(${loginBG});
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 80, 0.5);
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
`;
const Wrapper = styled.div`
  border-radius: 30px;
  position: absolute;
  background-color: #fff;
  width: 350px;
  min-width: 350px;
  left: calc(50% - 175px);
  top: 15%;
  padding: 25px 40px;
  text-align: center;
`;

const APIManager = {
  apiLink: `${server}`,
  clientID: 'UFqoydUSvok4AMwt7wyWbQwgFqLhPTq2Tv4iFpQt',
  clientSecret: 'JhbGa7KprgIaHGiDRbh0DIhY9Rk8kmJXCJo0BxwiJ4KguYNDbMKHxkj5gBD20zkf9YeyLPdp0rqDWKhOm9nClyjvb5WUoPTI88alQj5Ym6fgN6Y6k5N3Dm6Sn4HcSMUO',
  version: '1.5.1',
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      username: null,
      password: null,
      loginError: false,
      errorMsg: null,
      fetching: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
    this.getUserData = this.getUserData.bind(this);
  }

  async getUserData() {
    const url = `${server}v2/user/profile`;
    return apiGetRequest(url)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async handleAddUserInfo() {
    try {
      const userData = await this.getUserData();
      if (userData.is_admin) {
        this.props.addUserInfo(userData);
      } else {
        this.setState({
          password: '',
          loginError: true,
          errorMsg: 'You are not an admin.',
        });
      }
    } catch (err) {
      this.setState({
        password: '',
        loginError: true,
        errorMsg: 'Something went wrong. Couldn\'t get user data.',
      });
    }
  }

  enterPressed(e) {
    const code = e.keyCode || e.which;
    if (code === 13) {
      this.handleSubmit(e);
    }
  }

  loginWithUserNameAndPassword(username, password) {
    signIn(username, password)
      .then(() => {
        this.setState(
          {
            password: '',
            loginError: false,
            errorMsg: null,
          },
          () => {
            this.handleAddUserInfo();
          },
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loginError: true,
          errorMsg: 'Invalid credentials.',
        });
      });
  }

  handleChange(inputName, e) {
    if (inputName === 'password') {
      const password = e.target.value;
      this.setState({ password });
    } else {
      const username = e.target.value;
      this.setState({ username });
    }
  }

  handleSubmit(e) {
    const { password, username } = this.state;
    const emailValid = isValidEmail(username);
    e.preventDefault();
    if (username === null || password === null) {
      // one of the fields is empty
      this.setState({
        password: '',
        loginError: true,
        errorMsg: 'All fields are required.',
      });
    } else if (username.length <= 1 || username.length >= 101) {
      // username too short or long
      this.setState({
        password: '',
        loginError: true,
        errorMsg: 'Username must be between 2 - 100 characters.',
      });
    } else if (password.length <= 5 || password.length >= 51) {
      // password too short or long
      this.setState({
        password: '',
        loginError: true,
        errorMsg: 'Password must be between 6 - 50 characters.',
      });
    } else if (!emailValid) {
      // email invalid
      this.setState({
        password: '',
        loginError: true,
        errorMsg: 'Must be a valid email address.',
      });
    } else {
      // passed validation
      this.setState({ fetching: true });
      this.loginWithUserNameAndPassword(username, password);
      this.setState({
        password: '',
        username: '',
        loginError: false,
        errorMsg: null,
        fetching: false,
      });
    }
  }

  render() {
    return (
      <>
        <Background>
          <Wrapper>
            <Logo alt="goodpods" src={logo2} />
            <p />
            <p>
              Admin Login v
              {APIManager.version}
            </p>
            {this.state.fetching ? (
              <h1>Loading...</h1>
            ) : (
              <>
                <form>
                  <InputGroup
                    onChange={this.handleChange}
                    onKeyPress={this.enterPressed}
                    placeholder="example@goodpods.com"
                    value={this.state.username}
                    inputName="text"
                    type="text"
                    label="Email ID"
                    error={this.state.loginError}
                  />
                  <InputGroup
                    onKeyPress={this.enterPressed}
                    onChange={this.handleChange}
                    placeholder="********"
                    value={this.state.password}
                    inputName="password"
                    type="password"
                    label="Password"
                    error={this.state.loginError}
                  />
                  {this.state.errorMsg ? (
                    <ErrorMsg>
                      *
                      {this.state.errorMsg}
                    </ErrorMsg>
                  ) : ''}
                  <LogBTN type="button" onClick={this.handleSubmit}>
                    Login
                  </LogBTN>
                </form>
                <DefaultLink to="/ForgotPassword">forgot password?</DefaultLink>
              </>
            )}
          </Wrapper>
        </Background>
      </>
    );
  }
}

export default connect(mapStoreToProps, { addToken, addUserInfo, logout })(Login);
