// React & React Router
import React from 'react';

// Custom Components
import PodcastsAll from './PodcastsAll';
import PodcastsFeatured from './PodcastsFeatured';

// Assets & Styles

class Podcasts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    switch (this.props.activeTab) {
      case 'Featured':
        return <PodcastsFeatured />;
      case 'All':
        return <PodcastsAll />;
      default:
        return <PodcastsAll />;
    }
  }
}

export default Podcasts;
