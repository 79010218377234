import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { CONSTANTS, checkData, server, mapStoreToProps } from '../assets/CONSTANTS';
import { apiPostRequest } from '../lib/api/utils';

const propTypes = {
  removeUser: PropTypes.func,
  onClick: PropTypes.func
};

const defaultProps = {};

const ButtonContainer = styled.div`
  padding-top: 50px;
  margin-top: 25px;
  border-top: 1px solid ${CONSTANTS.color.greyP};
  text-align: center;
`;

const RemoveButton = styled.button`
  width: 80%;
  font-weight: bold;
  font-size: 16px;
  min-height: 45px;
  padding: 10px 20px;
  margin: 15px auto;
  background-color: ${CONSTANTS.color.yellowP};
  border: 1px solid ${CONSTANTS.color.yellowP};
  border-radius: 4px;
  cursor: pointer;
  transition: 0.15s all ease;
`;

const DeactivateButton = styled(RemoveButton)`
  background-color: transparent;
  border: 1px solid ${CONSTANTS.color.greyP};
  outline: none;
  &:hover {
    background-color: ${CONSTANTS.color.yellow};
  }
  &:active {
    background-color: ${CONSTANTS.color.yellowP};
  }
`;

class SelectedContentActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onDeactivate = this.onDeactivate.bind(this);
    this.removeUser = this.removeUser.bind(this);
  }

  removeUser() {
    let selectedDataType = checkData(this.props.reported_content.clickedResult.data);
    let data = new FormData();
    let ID = 0;
    let url = `${server}`;
    switch (selectedDataType) {
      // case 'comment': {
      //   ID = this.props.reported_content.clickedResult.data.reported_comment_data.id;
      //   url += `delete-comment`;
      //   data.append('comment_id', ID);
      //   break;
      // }
      // case 'review': {
      //   ID = this.props.reported_content.clickedResult.data.reported_review_data.id;
      //   url += `delete-review`;
      //   data.append('review_id', ID);
      //   break;
      // }
      case 'user': {
        ID = this.props.reported_content.clickedResult.data.reported_user.profile_id;
        url += `delete-user-account`;
        data.append('user_id', ID);
        break;
      }
      default: {
      }
    }

    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: this.props.token
      },
      body: data
    })
      .then(res => res.json())
      .then(data => {
        this.updateRCReadStatus();
        console.log(data);
      })
      .catch(error => console.log(error));
  }

  deactivateUser = () => {
    let selectedDataType = checkData(this.props.reported_content.clickedResult.data);
    let uID = 0;
    switch (selectedDataType) {
      case 'comment': {
        uID = this.props.reported_content.clickedResult.data.reported_comment_data.user;
        break;
      }
      case 'review': {
        uID = this.props.reported_content.clickedResult.data.reported_review_data.user;
        break;
      }
      case 'user': {
        uID = this.props.reported_content.clickedResult.data.reported_user.profile_id;
        break;
      }
      default: {
      }
    }

    const ep = `${server}v2/admin/edit-user`;
    const reqData = {
      user_id: uID,
      deactivated: true,
    };
    return apiPostRequest(ep, reqData)
      .then(data => {
        this.updateRCReadStatus();
        console.log(data);
        return data;
      })
      .catch(error => console.log(error));
  };

  onDeactivate() {
    this.deactivateUser();
  }

  updateRCReadStatus() {
    const reportedItemsURL = `${server}v2/admin/reported-items?limit=10&sort_field=created&descending=true`;
    const url = `${server}mark-reported-item-read`;
    let data = {
      item_id: this.props.reported_content.clickedResult.data.report_id
    }
    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: this.props.token
      },
      body: data
    })
      .then(response => {
        console.log(response);
        response.json();
      })
      .then(data => {
        this.props.openModal();
        this.props.updateList(reportedItemsURL, true);
        console.log(data);
        return data;
      })
      .catch(error => console.log(error));
  }

  render() {
    return (
      <ButtonContainer>
        <RemoveButton onClick={() => this.removeUser()} disabled>Remove Reported Content/User</RemoveButton>
        {/* TODO: ADD V2 */}
        <DeactivateButton onClick={() => this.onDeactivate()}>
          Deactivate reported User&apos;s Account
        </DeactivateButton>
      </ButtonContainer>
    );
  }
}

export default connect(mapStoreToProps)(SelectedContentActions);

SelectedContentActions.propTypes = propTypes;
SelectedContentActions.defaultProps = defaultProps;
