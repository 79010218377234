import React from 'react';
import styled from 'styled-components';

import { CONSTANTS } from '../assets/CONSTANTS';

const ModalWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 600;
`;

const Modal = styled.div`
  background-color: ${CONSTANTS.color.whiteP};
  position: absolute;
  top: calc(35vh - 150px);
  left: calc(38vw - 10%);
  max-width: 60%;
  width: 50%;
  min-width: 280px;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  padding: 15px 10px;
`;

const Heading = styled.h1`
  font-weight: bold;
  font-size: 1.6rem;
  margin: auto;
  text-align: center;
`;
const ConfirmBtn = styled.button`
  padding: 5px 0px;
  border-radius: 4px;
  border: none;
  background-color: ${CONSTANTS.color.yellowP};
  font-weight: bold;
  margin: 5px auto;
  width: 100%;
  outline: none;
  cursor: pointer;
  width: 40%
  text-align:center;
  padding: 10px 0px;
`;

const LabelMsg = styled.p`
  color: ${CONSTANTS.color.black};
  text-align: center;
  font-size: 1.1rem;
  overflow-wrap: break-word;
`;

const PodcastErrorModal = ({ errorMessage, onOKPressHandler, isUpdateError, isPodcastInfo }) => {

  return (
    <ModalWrapper id='out' onClick={onOKPressHandler}>
      <Modal onClick={(event) => event.stopPropagation()}>
        <Heading>{isPodcastInfo ? 'Podcast Info' : isUpdateError ? 'Can not update error' : 'Can not play error'}</Heading>
        <LabelMsg>{errorMessage}</LabelMsg>
        <ConfirmBtn onClick={onOKPressHandler}>
          Close
        </ConfirmBtn>     
      </Modal>
    </ModalWrapper>
  );
  }

  export default PodcastErrorModal;