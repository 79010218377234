// React & React Router
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
// Custom Components
import TableView from '../components/TableView';
import FeaturedModal from '../components/FeaturedModal';

// Assets & Styles
import TestData from '../data.json';
import {
  CONSTANTS,
  server,
  FormatDate,
  mapStoreToProps,
  FlexRow,
  FlexItem,
  TabLink,
  sortPodcastComparison,
} from '../assets/CONSTANTS';

import {
  apiGetRequest, apiPostRequest, getNextPageFromURL, getPrevPageFromURL,
} from '../lib/api/utils';

const host = `${server}`;
const path = 'v2/admin/featured-podcasts';
const endpoint = `${host}${path}?limit=10&offset=0`;

const Tab = styled(FlexItem)`
  background-color: ${CONSTANTS.color.tabGrey};
  border-bottom: 4px solid ${CONSTANTS.color.whiteP};
  justify-content: middle;
  padding: 15px 0px;
  text-align: center;
  border-top-left-radius: 4px !important;
`;
const TabActive = styled(Tab)`
  background-color: ${CONSTANTS.color.whiteP};
  border-bottom: 4px solid ${CONSTANTS.color.yellowP};
  border-top-right-radius: 4px !important;
`;

const PrevNextBtn = styled.button`
  padding: 15px;
  background-color: #fff;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  cursor: pointer;
  outline: none;
  margin: 5px;
  font-weight: bold;
  transition: 0.15s all ease-in-out;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  &:hover {
    background-color: ${CONSTANTS.color.yellowP};
  }
`;

const CenterAligned = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${CONSTANTS.color.whiteP};
  padding: 20px 0px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
`;

class PodcastsFeatured extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      episodes_next: '',
      episodes_prev: '',
      podcasts: [],
      influencers: [],
      episodes: [],
      modalActive: false,
      chosenEpisode: {},
      sort: {
        currentField: '',
        sortOrder: '',
      },
    };

    this.handleSortClick = this.handleSortClick.bind(this);
    this.loadNextPage = this.loadNextPage.bind(this);
    this.loadPrevPage = this.loadPrevPage.bind(this);
    this.openEpModal = this.openEpModal.bind(this);
    this.closeEpModal = this.closeEpModal.bind(this);
    this.updateFeaturedStatus = this.updateFeaturedStatus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentDidMount() {
    this.initialize();
  }

  async initialize() {
    this.setState(
      {
        episodes: await this.getEpisodes(endpoint),
        // influencers: await this.getInfluencers(influencerURL),
      },
      () => {
        console.log(this.state);
      },
    );
  }

  async loadNextPage() {
    const { episodes_next } = this.state;
    if (episodes_next) {
      this.setState({ episodes: await this.getEpisodes(episodes_next) });
    }
  }

  async loadPrevPage() {
    const { episodes_prev } = this.state;
    if (episodes_prev) {
      this.setState({ episodes: await this.getEpisodes(episodes_prev) });
    }
  }

  closeEpModal() {
    this.setState({ modalActive: false });
  }

  async openEpModal(e) {
    const ep_id = e.currentTarget.id;
    const ep = await this.getSingleEpisode(ep_id, this.state.episodes);
    console.log(ep);

    this.setState(
      {
        modalActive: true,
        chosenEpisode: ep,
      },
    );
  }
  // **** NOT IN USE **** * //
  // async getInfluencers(url) {
  //   const currUser = getAuthUser();
  //   const firebaseToken = await currUser.getIdToken(false);
  //   return fetch(url, {
  //     method: 'get',
  //     headers: {
  //       DjangoAuth: `Bearer ${firebaseToken}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => data.results)
  //     .catch((error) => console.log(error));
  // }

  getSingleEpisode(clicked_id, data) {
    let results = {};
    // TODO: should change this to a filter then map. Just muting for now.
    // eslint-disable-next-line array-callback-return
    data.map((ep) => {
      // eslint-disable-next-line eqeqeq
      if (ep.episode_id == clicked_id) {
        const singleEp = {
          ep_id: ep.episode_id,
          title: ep.episode_title,
          image: ep.episode_image,
          date: FormatDate(ep.episode_pub_date, 'dmy'),
          parent_pod: ep.parent_podcast_title,
          goodpods_recommended: ep.goodpods_recommended,
          pod_id: ep.parent_podcast_id,
          is_recommended: ep.is_recommended,
          is_friends_of_goodpods: ep.is_friends_of_goodpods,
          is_discussions_happening_now: ep.is_discussions_happening_now,
        };
        results = { ...singleEp };
      }
    });
    return results;
  }

  getEpisodes(url) {
    return apiGetRequest(url)
      .then((data) => {
        this.setState({ episodes_next: getNextPageFromURL(url) });
        this.setState({ episodes_prev: getPrevPageFromURL(url) });

        return data;
      })
      .catch((error) => console.log(error));
  }

  handleSubmit() {
    console.log('handle submit logout VVV');
    console.log(this.state);
    this.submitEpModal(this.state.chosenEpisode);
  }

  async handleRemove(e) {
    const ep_id = e.currentTarget.id;
    const ep = await this.getSingleEpisode(ep_id, this.state.episodes);

    this.setState(
      {
        chosenEpisode: ep,
      },
      () => {
        this.submitEpModal(this.state.chosenEpisode, true);
      },
    );
  }

  handleSortClick(e) {
    const sortType = e.currentTarget.getAttribute('data-sorttype');
    if (sortType === this.state.sort.currentField) {
      this.state.sort.sortOrder === 'ASC'
        ? this.setState({ sort: { currentField: sortType, sortOrder: 'DESC' } }, () => {
          this.setState({
            episodes: sortPodcastComparison(
              this.state.episodes,
              this.state.sort.currentField,
              this.state.sort.sortOrder,
            ),
          });
        })
        : this.setState({ sort: { currentField: sortType, sortOrder: 'ASC' } }, () => {
          this.setState({
            episodes: sortPodcastComparison(
              this.state.episodes,
              this.state.sort.currentField,
              this.state.sort.sortOrder,
            ),
          });
        });
    } else {
      this.setState({ sort: { currentField: sortType, sortOrder: 'ASC' } }, () => {
        this.setState({
          episodes: sortPodcastComparison(
            this.state.episodes,
            this.state.sort.currentField,
            this.state.sort.sortOrder,
          ),
        });
      });
    }
  }

  submitEpModal(singleEp, removeFlag) {
    const ep = singleEp;
    const url = `${server}v2/admin/set-episode-details`;
    const reqData = {
      episode_id: ep.ep_id,
      recommended: removeFlag ? false : ep.is_recommended,
      discussions_happening_now: removeFlag ? false : ep.is_discussions_happening_now,
      friends_of_goodpods: removeFlag ? false : ep.is_friends_of_goodpods,
    };

    return apiPostRequest(url, reqData)
      .then(async (data) => {
        console.log(data);
        this.setState({
          episodes: await this.getEpisodes(`${endpoint}&podcastId=${ep.ep_id}`),
          modalActive: false,
        });
        return data;
      })
      .catch((error) => console.log(error));
  }

  updateFeaturedStatus(e, toggle) {
    const clicked = e.currentTarget.id;
    switch (clicked) {
      case 'rec': {
        const ep = { ...this.state.chosenEpisode };
        ep.is_recommended = !ep.is_recommended;
        console.log(ep);
        this.setState({ chosenEpisode: ep });
        break;
      }
      case 'fog': {
        const ep = { ...this.state.chosenEpisode };
        ep.is_friends_of_goodpods = !ep.is_friends_of_goodpods;
        console.log(ep);
        this.setState({ chosenEpisode: ep });
        break;
      }
      case 'dhn': {
        const ep = { ...this.state.chosenEpisode };
        ep.is_discussions_happening_now = !ep.is_discussions_happening_now;
        console.log(ep);
        this.setState({ chosenEpisode: ep });
        break;
      }
      default: {
        break;
      }
    }
  }

  render() {
    return (
      <div>
        <FlexRow>
          <Tab>
            <TabLink to="/Podcasts">All Podcasts</TabLink>
          </Tab>
          <TabActive>
            <TabLink to="/Podcasts/Featured">Featured Podcasts</TabLink>
          </TabActive>
        </FlexRow>
        <div>
          <TableView
            tableTitle="All Podcasts"
            linkText="View All"
            linkPath="/"
            tableTopBar={false}
            rowData={this.state.episodes}
            headerData={TestData.headers.featured}
            rowType="fpodcast"
            openModal={this.openEpModal}
            onRemove={this.handleRemove}
            sortFunction={this.handleSortClick}
            sortData={this.state.sort}
          />
          <CenterAligned>
            <PrevNextBtn onClick={this.loadPrevPage}>&lt;</PrevNextBtn>
            <PrevNextBtn onClick={this.loadNextPage}>&gt;</PrevNextBtn>
          </CenterAligned>
        </div>
        {this.state.modalActive ? (
          <FeaturedModal
            onSubmit={this.handleSubmit}
            onToggle={this.updateFeaturedStatus}
            data={this.state.chosenEpisode}
            influencers={this.state.influencers}
            onClick={this.closeEpModal}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default connect(mapStoreToProps)(PodcastsFeatured);
