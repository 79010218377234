import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { getAuthUser } from '../firebase';

// Custom components
import DataBanner from '../components/DataBanner';
import PodcastHealthTableRow from '../components/PodcastHealthTableRow';
import UserIssuesTableRow from '../components/UserIssuesTableRow';

// Assets & Styles
import {
  server,
  CONSTANTS,
  Flex3,
  FlexMid,
  MobileStackFlexWrapper
} from '../assets/CONSTANTS';

// Icons
import personIcon from '../assets/SideNav/usersIconAlt.svg';

//Assets & Styles
import TestData from '../../src/data.json';

const Spacer = styled.div`
  padding: 10px 0px;
  margin: 5px 0px;
  min-height: 1px;
`;

const CenterAligned = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${CONSTANTS.color.whiteP};
  padding: 20px 0px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
`;

const PrevNextBtn = styled.button`
  background-color: #fff;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  cursor: pointer;
  outline: none;
  margin: 5px;
  font-weight: bold;
  transition: 0.15s all ease-in-out;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  color: ${(props) => (props.isActive ? '#333333' : '#AAAAAA')};
  font-size: 1.5rem;
  &:hover {
    background-color: ${(props) => (props.isActive ? `${CONSTANTS.color.yellowP}` : '#fff')};
    cursor: ${(props) => (props.isActive ? `pointer` : 'default')};
  };
`;

const API_URL = `${server}v2/`;

const getGenericHealthStats = (setHealthStats) => {
  const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      fetch(`${API_URL}admin/health-generic-stats`, {
        method: 'GET',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
      }).then(response => response.json())
      .then(data => setHealthStats(data));

    }).catch((err) => console.log(err));
};

const getPodcastHealthTable = (offset=0, query='', setPodcastHealthTable=() => null, tableLimit=10) => {
  const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      fetch(`${API_URL}admin/podcast-health-information?limit=${tableLimit}&offset=${offset}&query=${query}`, {
        method: 'GET',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
      }).then(response => response.json())
      .then(data => {
        if (data?.length) setPodcastHealthTable(data)
      });
    }).catch((err) => console.log(err));
};

const getUserIssuesTable = (offset=0, query='', setUserIssuesTable=() => null, tableLimit=10) => {
  const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      fetch(`${API_URL}admin/user-playback-issues?limit=${tableLimit}&offset=${offset}&query=${query}`, {
        method: 'GET',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
      }).then(response => response.json())
      .then(data => {
        if (data?.length) setUserIssuesTable(data)
      });
    }).catch((err) => console.log(err));
};

const PodcastHealth = () => {
  const tableLimit = 10,
        [ genericHealthStats, setHealthStats ] = useState({}),
        [ podcastHealthTable, setPodcastHealthTable ] = useState([]),
        [ userIssuesTable, setUserIssuesTable ] = useState([]),
        [ podcastQueryString, setPodcastQueryString ] = useState(''),
        [ episodeQueryString, setEpisodeQueryString ] = useState(''),
        [ refreshingPodcastID, setRefreshingPodcastID ] = useState(null),
        podcastHealthTableOffset = useRef(0),
        userIssueTableOffset = useRef(0);

  useEffect(() => {
    podcastHealthTableOffset.current = 0;
    userIssueTableOffset.current = 0;
    getGenericHealthStats(setHealthStats);
    getPodcastHealthTable(0, '', setPodcastHealthTable);
    getUserIssuesTable(0, '', setUserIssuesTable, );
  }, []);

  useEffect(() => {
    podcastHealthTableOffset.current = 0;
    getPodcastHealthTable(0, podcastQueryString, setPodcastHealthTable)
  }, [podcastQueryString]);

  useEffect(() => {
    userIssueTableOffset.current = 0;
    getUserIssuesTable(0, episodeQueryString, setUserIssuesTable);
  }, [episodeQueryString]);

  const getNextPodcastTableHandler = _ => {
    if (podcastHealthTable.length === tableLimit) {
      const newOffset = podcastHealthTableOffset.current + tableLimit;
      podcastHealthTableOffset.current = newOffset;
      getPodcastHealthTable(newOffset, podcastQueryString, setPodcastHealthTable);
    }
  };

  const getPrevPodcastTableHandler = _ => {
    if (podcastHealthTableOffset.current >= tableLimit) {
      const newOffset = podcastHealthTableOffset.current - tableLimit
      podcastHealthTableOffset.current = newOffset;
      getPodcastHealthTable(newOffset, podcastQueryString, setPodcastHealthTable);
    }
  };

  const getNextIssueTableHandler = _ => {
    if (userIssuesTable.length === tableLimit) {
      const newOffset = userIssueTableOffset.current + tableLimit;
      userIssueTableOffset.current = newOffset;
      getUserIssuesTable(newOffset, episodeQueryString, setUserIssuesTable);
    }
  };

  const getPrevIssueTableHandler = _ => {
    if (userIssueTableOffset.current >= tableLimit) {
      const newOffset = userIssueTableOffset.current - tableLimit
      userIssueTableOffset.current = newOffset;
      getUserIssuesTable(newOffset, episodeQueryString, setUserIssuesTable);
    }
  };

  const onFilterPodcasts = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      setPodcastQueryString(event.target.value);
    }
  }; 

  const onFilterEpisodes = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      setEpisodeQueryString(event.target.value);
    }
  }; 

  const removeEpisodeHandler = (episodeID) => {
    // remove from front end
    setUserIssuesTable(userIssuesTable.filter(item => item?.episode_id !== episodeID));
    // send backend req
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      fetch(`${API_URL}admin/admin-clear-playback-issues?episode_id=${episodeID}`, {
        method: 'GET',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
      });
    }).catch((err) => console.log(err));
  };

  const checkPodcastHandler = (podcastID) => {
    setRefreshingPodcastID(podcastID);
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      fetch(`${API_URL}admin/admin-force-check?podcast_id=${podcastID}`, {
        method: 'GET',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
      }).then(_ => {
        setRefreshingPodcastID(null);
        // Refresh complete table
        getPodcastHealthTable(podcastHealthTableOffset.current, podcastQueryString, setPodcastHealthTable);
      });
    }).catch((err) => console.log(err));
  };

  return (
    <>
      <MobileStackFlexWrapper>
        <Flex3>
          <DataBanner
            wrapperBackground={CONSTANTS.color.blueDB}
            iconBackground={CONSTANTS.color.blueDarkDB}
            dataIcon={personIcon}
            dataValue={genericHealthStats?.podcast_count ? genericHealthStats?.podcast_count : '--------'}
            dataLabel="Total Podcasts"
          />
        </Flex3>
        <FlexMid>
          <DataBanner
            wrapperBackground={`${CONSTANTS.color.greenDB}`}
            iconBackground={`${CONSTANTS.color.greenDarkDB}`}
            dataIcon={personIcon}
            dataValue={genericHealthStats?.podcast_count ? `${((genericHealthStats?.subscribed_podcast_count / genericHealthStats?.podcast_count) * 100).toFixed(2)}%` : '--------'}
            dataLabel="Subscribed Podcasts"
          />
        </FlexMid>
        <Flex3>
          <DataBanner
            wrapperBackground={CONSTANTS.color.redDB}
            iconBackground={CONSTANTS.color.redDarkDB}
            dataIcon={personIcon}
            dataValue={genericHealthStats?.episode_count ? genericHealthStats?.episode_count : '--------'}
            dataLabel="Total Episodes"
          />
        </Flex3>
      </MobileStackFlexWrapper>
      <MobileStackFlexWrapper>
        <Flex3>
          <DataBanner
            wrapperBackground={CONSTANTS.color.redDB}
            iconBackground={CONSTANTS.color.redDarkDB}
            dataIcon={personIcon}
            dataValue={genericHealthStats?.podcast_count ? `${((genericHealthStats?.podcast_not_updating_count / genericHealthStats?.podcast_count) * 100).toFixed(2)}%` : '--------'}
            dataLabel="Podcasts not updating"
          />
        </Flex3>
        <FlexMid>
          <DataBanner
            wrapperBackground={`${CONSTANTS.color.blueDB}`}
            iconBackground={`${CONSTANTS.color.blueDarkDB}`}
            dataIcon={personIcon}
            dataValue={genericHealthStats?.podcast_count ? `${((genericHealthStats?.podcast_not_playing_count / genericHealthStats?.podcast_count) * 100).toFixed(2)}%` : '--------'}
            dataLabel="Podcasts not playing"
          />
        </FlexMid>
        <Flex3>
          <DataBanner
            wrapperBackground={CONSTANTS.color.greenDB}
            iconBackground={CONSTANTS.color.greenDarkDB}
            dataIcon={personIcon}
            dataValue={genericHealthStats?.episode_count ? `${((genericHealthStats?.episode_user_reported_count / genericHealthStats?.episode_count) * 100).toFixed(4)}%`: '--------'}
            dataLabel="Episodes reported by users"
          />
        </Flex3>
      </MobileStackFlexWrapper>
      <Spacer />
      <PodcastHealthTableRow
        tableTitle="Podcast Health Reports"
        dataPacket={podcastHealthTable}
        headerData={TestData.headers.podcastHealthTable}
        onFilterPodcasts={onFilterPodcasts}
        checkPodcastHandler={checkPodcastHandler}
        refreshingPodcastID={refreshingPodcastID}
      />
      <CenterAligned>
        <PrevNextBtn onClick={getPrevPodcastTableHandler} isActive={podcastHealthTableOffset.current >= tableLimit}>&lt;</PrevNextBtn>
        <PrevNextBtn onClick={getNextPodcastTableHandler} isActive={podcastHealthTable.length === 10}>&gt;</PrevNextBtn>
      </CenterAligned>
      <Spacer />
      <UserIssuesTableRow
        tableTitle="User Playback Reports"
        dataPacket={userIssuesTable}
        headerData={TestData.headers.userIssuesTable}
        onFilterEpisodes={onFilterEpisodes}
        removeEpisodeHandler={removeEpisodeHandler}
      />
      <CenterAligned>
        <PrevNextBtn onClick={getPrevIssueTableHandler} isActive={userIssueTableOffset.current >= tableLimit}>&lt;</PrevNextBtn>
        <PrevNextBtn onClick={getNextIssueTableHandler} isActive={userIssuesTable.length === 10}>&gt;</PrevNextBtn>
      </CenterAligned>
    </>
  );
};

export default PodcastHealth;