import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Custom Components
// Assets & Styles

const DataValue = styled.p`
  margin: 5px 0px;
  font-weight: 700;
`;
const DataLabel = styled(DataValue)``;
const Wrapper = styled.div`
  background-color: ${props => props.iconBackground};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  margin: 15px 0px;
`;
const DataWrapper = styled.div`
  background-color: ${props => props.wrapperBackground};
  flex: 10;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: white;
  font-weight: 500;
  padding: 20px;
`;
const IconWrapper = styled.div`
  flex: 3;
  justify-content: center;
  display: flex;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin: 15px 0px;
`;

function DataBanner({ wrapperBackground, iconBackground, dataValue, dataLabel, dataIcon }) {
  return (
    <Wrapper iconBackground={iconBackground}>
      <IconWrapper>
        <img alt="person" src={dataIcon} width="120px" height="80px" />
      </IconWrapper>
      <DataWrapper wrapperBackground={wrapperBackground}>
        <DataValue>{dataValue}</DataValue>
        <DataLabel>{dataLabel}</DataLabel>
      </DataWrapper>
    </Wrapper>
  );
}

DataBanner.propTypes = {
  wrapperBackground: PropTypes.string.isRequired,
  iconBackground: PropTypes.string.isRequired,
  dataIcon: PropTypes.string.isRequired,
  dataValue: PropTypes.number.isRequired,
  dataLabel: PropTypes.string.isRequired
};

export default DataBanner;
