import React from 'react';
import styled from 'styled-components';
import { FlexItem, FlexCol } from '../assets/CONSTANTS';
import profpic from '../assets/ImgPH.svg';

const FileInput = styled.input`
  color: black;
  background-color: #ffffff;
  min-height: 45px;
  width: 50%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  font-size: 14px;
  margin: 10px auto;
  padding: 20px;
  cursor: pointer;
  -webkit-transition: 0.15s all ease-in-out;
  transition: 0.15s all ease-in-out;
`;

function readURL(input, imgID) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function(e) {
      document.getElementById(imgID).src = e.target.result;
    };

    reader.readAsDataURL(input.files[0]);
  }
}

function updateImgSrc(inputID, imageID) {
  readURL(document.getElementById(inputID), imageID);
}

function ImageSelector({ image, buttonText, onImageChange, id, imageID }) {
  return (
    <FlexCol>
      <FlexItem margin="margin-top:14px;" textAlign="center">
        <img id={imageID} alt="" src={profpic} width="75px" />
      </FlexItem>
      <FlexItem textAlign="center">
        <FileInput
          id={id}
          type="file"
          onChange={e => {
            onImageChange(e);
            updateImgSrc(id, imageID);
          }}
        />
      </FlexItem>
    </FlexCol>
  );
}

export default ImageSelector;
