import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string
};

const defaultProps = {
  onClick: () => {
    return '';
  }
};

const Input = styled.input`
  zoom: 1.9;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.span``;

class Checkbox extends React.Component {
  render() {
    const { label, checked, onClick, id } = this.props;
    return (
      <CheckboxContainer>
        {checked ? (
          <Input id={id} onClick={e => onClick(e)} defaultChecked type="checkbox" />
        ) : (
          <Input id={id} onClick={e => onClick(e)} type="checkbox" />
        )}

        <Label>{label}</Label>
      </CheckboxContainer>
    );
  }
}

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
