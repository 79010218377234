/**
 * gets the amounts of minutes past from oldDate to toDate
 * Note if oldDate is in the future of toDate, the result will not be correct
 *
 * @param {Date} oldDate
 * @param {Date} toDate defaults to the current time, if not given
 * @return { int } the number of minutes past
 */
 export const getMinutesAgo = (oldDate, toDate = new Date()) => {
  const ONE_MINUTE = 60 * 1000;
  oldDate.setSeconds(0, 0);
  toDate.setSeconds(0, 0);

  return (toDate - oldDate) / ONE_MINUTE;
};

/**
 * gets the amounts of minutes past from oldDate to toDate
 * Note if oldDate is in the future of toDate, the result will not be correct
 *
 * @param {Date} oldDate
 * @param {Date} toDate defaults to the current time, if not given
 * @return { int } the number of minutes past
 */
export const getHoursAgo = (oldDate, toDate = new Date()) => {
  const ONE_HOUR = 60 * 60 * 1000;
  oldDate.setMinutes(0, 0, 0);
  toDate.setMinutes(0, 0, 0);

  return (toDate - oldDate) / ONE_HOUR;
};

/**
 * gets the amounts of days past from oldDate to toDate
 * Note if oldDate is in the future of toDate, the result will not be correct
 *
 * @param {Date} oldDate
 * @param {Date} toDate defaults to the current time, if not given
 * @return { int } the number of days past
 */
export const getDaysAgo = (oldDate, toDate = new Date()) => {
  const ONE_DAY = 24 * 60 * 60 * 1000;
  oldDate.setHours(0, 0, 0, 0);
  toDate.setHours(0, 0, 0, 0);

  return Math.floor((toDate - oldDate) / ONE_DAY);
};

/**
 * formats the given timestamp to something of the form: 'Sep 17, 2008'
 *
 * Note that the 'toLocaleString' function does not work in android, so this function
 * is partially to work around that
 *
 * @param { int } unixTimeDate the unix time stamp to format
 * @param { bool } includeYear  if the year should be in the display or not
 * @return { string } the formatted timestamp
 */
 export const formatTimestamp = (unixTimeDate, includeYear = false) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const currentDate = new Date(unixTimeDate);
  const currentYear = currentDate.getFullYear();
  const currentMonthIndex = currentDate.getMonth();

  return `${months[currentMonthIndex]} ${currentDate.getDate()}${includeYear ? ', ' + currentYear : ''}`;
};

/**
 * converts a date to a short hand string representing how long ago the date was in the past
 * i.e. 1 week ago, 4d etc.
 *
 * @param { Date } date the date to convert
 * @param { bool } bShorten if the date should be shortened or now, i.e. 1 week ago -> 1w
 * @return { string } the shorthand string representing how much time has gone
 */
export const convertDateToHowLongAgoString = (date) => {
  const TODAY = new Date();
  const CHECKED_DATE = new Date(date);
  const TIME_DIFF = Math.abs(TODAY.getTime() - CHECKED_DATE.getTime());

  /* these are the upper bounds for any time */
  const JUST_NOW = 1000 * 60;
  const MINUTES = 1000 * 60 * 60;
  const HOURS = 1000 * 60 * 60 * 24;
  const DAYS = 1000 * 60 * 60 * 24 * 7 - 1;
  const WEEK = 1000 * 60 * 60 * 24 * 7 * 2;
  const MAX_DATE = 8640000000000000;

  const TIME_STRINGS = {
    [JUST_NOW]: () => {
      return 'Just Now';
    },
    [MINUTES]: (m) => {
      return getMinutesAgo(m) + 'm ago';
    },
    [HOURS]: (h) => {
      return getHoursAgo(h) + 'h ago';
    },
    [DAYS]: (d) => {
      return getDaysAgo(d) + 'd ago';
    },
    [WEEK]: () => {
      return '1 week ago';
    },
    [MAX_DATE]: () => {
      return formatTimestamp(CHECKED_DATE);
    },
  };

  for (var key in TIME_STRINGS) {
    if (TIME_DIFF <= key) {
      return TIME_STRINGS[key](CHECKED_DATE);
    }
  }
};

