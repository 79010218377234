// Constant Values & Styles
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CONSTANTS = {
  color: {
    yellowP: '#fcdb00',
    blueP: '#8bbbe4',
    darkP: '#333333',
    blackA50: 'rgba(0,0,0,0.3)',
    blackA70: 'rgba(0,0,0,0.7)',
    sidebarText: '#788195',
    blackP: '#000000',
    whiteP: '#ffffff',
    greyP: '#aeada8',
    tabGrey: '#F6F6F6',
    beigeP: '#fcf1eb',
    sidebarP: '#2d3347',
    contentBG: '#f1f3f6',
    lightGrey: '#d8d8d8',
    borderGrey: '#e9e9e9',
    yellow: '#fff2a9',
    yellow2: '#fff8d0',
    yellow3: '#f1eac8',
    blue: '#dbe9ff',
    blue2: '#dff3ff',
    green: '#c7e26a',
    green2: '#f2fdce',
    green3: '#dbfff0',
    green4: '#56CEA8',
    red: '#f56c76',
    red2: '#ee9a9a',
    red3: '#ffd5d8',
    red4: '#ffdeed',
    red5: '#ff0000',
    purple: '#f8e9ff',
    orange: '#ffdfc8',
    blueDB: '#8bbbe4',
    blueDarkDB: '#73aee1',
    greenDB: '#c7e26a',
    greenDarkDB: '#b8d160',
    redDB: '#f56c76',
    redDarkDB: '#e8636d',
    linkBlue: '#008CEC',
  },

  layout: {
    flexRowContainer: {
      display: 'flex',
      flexDirection: 'row',
      minHeight: '50px',
      backgroundColor: 'white',
    },
    flexRowItem: {
      flex: 1,
      padding: '20px 20px',
    },
    flexColContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    contentContainer: {
      backgroundColor: 'white',
      minHeight: '50px',
      marginTop: '25px',
      padding: '20px',
    },
    gutters: {
      padding: '0px 20px',
    },
    centered: {
      rule: 'text-align: center;',
    },
    rightAligned: {
      textAlign: 'right',
    },
    leftAligned: {
      textAlign: 'left',
    },
    width100: {
      width: '100%',
    },
  },
};

//= ================
// routes
//= ================

const routeNames = [
  { name: 'Home', path: '/' },
  { name: 'Reported Content', path: '/ReportedContent' },
  { name: 'Users', path: '/Users' },
  { name: 'Dynamic Emails', path: '/DynamicEmails' },
  { name: 'Notifications', path: '/Notifications' },
  { name: 'Groups', path: '/Groups' },
  { name: 'TipJars', path: '/TipJars' },
  { name: 'CuratedLists', path: '/CuratedLists' },
  { name: 'Podcasts', path: '/Podcasts' },
  { name: 'Featured Podcasts', path: '/Podcasts/Featured' },
  { name: 'Featured Podcasts', path: '/Featured-Podcasts' },
  { name: 'Settings', path: '/Settings' },
];

//= ================
// styled components
//= ================

const VCenteredImage = styled.img`
  border-radius: ${(props) => (props.circleImg ? '50%' : '0px')};
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: ${(props) => (props?.isDefault ? 'default' : 'pointer')};
`;

const VCenteredContent = styled.p`
  margin: 0px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: ${(props) => props.color || CONSTANTS.color.blackP};
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

const VCenteredDivContent = styled.div`
  margin: 0px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: ${(props) => props.color || CONSTANTS.color.blackP};
`;

const ContentContainer = styled.div`
  min-height: 50px;
  padding: 20px;
  background-color: white;
  margin: 10px 0px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 50px;
  width: 100%;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  ${(props) => (props.margin ? props.margin : 'margin: 0px;')};
  padding: ${(props) => (props.paddedVert ? '10px 0px' : '0px')};
  ${(props) => (props.padding ? props.padding : '')};
  border-top: ${(props) =>
    props.tableRowContainer ? `1px solid ${CONSTANTS.color.lightGrey}` : 'none'};
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50px;
`;

const FlexItem = styled.div`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  padding: ${(props) => (props.padded ? '10px 15px;' : '0;')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : 'transparent')};
  ${(props) => (props.margin ? props.margin : 'margin: 0px;')};
  ${(props) =>
    props.isATab
      ? `
      background-color: ${CONSTANTS.color.whiteP};
      border-bottom: 4px solid ${CONSTANTS.color.yellowP};
      justify-content: middle;
      padding:15px 0px;
      text-align:center;
      `
      : `
      justify-content: middle;
      `};
`;

const Flex3 = styled.div`
  flex: 3;
`;

const Flex4 = styled.div`
  flex: 4;
  padding: 0px 40px;
`;

const MobileStackFlexWrapper = styled(FlexRow)`
  @media (max-width: 1300px) {
    flex-direction: column !important;
  }
`;

const FlexMid = styled(Flex4)`
  @media (max-width: 1300px) {
    padding: 0;
  }
`;

const LoginButton = `width: 100%;
text-align: center;
min-height: 35px;
background-color: ${CONSTANTS.color.yellowP};
border: none;
border-radius: 3px;
margin-top: 15px;
margin-bottom: 25px;
cursor: pointer;
&:hover: {
  content: '';
}`;

const Button = styled.button`
  width: 100%;
  text-align: center;
  min-height: 35px;
  background-color: ${CONSTANTS.color.yellowP};
  border: none;
  border-radius: 3px;
  margin-top: 15px;
  margin-bottom: 25px;
  cursor: pointer;
  &:hover: {
    content: '';
  }
`;

const InputStyle = `width: 100%;
min-height: 45px;
padding: 0px 15px;
border-radius: 3px;
border: 1px solid #979797;
&:hover: {
  content: '';
}`;

const DefaultLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: #9e9e9e;
  &:hover {
    text-decoration: underline;
  }
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
`;

const SettingsInput = styled.input`
  min-height: 45px;
  border: 1px solid ${(props) => (props.error ? CONSTANTS.color.red : CONSTANTS.color.lightGrey)};
  width: 100%;
  border-radius: 4px;
  outline: none;
`;

const SettingsTextarea = styled.textarea`
  min-height: 45px;
  border: 1px solid ${CONSTANTS.color.lightGrey};
  width: 100%;
  border-radius: 4px;
  outline: none;
  resize: none;
`;

const SettingsDropdown = styled.select`
  height: 50px;
  line-height: 50px;
  font-size: 1rem;
  border: 1px solid ${CONSTANTS.color.lightGrey};
  width: 100%;
  border-radius: 4px;
  outline: none;
  resize: none;
  line-height: 4em;
`;

const TabLink = styled(Link)`
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  color: black;
  font-size: 2rem;
`;

const Label = styled.p`
  text-align: left;
`;

const Logo = styled.img`
  width: 100%;
  margin-bottom: 15px;
`;

const ResponsiveWrapper3 = styled.div`
  @media (max-width: 2100px) {
    width: 490px;
  }
  @media (max-width: 2000px) {
    width: 470px;
  }
  @media (max-width: 1900px) {
    width: 450px;
  }
  @media (max-width: 1750px) {
    width: 410px;
  }
  @media (max-width: 1600px) {
    width: 365px;
  }
  @media (max-width: 1580px) {
    width: 360px;
  }
  @media (max-width: 1500px) {
    width: 330px;
  }
  @media (max-width: 1440px) {
    width: 310px;
  }
  @media (max-width: 1300px) {
    width: 100%;
    margin-bottom: 25px;
  }
`;
const ResponsiveWrapper4 = styled.div`
  @media (max-width: 2100px) {
    width: 650px;
  }
  @media (max-width: 2000px) {
    width: 630px;
  }
  @media (max-width: 1900px) {
    width: 610px;
  }
  @media (max-width: 1750px) {
    width: 550px;
  }
  @media (max-width: 1600px) {
    width: 490px;
  }
  @media (max-width: 1580px) {
    width: 460px;
  }
  @media (max-width: 1440px) {
    width: 420px;
  }
  @media (max-width: 1300px) {
    width: 100%;
    margin-bottom: 25px;
  }
`;

//= ================
// Helper/Reused Functions/Vars
//= ================

const server = process.env.REACT_APP_BACKEND_SERVER_URL;

const mapStoreToProps = (store) => {
  const { token } = store.login;
  const { tokenExpiry } = store.login;
  const { user_info } = store.login;
  const { reported_content } = store;
  if (store.reported_content.clickedRepUser !== null) {
    var reported_user = store.reported_content.clickedRepUser.data;
  }

  return {
    token,
    tokenExpiry,
    user_info,
    reported_content,
    reported_user,
  };
};

const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@“]+(\.[^<>()[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(email)) {
    return true;
  }
  return false;
};

// selected reported content type
const checkData = (data) => {
  if (data.reported_user === null) {
    if (data.reported_review_data === null) {
      if (data.reported_comment_data === null) {
        console.log('no data exists');
      } else {
        return 'comment';
      }
    } else {
      return 'review';
    }
  } else {
    return 'user';
  }
};

const sortUserComparison = (array, sortType, sortOrder = 'ASC') => {
  switch (sortType) {
    case 'Followers': {
      array.sort((a, b) =>
        sortOrder === 'ASC'
          ? a.follower_count - b.follower_count
          : b.follower_count - a.follower_count
      );
      break;
    }
    case 'Following': {
      array.sort((a, b) =>
        sortOrder === 'ASC'
          ? a.following_count - b.following_count
          : b.following_count - a.following_count
      );
      break;
    }
    case 'Reviews': {
      array.sort((a, b) =>
        sortOrder === 'ASC' ? a.review_count - b.review_count : b.review_count - a.review_count
      );
      break;
    }
    case 'FirstName': {
      array.sort((a, b) => {
        const AName = a.first_name.toLowerCase();
        const BName = b.first_name.toLowerCase();
        if (sortOrder === 'DESC') {
          if (AName > BName) {
            return -1;
          }
          if (AName < BName) {
            return 1;
          }
          return 0;
        }
        if (AName < BName) {
          return -1;
        }
        if (AName > BName) {
          return 1;
        }
        return 0;
      });
      break;
    }
    case 'LastName': {
      array.sort((a, b) => {
        const AName = a.last_name.toLowerCase();
        const BName = b.last_name.toLowerCase();
        if (sortOrder === 'DESC') {
          if (AName > BName) {
            return -1;
          }
          if (AName < BName) {
            return 1;
          }
          return 0;
        }
        if (AName < BName) {
          return -1;
        }
        if (AName > BName) {
          return 1;
        }
        return 0;
      });
      break;
    }
    case 'EmailID': {
      array.sort((a, b) => {
        const AName = a.user_email.toLowerCase();
        const BName = b.user_email.toLowerCase();
        if (sortOrder === 'DESC') {
          if (AName > BName) {
            return -1;
          }
          if (AName < BName) {
            return 1;
          }
          return 0;
        }
        if (AName < BName) {
          return -1;
        }
        if (AName > BName) {
          return 1;
        }
        return 0;
      });
      break;
    }
    case 'Verified': {
      array.sort((a, b) => {
        const ABool = a.is_influencer;
        const BBool = b.is_influencer;
        if (sortOrder === 'DESC') {
          if (ABool === BBool) {
            return 0;
          }
          if (ABool === true && BBool === false) {
            return 1;
          }
          return -1;
        }
        if (ABool === BBool) {
          return 0;
        }
        if (ABool === true && BBool === false) {
          return -1;
        }
        return 1;
      });
      break;
    }
    default: {
      break;
    }
  }
  return array;
};

const sortPodcastComparison = (array, sortType, sortOrder = 'ASC') => {
  const returnArray = [...array];
  switch (sortType) {
    case 'Podcasts': {
      returnArray.sort((a, b) => {
        const AName = a.title.toLowerCase();
        const BName = b.title.toLowerCase();
        if (sortOrder === 'DESC') {
          if (AName > BName) {
            return -1;
          }
          if (AName < BName) {
            return 1;
          }
          return 0;
        }
        if (AName < BName) {
          return -1;
        }
        if (AName > BName) {
          return 1;
        }
        return 0;
      });
      break;
    }
    case 'Season-Episode': {
      returnArray.sort((a, b) => {
        const AName = a.title.toLowerCase();
        const BName = b.title.toLowerCase();
        if (sortOrder === 'DESC') {
          if (AName > BName) {
            return -1;
          }
          if (AName < BName) {
            return 1;
          }
          return 0;
        }
        if (AName < BName) {
          return -1;
        }
        if (AName > BName) {
          return 1;
        }
        return 0;
      });
      break;
    }
    case 'Location': {
      returnArray.sort((a, b) => {
        const AName = Object.values(a.episode_locations);
        const BName = Object.values(b.episode_locations);
        if (sortOrder === 'DESC') {
          if (AName > BName) {
            return -1;
          }
          if (AName < BName) {
            return 1;
          }
          return 0;
        }
        if (AName < BName) {
          return -1;
        }
        if (AName > BName) {
          return 1;
        }
        return 0;
      });
      break;
    }
    case 'ID': {
      returnArray.sort((a, b) => (sortOrder === 'ASC' ? a.id - b.id : b.id - a.id));
      break;
    }
    case 'Reviews': {
      returnArray.sort((a, b) =>
        sortOrder === 'ASC' ? a.reviews_count - b.reviews_count : b.reviews_count - a.reviews_count
      );
      break;
    }
    case 'Bookmarks': {
      returnArray.sort((a, b) =>
        sortOrder === 'ASC'
          ? a.bookmarks_count - b.bookmarks_count
          : b.bookmarks_count - a.bookmarks_count
      );
      break;
    }
    case 'Subscribed': {
      returnArray.sort((a, b) =>
        sortOrder === 'ASC'
          ? a.subscriptions_count - b.subscriptions_count
          : b.subscriptions_count - a.subscriptions_count
      );
      break;
    }
    case 'Listens': {
      returnArray.sort((a, b) =>
        sortOrder === 'ASC' ? a.listens_count - b.listens_count : b.listens_count - a.listens_count
      );
      break;
    }
    case 'Recommends': {
      returnArray.sort((a, b) =>
        sortOrder === 'ASC'
          ? a.recommends_count - b.recommends_count
          : b.recommends_count - a.recommends_count
      );
      break;
    }
    case 'Shares': {
      returnArray.sort((a, b) =>
        sortOrder === 'ASC' ? a.shares_count - b.shares_count : b.shares_count - a.shares_count
      );
      break;
    }
    case 'CreatedOn': {
      returnArray.sort((aDate, bDate) => {
        const newADate = Date.parse(aDate.pub_date);
        const newBDate = Date.parse(bDate.pub_date);
        if (sortOrder === 'ASC') {
          if (newADate === newBDate) {
            return 0;
          }
          if (newADate < newBDate) {
            return -1;
          }
          return 1;
        }
        if (newADate > newBDate) {
          return -1;
        }
        if (newADate < newBDate) {
          return 1;
        }
        return 0;
      });
      break;
    }
    case 'DateAddedOn': {
      returnArray.sort((aDate, bDate) => {
        const newADate = Date.parse(aDate.pub_date);
        const newBDate = Date.parse(bDate.pub_date);
        if (sortOrder === 'ASC') {
          if (newADate === newBDate) {
            return 0;
          }
          if (newADate < newBDate) {
            return -1;
          }
          return 1;
        }
        if (newADate > newBDate) {
          return -1;
        }
        if (newADate < newBDate) {
          return 1;
        }
        return 0;
      });
      break;
    }
    default: {
      break;
    }
  }
  return returnArray;
};

const month = [];
month[0] = 'Jan';
month[1] = 'Feb';
month[2] = 'Mar';
month[3] = 'Apr';
month[4] = 'May';
month[5] = 'Jun';
month[6] = 'Jul';
month[7] = 'Aug';
month[8] = 'Sep';
month[9] = 'Oct';
month[10] = 'Nov';
month[11] = 'Dec';

const week = [];
week[0] = 'Sunday';
week[1] = 'Monday';
week[2] = 'Tuesday';
week[3] = 'Wednesday';
week[4] = 'Thursday';
week[5] = 'Friday';
week[6] = 'Saturday';

function FormatDate(dateToFormat, dateFormat) {
  const date = new Date(dateToFormat);
  let fDate = '';
  switch (dateFormat) {
    case 'dmy':
      fDate = `${date.getDate()} ${month[date.getMonth()]} ${date.getFullYear()}`;
      break;
    case 'dm':
      fDate = `${date.getDate()} ${month[date.getMonth()]}`;
      break;
    case 'd':
      fDate = `${date.getDate()}`;
      break;
    case 'm':
      fDate = `${month[date.getMonth()]}`;
      break;
    case 'graph':
      fDate = `${week[date.getDay()]}`;
      break;
    default:
      fDate = `${date.getDate()} ${month[date.getMonth()]} ${date.getFullYear()}`;
      break;
  }

  return fDate;
}

const MockData = [
  {
    created_date: '2019-10-01T13:21:36.561857+10:00',
    first_name: 'Phillip',
    follower_count: 1,
    following_count: 4,
    full_name: 'Phillip Sgardelis',
    id: 198,
    is_deactivated: false,
    is_influencer: false,
    is_podcaster: false,
    is_verified: false,
    last_name: 'Sgardelis',
    phone: '',
    profile_url: 'media/profile_images/317.jpeg',
    review_count: 0,
    user_bio: 'My hobbies are testing and breaking apps.',
    user_email: 'phillip@cognativ.com.au',
  },
  {
    created_date: '2019-09-27T15:39:14.309791+10:00',
    first_name: 'add',
    follower_count: 0,
    following_count: 1,
    full_name: 'add asd',
    id: 197,
    is_deactivated: false,
    is_influencer: true,
    is_podcaster: false,
    is_verified: false,
    last_name: 'asd',
    phone: '',
    profile_url: 'media/profile_images/306.jpeg',
    review_count: 0,
    user_bio: '',
    user_email: 'asas@fffff.com',
  },
  {
    created_date: '2019-09-27T12:56:59.663570+10:00',
    first_name: 'sad',
    follower_count: 0,
    following_count: 1,
    full_name: 'sad asdasd',
    id: 195,
    is_deactivated: false,
    is_influencer: false,
    is_podcaster: false,
    is_verified: false,
    last_name: 'asdasd',
    phone: '',
    profile_url: 'media/default_profile/default_profile.png',
    review_count: 1,
    user_bio: '',
    user_email: 'hhhhhh@hhhh.com',
  },
];

//= ================
// Exports
//= ================

export {
  checkData,
  isValidEmail,
  mapStoreToProps,
  server,
  routeNames,
  LoginButton,
  InputStyle,
  SettingsInput,
  SettingsTextarea,
  SettingsDropdown,
  Label,
  DefaultLink,
  TabLink,
  FlexRow,
  FlexCol,
  FlexItem,
  Flex3,
  Flex4,
  FlexMid,
  MobileStackFlexWrapper,
  ResponsiveWrapper3,
  ResponsiveWrapper4,
  Button,
  Logo,
  VCenteredImage,
  VCenteredContent,
  ContentContainer,
  VCenteredDivContent,
  sortUserComparison,
  sortPodcastComparison,
  MockData,
  FormatDate,
};

export default CONSTANTS;
