/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-date-picker';
import Moment from 'moment';
import styled from 'styled-components';
import TextareaGroup from './TextareaGroup';
import InputGroup from './InputGroup';

// styling + constants + helper functions
import {
  CONSTANTS,
  DefaultLink,
  server,
  FlexItem,
  FlexRow,
  mapStoreToProps,
  isValidEmail,
} from '../assets/CONSTANTS';
import { apiGetRequest, apiPostRequest } from '../lib/api/utils';

const { color } = CONSTANTS;
const gutters = '20px;';

const WelcomeMsg = styled.p`
  color: ${CONSTANTS.color.black};
  text-align: left;
`;

const SectionHeading = styled.h1`
  font-weight: bold;
  font-size: 25px;
  padding: ${(props) => (props.padding ? props.padding : '5px 0px;')};
  margin: 10px 0px 20px 0px;
  text-align: left;
`;

const ScheduleMsg = styled.p`
  color: ${CONSTANTS.color.black};
  text-align: left;
`;

const WarningMsg = styled.p`
  color: ${CONSTANTS.color.red};
  text-align: left;
`;

const ActionButton = styled.button`
  color: black;
  width: 100%;
  background-color: ${color.yellow};
  border: 1px solid ${color.yellow};
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  min-height: 45px;
  font-weight: bold;
  margin: 20px auto;
  padding: ${(props) => (props.padding ? props.padding : '5px 0px;')};
  transition: 0.15s all ease-in-out;
  &:hover {
    background-color: ${color.yellowP};
    border: 1px solid ${color.yellowP};
  }
`;

const OuterFlexItem = styled(FlexItem)`
  padding-left: 40px;
  padding-right: 40px;
  padding: 15px 30px;
`;

class WeeklyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dateSelected: null,
      firstPara: '',
      lastPara: '',
      sendWarning: false,
      accountUsername: '',
      sendingToUser: '',
      sendingWeeklyEmail: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onSendTestEmailClick = this.onSendTestEmailClick.bind(this);
    this.deleteSchedule = this.deleteSchedule.bind(this);
  }

  componentDidMount() {
    this.checkExisting();
  }

  onChange(inputName, e) {
    if (inputName === 'firstParagraph') {
      const firstPara = e.target.value;
      this.setState({ firstPara });
    } else {
      const lastPara = e.target.value;
      this.setState({ lastPara });
    }
  }

  onDateChange(val) {
    if (!val) return;
    this.setState({ dateSelected: val });
  }

  checkExisting() {
    const url = `${server}v2/admin/schedule-weekly-email`;
    apiGetRequest(url)
      .then((data) => {
        this.setState({
          firstPara: data.top_comment,
          lastPara: data.bottom_comment,
          dateSelected: data.send_date,
          loading: false,
        });
        data.sending_previous
          ? this.setState({ sendWarning: true })
          : this.setState({ sendWarning: false });
      })
      .catch(() => this.setState({ loading: false }));
  }

  sendWeeklyEmail(accountUsername, sendingToUser) {
    const url = `${server}v2/admin/send-example-weekly-email`;
    const requestData = {
      username: accountUsername,
      to_email: sendingToUser,
    };
    this.setState({ sendingWeeklyEmail: true });

    apiPostRequest(url, requestData)
      .then((data) => {
        this.setState({
          accountUsername: '',
          sendingToUser: '',
          sendingWeeklyEmail: false,
        });
        if (data) {
          alert(data.comments);
        }
      })
      .catch(() => {
        this.setState({ sendingWeeklyEmail: false });
      });
  }

  setSchedule(top_comment, bottom_comment, schedule_date) {
    const url = `${server}v2/admin/schedule-weekly-email`;
    const send_date = Moment(schedule_date).format('YYYY-MM-DD');
    const bodyData = {
      send_date,
      top_comment,
      bottom_comment,
    };
    apiPostRequest(url, bodyData)
      .then((data) => alert('Email scheduled!'))
      .catch((err) => console.log(err));
  }

  deleteSchedule() {
    const url = `${server}v2/admin/unschedule-weekly-email`;
    apiPostRequest(url)
      .then((data) => {
        this.setState({
          firstPara: '',
          lastPara: '',
          dateSelected: null,
        });
        return data;
      })
      .catch((err) => console.log(err));
  }

  onClick() {
    const { firstPara, lastPara, dateSelected } = this.state;

    if (firstPara.length > 1000 || lastPara.length > 1000) {
      return alert('Maximum Character Length: 1000');
    }

    if (firstPara.length < 5 || lastPara.length < 5) {
      return alert('Minimum Character Length: 5');
    }

    if (!dateSelected) {
      return alert('Please select a date');
    }

    this.setSchedule(firstPara, lastPara, dateSelected);

    // this.setState({ firstPara: '', lastPara: '' });

    // this.checkExisting();
  }

  onSendTestEmailClick() {
    const { user_info } = this.props;
    const { accountUsername, sendingToUser, sendingWeeklyEmail } = this.state;

    if (sendingWeeklyEmail) {
      return;
    }

    let loggedInUserEmail = '';

    if (user_info) {
      if (user_info.username) {
        loggedInUserEmail = user_info.username;
      }
    }

    if (loggedInUserEmail !== '') {
      if (accountUsername !== '') {
        let sendingToUserEmail = loggedInUserEmail;

        if (sendingToUser !== '') {
          if (isValidEmail(sendingToUser)) {
            sendingToUserEmail = sendingToUser;
          } else {
            return alert('Please enter a valid email!');
          }
        }
        this.sendWeeklyEmail(accountUsername, sendingToUserEmail);
      } else {
        alert('Please enter a username!');
      }
    }
  }

  render() {
    const { user_info } = this.props;
    const {
      firstPara,
      lastPara,
      sendWarning,
      sendingToUser,
      accountUsername,
      sendingWeeklyEmail,
      dateSelected,
      loading,
    } = this.state;

    let loggedInUserEmail = '';
    if (user_info) {
      if (user_info.username) {
        loggedInUserEmail = user_info.username;
      }
    }

    return (
      <>
        {loading ? 'Loading...' : (
          <FlexItem>
            <OuterFlexItem
              textAlign="center"
              bgColor={color.whiteP}
              margin={`margin-bottom:${gutters}`}
              flex={5}
            >
              <FlexRow>
                <FlexItem>
                  <SectionHeading>Weekly Email</SectionHeading>
                  <WelcomeMsg>Select day to schedule weekly email:</WelcomeMsg>
                  <DatePicker
                    onChange={this.onDateChange}
                    value={dateSelected ? new Date(dateSelected) : null}
                    required
                  />
                  <ScheduleMsg>
                    The next mailout is on
                    {' '}
                    {this.state.dateSelected ? Moment(this.state.dateSelected).format('dddd, MMM Do') : '...'}
                  </ScheduleMsg>
                </FlexItem>
                <FlexItem textAlign="right">
                  <DefaultLink
                    onClick={this.deleteSchedule}
                    to="/DynamicEmails"
                    margin="0px 10px 0px 20px"
                    padding="5px 0px"
                  >
                    Delete Schedule
                  </DefaultLink>
                </FlexItem>
              </FlexRow>
              {sendWarning ? (
                <WarningMsg>
                  * Emails currently sending. Changes will take affect next mailout
                </WarningMsg>
              ) : null}
              <form onSubmit={(e) => e.preventDefault()}>
                <TextareaGroup
                  onChange={this.onChange}
                  placeholder="First Paragraph..."
                  inputName="firstParagraph"
                  label="First Paragraph"
                  value={firstPara}
                  rows={6}
                />
                <TextareaGroup
                  onChange={this.onChange}
                  placeholder="Last Paragraph..."
                  inputName="lastParagraph"
                  label="Last Paragraph"
                  value={lastPara}
                  rows={6}
                />
                <ActionButton onClick={this.onClick}>Save new template</ActionButton>
              </form>
            </OuterFlexItem>

            <OuterFlexItem
              textAlign="center"
              bgColor={color.whiteP}
              margin={`margin-bottom:${gutters}`}
              flex={5}
            >
              <SectionHeading>Send test email</SectionHeading>
              <form onSubmit={(e) => e.preventDefault()}>
                <InputGroup
                  id="accountUsername"
                  label="Username of the account"
                  placeholder="eg: goodpodsuser"
                  type="text"
                  value={accountUsername}
                  onChange={(e, input) => this.setState({ accountUsername: input.target.value })}
                />
                <InputGroup
                  id="sendingToUser"
                  label="Send the test email to (optional)"
                  placeholder={`Sending to ${loggedInUserEmail}`}
                  type="text"
                  value={sendingToUser}
                  onChange={(e, input) => this.setState({ sendingToUser: input.target.value })}
                />
                <ActionButton onClick={this.onSendTestEmailClick}>
                  {sendingWeeklyEmail ? 'Sending email...' : 'Send test email'}
                </ActionButton>
              </form>
            </OuterFlexItem>
          </FlexItem>

        )}
      </>
    );
  }
}

export default connect(mapStoreToProps)(WeeklyEmail);
