import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DateTimePicker from 'react-datetime-picker';

import {
  FlexRow,
  FlexItem,
  VCenteredContent,
  server,
  CONSTANTS,
  SettingsDropdown,
} from '../assets/CONSTANTS';
import { getAuthUser } from '../firebase';

const CustomSettingsDropdown = styled(SettingsDropdown)`
  width: 33%;
  padding: 5px;
`;

const Wrapper = styled.div`
  padding: 0px 25px;
  div:first-child {
    border-top: none;
  }
`;

const Row = styled(FlexRow)``;

const ModalWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 600;
`;

const Modal = styled.div`
  background-color: ${CONSTANTS.color.whiteP};
  position: absolute;
  top: calc(50vh - 150px);
  left: calc(50vw - 10%);
  max-width: 40%;
  width: 30%;
  min-width: 280px;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
`;

const EditSettings = styled.div`
  margin: 30px 0px;
`;

const Heading = styled.h1`
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 10px auto;
  text-align: center;
`;
const ConfirmBtn = styled.button`
  padding: 5px 0px;
  border-radius: 4px;
  border: none;
  background-color: ${CONSTANTS.color.yellowP};
  font-weight: bold;
  margin: 5px auto;
  width: 100%;
  outline: none;
  cursor: pointer;
  width: 40%;
  text-align: center;
  padding: 10px 0px;
`;

const CancelBtn = styled(ConfirmBtn)`
  border: 1px solid ${CONSTANTS.color.borderGrey};
  background-color: transparent;
  margin-right:40px;
`;

const EditBtn = styled(ConfirmBtn)`
  width:30%;
  margin-right:10%;
  padding: 5px 0px;
`;

const PlayBtn = styled(ConfirmBtn)`
  border: 1px solid ${CONSTANTS.color.green};
  background-color: ${CONSTANTS.color.green};
  width:30%;
  padding: 5px 0px;
`;

const PauseBtn = styled(ConfirmBtn)`
  border: 1px solid ${CONSTANTS.color.red};
  background-color: ${CONSTANTS.color.red};
  width: 30%;
  padding: 5px 0px;
`;

const LabelMsg = styled.p`
  color: ${CONSTANTS.color.black};
  text-align: center;
  margin-right:20px;
`;

const EditGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 25%;
  margin-top:20px;
`;

function EditCampaignModal({onCancel, editName, editFrequency, editStartDate, onSave}) {
  const [startDate, setStartDate] = useState(new Date(editStartDate * 1000));
  const [theFrequency, setFrequency] = useState(editFrequency);

  return (
    <ModalWrapper>
      <Modal>
        <Heading>
          Editing {editName}
        </Heading>
        <EditSettings>
          <EditGroup>
            <LabelMsg>Frequency:</LabelMsg>
            <CustomSettingsDropdown
              value={theFrequency}
              onChange={(e) => setFrequency(e.target.value)}
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="fortnightly">Fortnightly</option>
            </CustomSettingsDropdown>
          </EditGroup>
          <EditGroup>
            <LabelMsg>Next Send:</LabelMsg>
            <DateTimePicker
              onChange={setStartDate}
              value={startDate}
            />
          </EditGroup>
        </EditSettings>
        <LabelMsg>Will round DOWN to nearest half hour. Use your local time (24h format).</LabelMsg>
        <CancelBtn onClick={() => onCancel()}>
          Cancel Changes
        </CancelBtn>
        <ConfirmBtn onClick={() => onSave(theFrequency, startDate.getTime() / 1000)}>
          Save Changes
        </ConfirmBtn>
      </Modal>
    </ModalWrapper>
  );
}


class UserRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editModal: false,
      editID: 0,
      editName: '',
      editFrequency: '',
      editStartDate: null,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.editCampaignData = this.editCampaignData.bind(this);

    // this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleOpen(campaign_id, campaign_name, editFrequency, editStartDate) {
    this.setState({
      editModal: true, editID: campaign_id, editName: campaign_name, editFrequency, editStartDate 
    });
  }

  handleCancel() {
    this.setState({ editModal: false });
  }

  playPauseCampaign(campaignID, currentStatus) {
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      const url = `${server}v2/admin-notifications/play-pause-campaign`;
      const data = {
        campaign_id: campaignID,
        is_paused: !currentStatus,
      };
      return fetch(url, {
        method: 'POST',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          this.props.changeNotificationData();
        })
        .catch((error) => console.log(error));
    }).catch((err) => console.log(err));
  }

  editCampaignData(newFrequency, newStartDate) {
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      const url = `${server}v2/admin-notifications/edit-campaign-parameters`;
      const data = {
        campaign_id: this.state.editID,
        frequency: newFrequency,
        start_date_and_time: newStartDate,
      };
      return fetch(url, {
        method: 'POST',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          this.props.changeNotificationData();
          this.setState({ editModal: false });
        })
        .catch((error) => console.log(error));
    }).catch((err) => console.log(err));
  }

  generateRowData(rowsData) {

    if (rowsData !== undefined) {
      // alert(JSON.stringify(rowsData));
      return rowsData.map((row) => (
        <Row paddedVert tableRowContainer key={row.campaign_id} padded>
          <FlexItem textAlign="center">
            <VCenteredContent>{row.campaign_name}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>{row.frequency}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>{(row.status) ? "Paused" : "Running"}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>{new Date(row.next_send*1000).toLocaleString()}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>{new Date(row.last_send*1000).toLocaleString()}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>
              <EditBtn onClick={() => this.handleOpen(row.campaign_id, row.campaign_name, row.frequency, row.next_send)}>
                Edit
              </EditBtn>
              {(row.status) ? (
                <PlayBtn onClick={() => this.playPauseCampaign(row.campaign_id, row.status)}>
                  Run
                </PlayBtn>
              ) : (
                <PauseBtn onClick={() => this.playPauseCampaign(row.campaign_id, row.status)}>
                  Pause
                </PauseBtn>
              )}
            </VCenteredContent>
          </FlexItem>
        </Row>
      ));
    }
    return '';
  }

  render() {
    return (
      <div>
        <Wrapper>{this.generateRowData(this.props.dataPacket)}</Wrapper>

        {this.state.editModal ? (
          <EditCampaignModal
            onCancel={this.handleCancel}
            editName={this.state.editName}
            editFrequency={this.state.editFrequency}
            editStartDate={this.state.editStartDate}
            onSave={this.editCampaignData}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

UserRow.propTypes = {
  dataPacket: PropTypes.array,
};

export default UserRow;
