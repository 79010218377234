import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../redux/actions';
import styled from 'styled-components';

import { mapStoreToProps, CONSTANTS, FlexItem, FlexRow } from '../assets/CONSTANTS';
import { signUserOut } from '../firebase';
const { color } = CONSTANTS;

const SectionHeading = styled.h1`
  font-weight: bold;
  font-size: 14px;
  padding: ${(props) => (props.padding ? props.padding : '5px 0px;')};
  margin: 10px 0px 0px 0px;
  text-align: left;
`;

const Username = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 26px;
  text-align: left;
  margin: 0;
  height: 45px;

`;

const LogoutBtn = styled.button`
  color: black;
  width: min(300px, 100%);
  background-color: ${color.whiteP};
  border: 1px solid ${color.blackA50};
  cursor: pointer;
  border-radius: 6px;
  min-height: 45px;
  font-weight: bold;
  font-size: 16px;
  margin-top: auto;
  padding: ${(props) => (props.padding ? props.padding : '5px 0px;')};
  transition: 0.15s all ease-in-out;
  &:hover {
    background-color: ${color.yellowP};
    border: 1px solid ${color.yellowP};
  }
`;

const FlexItemPadded = styled(FlexItem)`
  padding: 10px 15px !important;
  box-sizing: border-box;
`;

const BtnWrapper = styled(FlexItem)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

class LogoutPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onClick = this.onClick.bind(this);
  }

  onClick = () => {
    this.handleLogout();
  };

  async handleLogout () {
    await signUserOut();
    this.props.logout();
  };


  render() {
    const { user_info } = this.props;
    return (
      <FlexItemPadded bgColor={color.whiteP} flex={1}>
        <FlexRow>
          <FlexItem>
            <SectionHeading>Logged in as</SectionHeading>
            {user_info && <Username>{user_info.username}</Username>}
          </FlexItem>
          <BtnWrapper>
            <LogoutBtn onClick={this.onClick}>Log Out</LogoutBtn>
          </BtnWrapper>
        </FlexRow>
      </FlexItemPadded>
    );
  }
}

export default connect(mapStoreToProps, { logout })(LogoutPanel);
