import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  FlexRow,
  FlexItem,
  VCenteredContent,
  server,
  CONSTANTS,
  VCenteredImage,
} from '../assets/CONSTANTS';

import influImg from '../assets/Influencer.svg';
import influAltImg from '../assets/InfluencerAlt.svg';
import { getAuthUser } from '../firebase';

const TableLink = styled(Link)`
  text-decoration: none;
  color: ${CONSTANTS.color.linkBlue};
  &:visited {
    color: ${CONSTANTS.color.linkBlue};
  }
`;

const Wrapper = styled.div`
  padding: 0px 25px;
  div:first-child {
    border-top: none;
  }
`;

const Row = styled(FlexRow)``;


const PointerArea = styled.div`
  cursor: pointer;
  flex-direction: row;
  align-items: center
  justify-content: center;
`

class TipJarRow extends Component {

  constructor(props) {
    super(props);
    this.toggleTips = this.toggleTips.bind(this);
  }

  toggleTips(userId, toggleTo) {
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      const url = `${server}v2/admin/toggle-tip-jar`;
      const data = {
        user_id: userId,
        tips_enabled: toggleTo,
      };
      return fetch(url, {
        method: 'POST',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
        body: JSON.stringify(data)
      })
        .then((res) => res.json())
        .then((data) => {
          this.props.changeNotificationData();
        })
        .catch((error) => console.log(error));
    }).catch((err) => console.log(err));
  }

  generateRowData(rowsData) {
    if (rowsData !== undefined) {
      // alert(JSON.stringify(rowsData));
      return rowsData.map((row) => (
        <Row paddedVert tableRowContainer key={row.campaign_id} padded>
          <FlexItem textAlign="center">
            <VCenteredContent>{row.name}</VCenteredContent>
          </FlexItem>

          <FlexItem textAlign="center">
            <VCenteredContent><TableLink to={`/Profile/${row.user_id}`}>@{row.username}</TableLink></VCenteredContent>
          </FlexItem>

          <FlexItem textAlign="center">
            <VCenteredContent>
              {row.podcast_claims.map((claim) => (<><TableLink to={`/Episode/${claim.podcast_id}`}>{claim.title}</TableLink><br/></>))}
            </VCenteredContent>
          </FlexItem>

          <FlexItem textAlign="center">
            <VCenteredContent>
              {row.tip_jars.map((tipjar) => (<><TableLink to={tipjar}>{tipjar}</TableLink><br/></>))}
            </VCenteredContent>
          </FlexItem>

          <FlexItem textAlign="center">
            <VCenteredContent>
              <PointerArea onClick={() => {
                row.enabled = !row.enabled;
                this.toggleTips(row.user_id, !row.enabled ? false : true);
              }}
              >
                {row.enabled ? (
                  <VCenteredImage circleImg width="20px" height="20px" alt="" src={influImg} />
                ) : (
                  <VCenteredImage circleImg width="20px" height="20px" alt="" src={influAltImg} />
                )}
              </PointerArea>
            </VCenteredContent>
          </FlexItem>

        </Row>
      ));
    }
    return '';
  }

  render() {
    return (
      <div>
        <Wrapper>{this.generateRowData(this.props.dataPacket)}</Wrapper>
      </div>
    );
  }
}

export default TipJarRow;
