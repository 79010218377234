import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

// Assets & Styles
import BackArrow from '../assets/BackArrow.svg';
import {
  CONSTANTS,
  mapStoreToProps,
  server,
  FlexItem,
  FlexRow,
  FlexCol,
  TabLink,
  VCenteredContent,
} from '../assets/CONSTANTS';
import { apiDeleteRequest, apiGetRequest, getNextPageFromURL, getPrevPageFromURL } from '../lib/api/utils';

const propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

const defaultProps = {};

const TableHeader = styled(FlexRow)`
  background-color: ${CONSTANTS.color.whiteP};
  border: 1px solid ${CONSTANTS.color.borderGrey};
  padding: 25px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
const TableRows = styled(FlexCol)``;
const Row = styled(FlexRow)`
  padding: 15px 25px;
  border-bottom: 1px solid ${CONSTANTS.color.borderGrey};
`;
const PodTitle = styled.h4`
  margin: 0;
  font-weight: bold;
  font-size: 1.5rem;
  padding-left: 20px;
`;
const Wrapper = styled.div`
  background-color: ${CONSTANTS.color.whiteP};
  border: 1px solid ${CONSTANTS.color.borderGrey};
  border-radius: 4px;
`;
const ActionButton = styled.button`
  background-color: transparent;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  padding: 10px 20px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
`;
const PrevNextBtn = styled.button`
  padding: 15px;
  background-color: #fff;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  cursor: pointer;
  outline: none;
  margin: 5px;
  font-weight: bold;
  transition: 0.15s all ease-in-out;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  &:hover {
    background-color: ${CONSTANTS.color.yellowP};
  }
`;

const CenterAligned = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${CONSTANTS.color.whiteP};
  padding: 20px 0px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
`;

class EpisodeReviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: null,
      reviews_next: '',
      reviews_prev: '',
      episode: null,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.loadNextPage = this.loadNextPage.bind(this);
    this.loadPrevPage = this.loadPrevPage.bind(this);
  }

  componentDidMount() {
    this.initialize();
  }

  async initialize() {
    const reviewsURL = `${server}v2/admin/reviews?episode_id=${this.props.match.params.podID}&limit=10&offset=0`;
    const episodeURL = `${server}v2/episode/details?episodeId=${this.props.match.params.podID}`;
    this.setState({ reviews: await this.getReviews(reviewsURL) });
    this.setState({ episode: await this.getEpisode(episodeURL) });
  }

  async loadNextPage() {
    console.log(this.state.reviews_next);
    const { reviews_next } = this.state;
    if (reviews_next) {
      this.setState({ reviews: await this.getReviews(reviews_next) });
    }
  }

  async loadPrevPage() {
    const { reviews_prev } = this.state;
    if (reviews_prev) {
      this.setState({ reviews: await this.getReviews(reviews_prev) });
    }
  }

  handleDelete(e) {
    const url = `${server}v2/admin/delete-feed-item`;
    const reviewsURL = `${server}v2/admin/reviews?episode_id=${this.props.match.params.podID}&limit=10&offset=0`;
    const rev_id = e.currentTarget.id;
    this.deleteReview(url, rev_id, reviewsURL);
  }

  deleteReview(url, review_id, allReviewsURL) {
    const reqUrl = `${url}?feed_item_id=${review_id}`;
    return apiDeleteRequest(reqUrl)
      .then(async () => {
        this.setState({ reviews: await this.getReviews(allReviewsURL) });
      })
      .catch((error) => console.log(error));
  }

  getReviews(url) {
    return apiGetRequest(url)
      .then((data) => {
        this.setState({ reviews_next: getNextPageFromURL(url), reviews_prev: getPrevPageFromURL(url) });
        return data;
      })
      .catch((error) => console.log(error));
  }
  getEpisode(url) {
    return apiGetRequest(url)
      .then((data) => {
        return data;
      })
      .catch((error) => console.log(error));
  }

  generateTableRows(data) {
    console.log(data);
    return data.reviews.map((review) => (
      <Row key={review.id}>
        <FlexItem flex={1}>
          <VCenteredContent>
            {review.user.user_real_name}
          </VCenteredContent>
        </FlexItem>
        <FlexItem flex={1}>
          <VCenteredContent>
            {review.review_stars}
            {' '}
            Stars
          </VCenteredContent>
        </FlexItem>
        <FlexItem flex={4}>
          <VCenteredContent>
            {review.review_text}
          </VCenteredContent>
        </FlexItem>
        <FlexItem flex={1}>
          <VCenteredContent>
            <ActionButton id={review.id} onClick={(e) => this.handleDelete(e)}>
              Remove
            </ActionButton>
          </VCenteredContent>
        </FlexItem>
      </Row>
    ));
  }

  render() {
    // const { history } = this.props;
    const { reviews, episode } = this.state;
    if (reviews !== null && reviews.length > 0) {
      console.log(reviews);
      return (
        <Wrapper>
          <TableHeader>
            <FlexItem flex={1} textAlign="left">
              <VCenteredContent>
                <TabLink to="/Podcasts">
                  <img alt="backArrow" src={BackArrow} />
                </TabLink>
              </VCenteredContent>
            </FlexItem>
            <FlexItem flex={20} textAlign="left">
              <PodTitle>
                {episode && episode.title}
                {' '}
                - Reviews
              </PodTitle>
            </FlexItem>
            <FlexItem flex={20} textAlign="right" />
          </TableHeader>
          <TableRows>{this.generateTableRows(this.state)}</TableRows>
          <CenterAligned>
            <PrevNextBtn onClick={this.loadPrevPage}>&lt;</PrevNextBtn>
            <PrevNextBtn onClick={this.loadNextPage}>&gt;</PrevNextBtn>
          </CenterAligned>
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <TableHeader>
          <FlexItem flex={1} textAlign="left">
            <VCenteredContent>
              <TabLink to="/Podcasts">
                <img alt="backArrow" src={BackArrow} />
              </TabLink>
            </VCenteredContent>
          </FlexItem>
          <FlexItem flex={20} textAlign="left">
            <PodTitle>{reviews === null ? 'Loading' : 'No Episode Reviews'}</PodTitle>
          </FlexItem>
          <FlexItem flex={20} textAlign="right" />
        </TableHeader>
        <CenterAligned>
          <PrevNextBtn onClick={this.loadPrevPage}>&lt;</PrevNextBtn>
          <PrevNextBtn onClick={this.loadNextPage}>&gt;</PrevNextBtn>
        </CenterAligned>
      </Wrapper>
    );
  }
}

EpisodeReviews.propTypes = propTypes;
EpisodeReviews.defaultProps = defaultProps;

export default connect(mapStoreToProps)(EpisodeReviews);
