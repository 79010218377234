// React & React Router
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// import { connect } from 'react-redux';
// import { emptyToken } from './redux/actions';

import MainAppContainer from './MainAppContainer';

// Assets & Styles
import './App.scss';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Router>
        <MainAppContainer />
      </Router>
    );
  }
}

export default App;
