/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import styled from 'styled-components';
import InputGroup from './InputGroup';
import { server } from '../assets/CONSTANTS';
import { getAuthUser } from '../firebase';

//styling + constants + helper functions
import { CONSTANTS, FlexItem, SettingsDropdown } from '../assets/CONSTANTS';
const { color } = CONSTANTS;
const gutters = '20px;';

const CustomSettingsDropdown = styled(SettingsDropdown)`
  width:33%;
  padding:5px;
`

const CustomForm = styled.form`
  text-align: left;
`

const SectionHeading = styled.h1`
  font-weight: bold;
  font-size: 18px;
  padding: ${(props) => (props.padding ? props.padding : '5px 0px;')};
  margin: 10px 0px 20px 0px;
  text-align: left;
`;

const Spacer = styled.div`
  padding: 0px 0px;
  margin: 5px 0px;
  min-height: 1px;
`;

const FlexItemPadded = styled(FlexItem)`
  padding: 10px 15px !important;
  box-sizing: border-box;
  border-radius: 4px;
`;

const WelcomeMsg = styled.p`
  color: ${CONSTANTS.color.black};
  text-align: left;
`;

const ActionButton = styled.button`
  color: black;
  width: 100%;
  background-color: ${color.yellow};
  border: 1px solid ${color.yellow};
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  min-height: 45px;
  font-weight: bold;
  margin: 20px auto;
  padding: ${(props) => (props.padding ? props.padding : '5px 0px;')};
  transition: 0.15s all ease-in-out;
  &:hover {
    background-color: ${color.yellowP};
    border: 1px solid ${color.yellowP};
  }
`;

export default class NotifyNewFeature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      takes_to: null,
      notificationText: '',
      audience: null,
      requiresCustomId: true,
      custom_id: null
    };
    this.onSendToChange = this.onSendToChange.bind(this);
    this.onAudienceChange = this.onAudienceChange.bind(this);
    this.requiresCustomId = this.requiresCustomId.bind(this);

    this.onClick = this.onClick.bind(this);
  }

  onSendToChange(e) {
    this.setState({ takes_to: e.target.value });
  }

  onAudienceChange(e) {
    this.setState({ audience: e.target.value });
  }

  requiresCustomId(takesTo) {
    if (takesTo === "new_feat_podcast" || takesTo === "new_feat_episode" || takesTo === "new_feat_groups" || takesTo === "new_feat_user" ){
      return true;
    }
    return false;
  }

  onClick() {
    const {
      notificationText,
      takes_to,
      audience,
      custom_id
    } = this.state;


    if (audience === "" || audience === null) {
      alert("Must select an audience");
      return;
    }

    if (takes_to === null || takes_to === "") {
      alert("Must select where to send user");
      return;
    }
    if (notificationText === "") {
      alert("Must write some notification text");
      return;
    }

    // if specific id field, ensure has specific id

    if (takes_to === "new_feat_podcast" || takes_to === "new_feat_episode" || takes_to === "new_feat_groups" || takes_to === "new_feat_user" ) {
      if (custom_id === "" || custom_id === null){
        alert("Must include a custom ID with this takes to option");
        return;
      }
    }

    // if audience date, calculate date to set

    let finalAudience = "usersJoinedAfterDate";
    let joinDate;

    var d = new Date();

    if (audience === "7days" ){
      d.setDate(d.getDate() - 7);
      joinDate = d.toISOString().substring(0, 10);
    }else if (audience === "14days" ){
      d.setDate(d.getDate() - 14);
      joinDate = d.toISOString().substring(0, 10);
    }else if (audience === "30days" ){
      d.setDate(d.getDate() - 30);
      joinDate = d.toISOString().substring(0, 10);
    }else if (audience === "90days" ){
      d.setDate(d.getDate() - 90);
      joinDate = d.toISOString().substring(0, 10);
    }else{
      finalAudience = audience;
    }

    let data = {
      "audience": finalAudience,
      "takes_to": takes_to,
      "text": notificationText,
      "joined_after_date": joinDate,
      "custom_id": custom_id
    }

    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      const url = `${server}v2/admin-notifications/send-one-off-notification`;
      fetch(url, {
        method: 'POST',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
        body: JSON.stringify(data)
      })
        .then((response) => {
          if (response.status === 200) {
            alert("Notification is now sending");
          } else {
            alert(`Issue sending notification (Error ${response.status})`);
          }
        })
        .catch((error) => console.log(error));
    }).catch((err) => console.log(err));
  }

  render() {
    const {
      notificationText,
      takes_to,
      custom_id
    } = this.state;

    return (
      <FlexItemPadded
        textAlign="center"
        bgColor={color.whiteP}
        margin={`margin-bottom:${gutters}`}
        flex={5}
      >
        <SectionHeading>Send a one-off notification</SectionHeading>
        
        <CustomForm onSubmit={(e) => e.preventDefault()}>

          <WelcomeMsg>Select which users will receive this notification:</WelcomeMsg>

          <CustomSettingsDropdown 
            onChange={this.onAudienceChange}
            >
              <option value="">Select audience</option>
              <option value="usersTest">Test Users (Goodpods team, Cognativ accounts)</option>
              <option value="usersAll">All Users</option>
              <option value="usersNeverJoinedGroup">Users who never joined a group</option>
              <option value="usersNeverSubscribedPodcast">Users who never subscribed to a podcast</option>
              <option value="usersNeverFollowedAnother">Users who never followed another user</option>
              <option value="usersNeverListenedPodcast">Users who never listened to a podcast</option>
              <option value="usersInUsa">Users who are in the USA</option>
              <option value="usersOutsideUsa">Users who are not in the USA</option>
              <option value="7days">Users who joined in the last 7 days</option>
              <option value="14days">Users who joined in the last 14 days</option>
              <option value="30days">Users who joined in the last 30 days</option>
              <option value="90days">Users who joined in the last 90 days</option>
          </CustomSettingsDropdown>

          <WelcomeMsg>Select where notification will send users who tap on it:</WelcomeMsg>
          <CustomSettingsDropdown 
            onChange={this.onSendToChange}
          >
            <option value="">Select place to send user</option>
            <option value="new_feat_feed">Feed Tab</option>
            <option value="new_feat_library">Library Tab</option>
            <option value="new_feat_search">Search Tab</option>
            <option value="new_feat_alerts">Alerts Tab</option>
            <option value="new_feat_profile">Profile Tab</option>
            <option value="new_feat_podcast">A Specific Podcast</option>
            <option value="new_feat_episode">A Specific Episode</option>
            <option value="new_feat_groups">A Specific Group</option>
            <option value="new_feat_user">A Specific User</option>
          </CustomSettingsDropdown>
          <Spacer/>

          <InputGroup
            id="custom_id"
            label="Custom ID (if required):"
            placeholder={(this.requiresCustomId(takes_to) ? "Please type in a custom ID" : "Not Required")}
            type="number"
            disabled={!this.requiresCustomId(takes_to)}
            value={custom_id}
            onChange={(e, input) => this.setState({ custom_id: input.target.value })}
          />
          
          <InputGroup
            id="notificationText"
            label="Full text to be displayed in the notification:"
            placeholder="Merry Christmas from Goodpods! Here's what we're listening to over Christmas"
            type="text"
            value={notificationText}
            onChange={(e, input) => this.setState({ notificationText: input.target.value })}
          />

          <ActionButton 
            onClick={this.onClick}
          >
            Send Notification To Selected Audience
          </ActionButton>

        </CustomForm>
      </FlexItemPadded>
    );
  }
}