import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  CONSTANTS,
  FlexRow,
  FlexItem,
  VCenteredImage,
  VCenteredContent,
} from '../assets/CONSTANTS';

const AddOnly = styled.button`
  padding: 10px 15px;
  margin-right: 25px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  border-radius: 4px;
  background-color: transparent;
  font-size: 14px;
  border-bottom: 2px solid ${CONSTANTS.color.borderGrey};
  outline: none;
  cursor: pointer;
`;

const SubText = styled.span`
  color: ${CONSTANTS.color.greyP};
`;

const AddVerify = styled(AddOnly)``;

class AddVerifiedPodcastRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // eslint-disable-next-line class-methods-use-this
  generateRowData(rowsData) {
    const { addClaimedPod, addVerifiedPod } = this.props;
    return rowsData.map((row) => {
      return (
        <FlexRow paddedVert tableRowContainer key={row.id} flex={1}>
          <FlexItem textAlign="center">
            <Link to={`/Episode/${row.id}`}>
              <VCenteredImage circleImg width="50px" height="50px" alt="" src={row.image} />
            </Link>
          </FlexItem>
          <FlexItem textAlign="left" flex={5}>
            <VCenteredContent>
              {row.title}
              <br />
              <SubText>{row.artist}</SubText>
            </VCenteredContent>
          </FlexItem>

          <FlexItem textAlign="right" flex={2}>
            <VCenteredContent>
              <AddVerify data-pod-id={row.id} onClick={(e) => addVerifiedPod(e)}>
                Add and verify
              </AddVerify>
            </VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="left" flex={1}>
            <VCenteredContent>
              <AddOnly data-pod-id={row.id} onClick={(e) => addClaimedPod(e)}>
                Add Only
              </AddOnly>
            </VCenteredContent>
          </FlexItem>
        </FlexRow>
      );
    });
  }

  render() {
    return <div>{this.generateRowData(this.props.dataPacket)}</div>;
  }
}

AddVerifiedPodcastRow.propTypes = {
  dataPacket: PropTypes.array,
};

export default AddVerifiedPodcastRow;
