export const addToken = (token, tokenExpiry) => ({
  type: 'ADD_TOKEN',
  payload: { token, tokenExpiry },
});

export const emptyToken = (token = '', tokenExpiry = '') => ({
  type: 'EMPTY_TOKEN',
  payload: { token, tokenExpiry },
});

export const addUserInfo = (data) => ({
  type: 'ADD_USER_INFO',
  payload: data,
});

export const logout = () => ({
  type: 'LOGOUT',
  payload: '',
});

export const AddClickedRCItem = (data) => ({
  type: 'ADD_CLICKED_RESULT',
  payload: { data },
});

export const AddClickedRepUser = (data) => ({
  type: 'ADD_CLICKED_REPUSER',
  payload: { data },
});
