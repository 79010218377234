import React from 'react';
import { connect } from 'react-redux';
// Custom components
import styled from 'styled-components';
import DataBanner from '../components/DataBanner';

//Assets & Styles
import {
  server,
  mapStoreToProps,
  CONSTANTS,
  Flex3,
  FlexMid,
  MobileStackFlexWrapper
} from '../assets/CONSTANTS';

import personIcon from '../assets/SideNav/usersIconAlt.svg';

//Custom Components
import TableView from '../components/TableView';

//Assets & Styles
import TestData from '../data.json';
import { getAuthUser } from '../firebase';

const Spacer = styled.div`
  padding: 10px 0px;
  margin: 5px 0px;
  min-height: 1px;
`;

class TipJars extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        allTipUsers: [
          {
            "name": "Loading...",
            "username": "Loading...",
            "podcast_claims": [],
            "tip_jars": [],
            "enabled": false,
          }
        ],
        genericStats:
      {
        "users_added_tips": "Loading...",
        "users_activated_tips": "Loading...",
        "users_with_podcast_claim": "Loading..."
      }
    };

    // this.getNewNotificationData = this.getNewNotificationData.bind(this);
    this.refreshTipData = this.refreshTipData.bind(this);
  }

  componentDidMount() {
    // Get generic notification statistics
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      const genericUrl = `${server}v2/admin/tipjar-generic-stats`;
      fetch(genericUrl, {
        method: 'GET',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) =>
          this.setState({ genericStats: data }))
        .catch((err) => console.log(err));
  
      // Get group details
  
      const detailedUrl = `${server}v2/admin/view-all-tipjars?limit=1000`;
      fetch(detailedUrl, {
        method: 'GET',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) =>
          this.setState({ allTipUsers: data })
        )
        .catch((err) => console.log(err));
    }).catch((err) => console.log(err));

    }


  refreshTipData(){
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      const detailedUrl = `${server}v2/admin/view-all-tipjars?limit=1000`;
      fetch(detailedUrl, {
        method: 'GET',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) =>
          this.setState({ allTipUsers: data })
        )
        .catch((err) => console.log(err));
    }).catch((err) => console.log(err));
  }


  render() {
    return (
      <div>

        <MobileStackFlexWrapper>
          <Flex3>
            <DataBanner
              wrapperBackground={CONSTANTS.color.blueDB}
              iconBackground={CONSTANTS.color.blueDarkDB}
              dataIcon={personIcon}
              dataValue={this.state.genericStats.users_added_tips}
              dataLabel="Users With Tip Jars"
            />
          </Flex3>
          <FlexMid>
          <DataBanner
              wrapperBackground={`${CONSTANTS.color.blueDB}`}
              iconBackground={`${CONSTANTS.color.blueDarkDB}`}
              dataIcon={personIcon}
              dataValue={this.state.genericStats.users_activated_tips}
              dataLabel="Users Enabled For Tipping"
            />
          </FlexMid>
          <Flex3>
            <DataBanner
              wrapperBackground={CONSTANTS.color.blueDB}
              iconBackground={CONSTANTS.color.blueDarkDB}
              dataIcon={personIcon}
              dataValue={this.state.genericStats.users_with_podcast_claim}
              dataLabel="Users With a Podcast Claim"
            />
          </Flex3>
        </MobileStackFlexWrapper>

        <Spacer />

        <TableView
          tableTitle="Manage Tip Jars"
          linkText=""
          linkPath="/"
          tableTopBar={true}
          rowData={this.state.allTipUsers}
          headerData={TestData.headers.tipjars}
          rowType="tipjars"
          token={this.props.token}
          changeNotificationData={this.refreshTipData}
        />

        <Spacer />

      </div>
    );
  }
}

export default connect(mapStoreToProps)(TipJars);
