import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import InputGroup from './InputGroup';

import { mapStoreToProps, CONSTANTS, server, FlexItem, isValidEmail } from '../assets/CONSTANTS';
import { getAuthUser } from '../firebase';

const { color } = CONSTANTS;

const ErrorMsg = styled.p`
  color: ${CONSTANTS.color.red};
  text-align: left;
`;

const SectionHeading = styled.h1`
  font-weight: bold;
  font-size: 18px;
  padding: ${(props) => (props.padding ? props.padding : '5px 0px;')};
  margin: 20px 0px 20px 0px;
  text-align: left;
`;

const ActionButton = styled.button`
  color: black;
  width: 100%;
  background-color: ${color.yellow};
  border: 1px solid ${color.yellow};
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  min-height: 45px;
  font-weight: bold;
  margin: 20px auto;
  padding: ${(props) => (props.padding ? props.padding : '5px 0px;')};
  transition: 0.15s all ease-in-out;
  &:hover {
    background-color: ${color.yellowP};
    border: 1px solid ${color.yellowP};
  }
`;

const FlexItemPadded = styled(FlexItem)`
  padding: 10px 15px !important;
  box-sizing: border-box;
  margin-bottom: 25px;
`;

class UserEmailChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newEmail: '',
      newEmailError: false,
      errorMsg: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onChange(inputName, e) {
    if (inputName === 'newEmail') {
      const newEmail = e.target.value;
      this.setState({ newEmail });
    }
  }

  onClick() {
    const newUserEmail = this.state.newEmail;

    if (!isValidEmail(newUserEmail)) {
      this.setState({ newEmailError: true, errorMsg: 'Not a valid email.' });
    } else {
      this.changeEmail(newUserEmail);

      this.props.onSubmit();
      this.setState({
        newEmailError: false,
        errorMsg: null,
        newEmail: '',
      });
    }
  }

  changeEmail(email) {
    const currUser = getAuthUser();
    currUser.getIdToken(false).then((firebaseToken) => {
      const url = `${server}v2/admin/edit-user`;
      const reqData = {
        user_id: this.props.userId,
        email_address: email,
      };
      return fetch(url, {
        method: 'POST',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
        body: JSON.stringify(reqData),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => console.log(error));
    }).catch((err) => console.log(err));
  }

  render() {
    const { newEmail, newEmailError, errorMsg } = this.state;
    return (
      <FlexItemPadded
        textAlign="center"
        bgColor={color.whiteP}
      >
        <SectionHeading>Change Email</SectionHeading>
        <form onSubmit={(e) => e.preventDefault()}>
          <InputGroup
            onChange={this.onChange}
            placeholder="user@goodpods.com"
            inputName="newEmail"
            type="text"
            label="Email"
            value={newEmail}
            error={newEmailError}
          />
          {errorMsg ? <ErrorMsg>*{errorMsg}</ErrorMsg> : ''}
          <ActionButton onClick={this.onClick}>Update Email</ActionButton>
        </form>
      </FlexItemPadded>
    );
  }
}

export default connect(
  mapStoreToProps,
)(UserEmailChange);
