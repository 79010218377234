//React & React Router
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { emptyToken } from '../redux/actions';

//Custom Components
import TableView from '../components/TableView';
// import ProgressList from '../components/ProgressList';
import DataBanner from '../components/DataBanner';
// import GraphView from '../components/GraphView';
//Assets & Styles
import {
  mapStoreToProps,
  server,
  CONSTANTS,
  FormatDate,
  Flex3,
  FlexMid,
  MobileStackFlexWrapper,
  ResponsiveWrapper3,
  ResponsiveWrapper4,
} from '../assets/CONSTANTS';
import TestData from '../data.json';
import personIcon from '../assets/SideNav/usersIconAlt.svg';
import speechIcon from '../assets/SideNav/speech.svg';
import emailIcon from '../assets/SideNav/messages.svg';
import { getAuthUser } from '../firebase';


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dashData: {},
      graphData: [],
    };
  }

  componentDidMount() {
    // this.getDashboardData();
    this.initialFetch();
  }

  handleEmptyToken = () => {
    this.props.emptyToken('', '');
  };

  getDashboardData() {
    let ep = `${server}get-admin-dashboard-content`;
    return fetch(ep, {
      method: 'get',
      headers: {
        Authorization: this.props.token,
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.success) {
          this.setState({
            dashData: data,
            graphData: await this.convertToRecharts(data),
          });
        }
      })
      .catch((error) => console.log(error));
  }

  convertToRecharts(data) {
    let dashboard_data = [];
    // TODO: should be map filter, leaving for now
    // eslint-disable-next-line array-callback-return
    data.graph_data.map((day) => {
      let date = FormatDate(day.date, 'graph');
      dashboard_data.push({
        Date: date,
        Users: day.user_count,
        Reviews: day.review_count,
      });
    });
    return dashboard_data;
  }

  async initialFetch() {
    const endpoint = `${server}v2/admin/user-search?limit=10`;
    this.setState({ users: await this.getUsers(endpoint) });
  }
  
  async getUsers(ep, token) {
    const currUser = getAuthUser();
    const firebaseToken = await currUser.getIdToken(false)
    return fetch(ep, {
      method: 'get',
      headers: {
        DjangoAuth: `Bearer ${firebaseToken}`,
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ users_prev: data.next });
        this.setState({ users_next: data.previous });
        return data.results;
      })
      .catch((error) => console.log(error));
  }

  render() {
    let { dashData } = this.state;
    console.log(dashData);
    return (
      <div>
        <MobileStackFlexWrapper>
          <Flex3>
            <DataBanner
              wrapperBackground={`${CONSTANTS.color.blueDB}`}
              iconBackground={`${CONSTANTS.color.blueDarkDB}`}
              dataIcon={personIcon}
              dataValue={dashData.total_user_count}
              dataLabel="Total Users"
            />
          </Flex3>
          <FlexMid>
            <DataBanner
              wrapperBackground={CONSTANTS.color.greenDB}
              iconBackground={CONSTANTS.color.greenDarkDB}
              dataIcon={speechIcon}
              dataValue={dashData.total_podcast_count}
              dataLabel="Total Podcasts"
            />
          </FlexMid>
          <Flex3>
            <DataBanner
              wrapperBackground={CONSTANTS.color.redDB}
              iconBackground={CONSTANTS.color.redDarkDB}
              dataIcon={emailIcon}
              dataValue={dashData.reported_content_count}
              dataLabel="Reported Content"
            />
          </Flex3>
        </MobileStackFlexWrapper>
        <MobileStackFlexWrapper>
          <Flex3>
            <ResponsiveWrapper3>
              {/* <GraphView
                title="Users added this week"
                total={dashData.weekly_user_count}
                data={graphData}
                userGraph={true}
              /> */}
              <h2>TEMP UNAVAILABLE</h2>
            </ResponsiveWrapper3>
          </Flex3>
          <FlexMid>
            <ResponsiveWrapper4>
              {/* <ProgressList dashData={dashData} altView /> */}
              <h2>TEMP UNAVAILABLE</h2>
            </ResponsiveWrapper4>
          </FlexMid>
          <Flex3>
            <ResponsiveWrapper3>
              {/* <GraphView
                title="Reviews added this week"
                total={dashData.weekly_review_count}
                data={graphData}
              /> */}
              <h2>TEMP UNAVAILABLE</h2>
            </ResponsiveWrapper3>
          </Flex3>
        </MobileStackFlexWrapper>

        <br></br>
        <TableView
          tableTitle="New Users"
          linkText="View All"
          linkPath="/Users/All"
          tableTopBar={true}
          rowData={this.state.users}
          headerData={TestData.headers.users}
          rowType="users"
        />
      </div>
    );
  }
}

Home.propTypes = {
  token: PropTypes.string.isRequired,
};
Home.defaultProps = {
  token: '',
};

export default connect(mapStoreToProps, { emptyToken })(Home);
