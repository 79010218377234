import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  CONSTANTS,
  checkData,
  FlexRow,
  FlexItem,
  mapStoreToProps,
  FormatDate
} from '../assets/CONSTANTS';

const propTypes = {
  data: PropTypes.object,
  reported_content: PropTypes.object,
  reported_user: PropTypes.object
};
const defaultProps = {
  data: null,
  reported_content: null,
  reported_user: null
};

const DateTag = styled.span`
  background-color: ${CONSTANTS.color.greyP};
  color: ${CONSTANTS.color.whiteP};
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px;
`;

// const ImageTitle = styled.h5`
//   text-align: center;
//   padding: 10px 0px;
//   width: 100px;
//   margin: -3px auto 0px 0px;
//   border-bottom: 1px solid ${CONSTANTS.color.greyP};
//   border-top: 1px solid ${CONSTANTS.color.greyP};
//   border-right: 1px solid ${CONSTANTS.color.greyP};
//   border-left: 1px solid ${CONSTANTS.color.greyP};
// `;

function UserContent({ data }) {
  UserContent.defaultProps = {};
  UserContent.propTypes = {
    data: PropTypes.object.isRequired
  };
  const day = FormatDate(data.created_date, 'd');
  const month = FormatDate(data.created_date, 'm');
  console.log(data);
  return (
    <FlexRow>
      <FlexItem flex={2} textAlign="left">
        <img alt="src" src={data.reporting_user.image} width="100px" />
      </FlexItem>
      <FlexItem flex={1} textAlign="right">
        <DateTag>{`${month} ${day}`}</DateTag>
      </FlexItem>
    </FlexRow>
  );
}
// NOT IN USE

// function ReviewContent({ data }) {
//   ReviewContent.defaultProps = {};
//   ReviewContent.propTypes = {
//     data: PropTypes.object.isRequired
//   };
//   const day = FormatDate(data.created_date, 'd');
//   const month = FormatDate(data.created_date, 'm');
//   return (
//     <FlexRow>
//       <FlexItem flex={2} textAlign="left">
//         <img alt="src" src={`${data.reported_review_data.episode_data.image}`} width="100px" />
//         <ImageTitle>{data.reported_review_data.podcast_data.title}</ImageTitle>
//       </FlexItem>
//       <FlexItem flex={1} textAlign="right">
//         <DateTag>{`${month} ${day}`}</DateTag>
//       </FlexItem>
//     </FlexRow>
//   );
// }

// function CommentContent({ data }) {
//   CommentContent.defaultProps = {};
//   CommentContent.propTypes = {
//     data: PropTypes.object.isRequired
//   };
//   const day = FormatDate(data.created_date, 'd');
//   const month = FormatDate(data.created_date, 'm');
//   return (
//     <FlexRow>
//       <FlexItem flex={2} textAlign="left">
//         <img
//           alt="PodcastEpisode"
//           src={`${data.reported_comment_data.episode_data.image}`}
//           width="100px"
//         />
//         <ImageTitle>{data.reported_comment_data.episode_data.podcast_title}</ImageTitle>
//       </FlexItem>
//       <FlexItem flex={1} textAlign="right">
//         <DateTag>{`${month} ${day}`}</DateTag>
//       </FlexItem>
//     </FlexRow>
//   );
// }

class SelectedContentHeader extends React.Component {
  render() {
    let userData = '';
    if (this.props.reported_content.clickedResult !== null) {
      if (this.props.reported_user !== null) {
        userData = this.props.reported_user;
      }
      const { data } = this.props.reported_content.clickedResult;
      let dataType = checkData(data);
      switch (dataType) {
        case 'comment': {
          return <></>;
        }
        case 'user': {
          return <UserContent data={data} userData={userData} />;
        }
        case 'review': {
          return <></>;
        }
        default: {
          return <div>No Content Found</div>;
        }
      }
    } else {
      return <></>;
    }
  }
}

export default connect(mapStoreToProps)(SelectedContentHeader);

SelectedContentHeader.propTypes = propTypes;
SelectedContentHeader.defaultProps = defaultProps;
