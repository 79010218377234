import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

// custom components
import AdminItems from './AdminItems';

// styling + constants + helper functions
import {
  CONSTANTS, server, FlexItem, FlexRow, mapStoreToProps,
} from '../assets/CONSTANTS';
import { apiGetRequest } from '../lib/api/utils';

const { color } = CONSTANTS;
const gutters = '20px;';

const SectionHeading = styled.h1`
  font-weight: bold;
  font-size: 18px;
  padding: ${(props) => (props.padding ? props.padding : '5px 0px;')};
  margin: 20px 0px 20px 0px;
  text-align: left;
`;

const FlexItemPadded = styled(FlexItem)`
  padding: 10px 15px !important;
  box-sizing: border-box;
  border-radius: 4px;
`;

const WrapRow = styled(FlexRow)`
  flex-wrap: wrap !important;
`;

// component start
class AdminPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admin_users: null,
    };
  }

  componentDidMount() {
    this.initialize();
  }

  async initialize() {
    this.setState({ admin_users: await this.getAdminUsers() });
  }

  getAdminUsers() {
    const url = `${server}v2/admin/user-search?is_admin=true&limit=50`;
    return apiGetRequest(url)
      .then((data) => data.results)
      .catch((error) => console.log(error));
  }

  render() {
    return (
      <FlexItemPadded
        textAlign="center"
        bgColor={color.whiteP}
        margin={`margin-bottom:${gutters}`}
        flex={5}
      >
        <SectionHeading>Admins</SectionHeading>

        <WrapRow>
          {this.state.admin_users ? <AdminItems dataPacket={this.state.admin_users} /> : ''}
        </WrapRow>
      </FlexItemPadded>
    );
  }
}

export default connect(mapStoreToProps)(AdminPanel);
