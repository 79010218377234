/* eslint-disable no-console */
import axios from 'axios';

import { getAuthUser } from '../../firebase';

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_SERVER_URL,
});

apiClient.interceptors.request.use(async (config) => {
  const currUser = getAuthUser();

  if (currUser === null) {
    return config;
  }

  const idToken = await currUser.getIdToken(false);
  if (config.headers) {
    // eslint-disable-next-line no-param-reassign
    config.headers.DjangoAuth = idToken ? `Bearer ${idToken}` : '';
  }

  return config;
});
// *******************************************************************************************
//
// ONLY USE THESE UTIL FUNCTIONS FOR V2 ENDPOINTS
//
// *******************************************************************************************
export async function apiPostRequest(
  url,
  data,
) {
  try {
    const response = await apiClient.post(url, data);

    return response.data;
  } catch (error) {
    if (error) {
      console.warn(error);
    }
    throw error;
  }
}

export async function apiGetRequest(
  url,
) {
  try {
    const response = await apiClient.get(url);

    return response.data;
  } catch (error) {
    if (error) {
      console.warn(error);
    }
    throw error;
  }
}

export async function apiDeleteRequest(
  url,
) {
  try {
    const response = await apiClient.delete(url);

    return response.data;
  } catch (error) {
    if (error) {
      console.warn(error);
    }
    throw error;
  }
}

// function to turn object into query string

export const objectToQueryString = (params) => Object.keys(params)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
  .join('&');

// export const queryStringToObject = (string) => {
//   const obj = new URLSearchParams(string);

//   return obj;
// };

export const getNextPageFromURL = (url) => {
  const [endpoint, queryString] = url.split('?');
  const urlSearchParams = new URLSearchParams(queryString);
  const params = Object.fromEntries(urlSearchParams.entries());

  const { limit, offset } = params;
  const limitNumber = parseInt(limit, 10);
  const offsetNumber = parseInt(offset, 10);

  // increment offset by the limit
  params.offset = offsetNumber + limitNumber;
  const next = `${endpoint}?${objectToQueryString(params)}`;
  return next;
};

export const getPrevPageFromURL = (url) => {
  const [endpoint, queryString] = url.split('?');
  const urlSearchParams = new URLSearchParams(queryString);
  const params = Object.fromEntries(urlSearchParams.entries());

  const { limit, offset } = params;
  const limitNumber = parseInt(limit, 10);
  const offsetNumber = parseInt(offset, 10);
  if (offsetNumber === 0) {
    return null;
  }
  // decrement offset by the limit
  params.offset = offsetNumber - limitNumber;
  const prev = `${endpoint}?${objectToQueryString(params)}`;
  return prev;
};
