import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
// Custom Components
// Assets & Styles
import { CONSTANTS, FlexRow, FlexItem } from '../assets/CONSTANTS';
import BackArrow from '../assets/BackArrow.svg';

const FlexRowWhiteBG = styled(FlexRow)`
  background-color: ${CONSTANTS.color.whiteP};
`;

const BackBtn = styled.img`
  cursor: pointer;
`;

const RoundImg65 = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const Name = styled.h1`
  margin: 0;
`;

const Email = styled.h4`
  margin: 0;
  padding: 5px 0px 0px 0px;
  color: ${CONSTANTS.color.greyP};
  font-weight: 400;
`;

const Biography = styled.p`
  margin: 0;
  padding: 5px 0px 0px 0px;
  color: ${CONSTANTS.color.greyP};
  font-weight: 400;
`;

const PaddedWrapper = styled.div`
  padding: 0px 20px;
`;

const OuterFlexItem = styled(FlexItem)`
  padding-left: 40px;
  padding-right: 40px;
  padding: 15px 30px;
`;

const Count = styled.h4`
  margin: 0;
  padding-top: 5px;
  font-weight: bold;
  font-size: 1.4rem;
`;
const CountLabel = styled.p`
  margin: 0;
  color: ${CONSTANTS.color.greyP};
  font-weight: 400;
`;

const Heading = styled(Name)`
  font-size: 1.3rem;
  padding-top: 10px;
  margin-bottom: 10px;
`;

class ProfileSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.returnToProfile = this.returnToProfile.bind(this);
  }

  returnToProfile() {
    console.log('test');
  }

  render() {
    const { userData, addingVerifiedPod, handleAddVerifiedPod } = this.props;
    const date = new Date(userData.created_date);

    return (
      <FlexRowWhiteBG margin="margin:20px 0px;">
        <FlexItem padded flex={3}>
          <FlexRow paddedVert>
            <FlexItem flex={1}>
              {!addingVerifiedPod ? (
                // <Link to="/Users">
                <BackBtn alt="back" src={BackArrow} onClick={() => this.props.history.goBack()} />
              ) : (
                // </Link>
                <BackBtn alt="back" onClick={() => handleAddVerifiedPod()} src={BackArrow} />
              )}
            </FlexItem>
            <FlexItem flex={2}>
              <RoundImg65 alt="" src={userData.profile_url} />
            </FlexItem>
            <FlexItem flex={8}>
              <PaddedWrapper>
                <Name>{`${userData.first_name} ${userData.last_name}`}</Name>
                <Email>{userData.user_email}</Email>
                <Email>{`@${userData.username}`}</Email>
              </PaddedWrapper>
            </FlexItem>
          </FlexRow>
        </FlexItem>
        <OuterFlexItem padded flex={2}>
          <Heading>Biography</Heading>
          <Biography>{userData.user_bio}</Biography>
          <Biography style={{ color: 'red' }}>
            {userData.auto_hide ? '* Private Profile *' : ''}
          </Biography>
        </OuterFlexItem>
        <OuterFlexItem padded flex={4}>
          <Heading>Stats</Heading>
          <FlexRow>
            <FlexItem flex={1}>
              <Count>{userData.review_count ? userData.review_count : '0'}</Count>
              <CountLabel>Reviews</CountLabel>
            </FlexItem>
            <FlexItem flex={1}>
              <Count>{userData.listen_count}</Count>
              <CountLabel>Listens</CountLabel>
            </FlexItem>
            <FlexItem flex={1}>
              <Count>{userData.follower_count ? userData.follower_count : '0'}</Count>
              <CountLabel>Followers</CountLabel>
            </FlexItem>
            <FlexItem flex={1}>
              <Count>{userData.following_count ? userData.following_count : '0'}</Count>
              <CountLabel>Following</CountLabel>
            </FlexItem>
            <FlexItem flex={1}>
              <Count>{date.toLocaleDateString()}</Count>
              <CountLabel>Date Joined</CountLabel>
            </FlexItem>
          </FlexRow>
        </OuterFlexItem>
      </FlexRowWhiteBG>
    );
  }
}

export default withRouter(ProfileSummary);
