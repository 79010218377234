import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Checkbox from './Checkbox';

import {
  CONSTANTS, FlexCol, FlexItem,
} from '../assets/CONSTANTS';

const propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.array,
  onToggle: PropTypes.func,
  onSubmit: PropTypes.func,
  influencers: PropTypes.array,
};

const defaultProps = {};

const Wrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 600;
`;
const Modal = styled.div`
  background-color: ${CONSTANTS.color.whiteP};
  position: absolute;
  top: calc(10vh);
  left: calc(50vw - 20%);
  max-width: 40%;
  width: 35%;
  min-width: 280px;
  padding: 40px;
  text-align: center;
  border-radius: 4px;
`;
const InnerWrapper = styled.div`
  position: relative;
`;
const Header = styled(FlexCol)`
  margin-top: 10px;
`;
const EpImage = styled.img`
  margin: 0px auto 20px auto;
  border-radius: 50%;
`;
const PodTitle = styled.h2`
  margin: 0px 0px;
`;
const EpTitle = styled.h1`
  margin: 10px 0px;
`;
const EpDate = styled.p`
  margin: 0px;
  font-weight: 300;
`;
const PaddedContainer = styled.div`
  padding: 25px 0px;
`;
const FeatureHeading = styled.h3`
  margin-top: 0;
`;
const ConfirmButton = styled.button`
  padding: 15px 25px;
  border-radius: 4px;
  border: none;
  background-color: ${CONSTANTS.color.yellowP};
  font-weight: bold;
  font-size: 1.25rem;
  width: 80%;
  outline: none;
  cursor: pointer;
`;
const CloseBtn = styled.button`
  position: absolute;
  color: ${CONSTANTS.color.lightGrey};
  top: -40px;
  right: -30px;
  border: none;
  font-weight: bold;
  font-size: 1.25rem;
  outline: none;
  cursor: pointer;
`;
const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: max-content;
  margin: auto;
  margin-bottom: 20px;
`;

class FeaturedModal extends React.Component {

  render() {
    const {
      onClick, data, onToggle, onSubmit,
    } = this.props;
    console.log('modal data', data);
    return (
      <Wrapper>
        <Modal>
          <InnerWrapper>
            <CloseBtn onClick={() => onClick()}>X</CloseBtn>
            <Header>
              <FlexItem textAlign="center">
                <EpImage alt="episodeIMG" width="125px" height="125px" src={`${data.image}`} />
              </FlexItem>
              <FlexItem textAlign="center">
                <PodTitle>{data.title}</PodTitle>
              </FlexItem>
              <FlexItem textAlign="center">
                <EpTitle>{data.parent_pod}</EpTitle>
              </FlexItem>
              <FlexItem textAlign="center">
                <EpDate>{data.date}</EpDate>
              </FlexItem>
            </Header>
            <PaddedContainer />

            <FeatureHeading>Add to a feature</FeatureHeading>
              <CheckboxesContainer>
                <Checkbox
                  id="rec"
                  onClick={onToggle}
                  label="Goodpods Recommends"
                  type="checkbox"
                  checked={data.is_recommended}
                />
                <Checkbox
                  id="fog"
                  onClick={onToggle}
                  label="Friends of Goodpods"
                  type="checkbox"
                  checked={data.is_friends_of_goodpods}
                />
                <Checkbox
                  id="dhn"
                  onClick={onToggle}
                  label="Discussions Happening Now"
                  type="checkbox"
                  checked={data.is_discussions_happening_now}
                />
              </CheckboxesContainer>
              {/* <FlexItem>
                {data.featured_status.top_trending !== true ? (
                  <Checkbox
                    id="tt"
                    onClick={onToggle}
                    label="Top Trending"
                    type="checkbox"
                    checked={false}
                  />
                ) : (
                  <Checkbox
                    id="tt"
                    onClick={onToggle}
                    label="Top Trending"
                    type="checkbox"
                    checked={true}
                  />
                )}
              </FlexItem> */}
            {/* <BottomFlexContainer>
              <FlexItem flex={1}>
                {this.checkInfluencer(data) ? (
                  <Checkbox
                    onClick={e => {
                      onToggle(e, 'off');
                      let influencerEl = document.getElementById('itk-dropdown');
                      influencerEl.value = 0;
                    }}
                    id="itk"
                    label="Verified"
                    type="checkbox"
                    checked={true}
                  />
                ) : (
                  <Checkbox
                    onClick={() => {}}
                    id="itk"
                    label="Verified"
                    type="checkbox"
                    checked={false}
                  />
                )}
              </FlexItem>
              <FlexItem flex={2}>
                <Dropdown
                  id="itk-dropdown"
                  onChange={e => {
                    onToggle(e);
                  }}
                >
                  <option value="0">Select a verified user</option>
                  {this.generateInfluencers(influencers)}
                </Dropdown>
              </FlexItem>
            </BottomFlexContainer> */}

            <ConfirmButton onClick={() => onSubmit()}>Save Changes</ConfirmButton>
          </InnerWrapper>
        </Modal>
      </Wrapper>
    );
  }
}

FeaturedModal.propTypes = propTypes;
FeaturedModal.defaultProps = defaultProps;

export default FeaturedModal;
