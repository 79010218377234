// React & React Router
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Custom Components
import TableView from '../components/TableView';

// Assets & Styles
import TestData from '../data.json';
import {
  CONSTANTS, server, mapStoreToProps, sortUserComparison,
} from '../assets/CONSTANTS';
import { apiGetRequest, getNextPageFromURL, getPrevPageFromURL } from '../lib/api/utils';

const host = `${server}`;
const path = 'v2/admin/user-search';
const initUrlParams = '?limit=20&offset=0';
const deactivatedParam = '&is_deactivated=true';
const endpoint = `${host}${path}${initUrlParams}${deactivatedParam}`;

const PrevNextBtn = styled.button`
  padding: 15px;
  background-color: #fff;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  cursor: pointer;
  outline: none;
  margin: 5px;
  font-weight: bold;
  transition: 0.15s all ease-in-out;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  &:hover {
    background-color: ${CONSTANTS.color.yellowP};
  }
`;

const CenterAligned = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${CONSTANTS.color.whiteP};
  padding: 20px 0px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
`;

class InactiveUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users_prev: null,
      users_next: null,
      users: [],
      deactivated_users: [],
      users_search: '',
      sort: {
        currentField: '',
        sortOrder: '',
      },
    };

    this.handleSortClick = this.handleSortClick.bind(this);
    this.handleFilterInput = this.handleFilterInput.bind(this);
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.loadNextPage = this.loadNextPage.bind(this);
    this.loadPrevPage = this.loadPrevPage.bind(this);
  }

  componentDidMount() {
    this.initialize();
  }

  async initialize() {
    this.setState({ users: await this.getUsers(endpoint) });
  }

  async loadNextPage() {
    const { users_next } = this.state;
    if (users_next) {
      this.setState({ users: await this.getUsers(users_next) });
    }
  }

  async loadPrevPage() {
    const { users_prev } = this.state;
    if (users_prev) {
      this.setState({ users: await this.getUsers(users_prev) });
    }
  }

  handleFilterInput(e) {
    const val = e.target.value;
    this.setState({ users_search: val }, () => console.log(this.state));
  }

  async handleFilterSubmit() {
    const search = this.state.users_search;
    const url = `${server}${path}?search_term=${search}&is_deactivated=true`;
    this.setState({ users: await this.getUsers(url) });
  }

  handleSortClick(e) {
    const sortType = e.currentTarget.getAttribute('data-sorttype');
    if (sortType === this.state.sort.currentField) {
      this.state.sort.sortOrder === 'asc'
        ? this.setState({ sort: { currentField: sortType, sortOrder: 'desc' } }, () => {
          this.setState(
            {
              users: sortUserComparison(
                this.state.users,
                this.state.sort.currentField,
                this.state.sort.sortOrder,
              ),
            },
            () => console.log(this.state),
          );
        })
        : this.setState({ sort: { currentField: sortType, sortOrder: 'asc' } }, () => {
          this.setState(
            {
              users: sortUserComparison(
                this.state.users,
                this.state.sort.currentField,
                this.state.sort.sortOrder,
              ),
            },
            () => console.log(this.state),
          );
        });
    } else {
      this.setState({ sort: { currentField: sortType, sortOrder: 'asc' } }, () => {
        this.setState(
          {
            users: sortUserComparison(
              this.state.users,
              this.state.sort.currentField,
              this.state.sort.sortOrder,
            ),
          },
          () => console.log(this.state),
        );
      });
    }
  }

  async getUsers(ep) {
    return apiGetRequest(ep)
      .then((data) => {
        if (data.results.length !== 20) {
          this.setState({ users_next: null, users_prev: getPrevPageFromURL(ep) });
        } else {
          this.setState({ users_next: getNextPageFromURL(ep), users_prev: getPrevPageFromURL(ep) });
        }
        return data.results;
      })
      .catch((error) => console.log(error));
  }

  render() {
    return (
      <div>
        <TableView
          tableTitle="Users Deactivated by Admin"
          allUsers
          linkText=""
          linkPath=""
          tableTopBar
          onInputChange={this.handleFilterInput}
          onInputSubmit={this.handleFilterSubmit}
          userSearch={this.state.users_search}
          rowData={this.state.users}
          headerData={TestData.headers.users}
          rowType="users"
          sortFunction={this.handleSortClick}
          sortData={this.state.sort}
        />
        <CenterAligned>
          <PrevNextBtn
            disabled={this.state.users_prev === null}
            onClick={() => this.loadPrevPage()}
          >
            &lt;
          </PrevNextBtn>
          <PrevNextBtn
            disabled={this.state.users_next === null}
            onClick={() => this.loadNextPage()}
          >
            &gt;
          </PrevNextBtn>
        </CenterAligned>
      </div>
    );
  }
}

export default connect(mapStoreToProps)(InactiveUsers);
