//React & React Router
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

//Custom Components
import InputGroup from '../components/InputGroup';

//Assets & Constants
import { CONSTANTS, server, mapStoreToProps, LoginButton, Logo } from '../assets/CONSTANTS';
import logo from '../assets/HeaderBar/logo@2x.jpg';
// import mailIcon from '../assets/Login/close-envelope.jpg';
import loginBG from '../assets/Login/adminImage.jpg';
// import DataBanner from '../components/DataBanner';

//Styled-components
const LogBTN = styled.button`
  font-weight: bold;
  ${LoginButton}
`;
const Background = styled.div`
  background-image: url(${loginBG});
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 80, 0.5);
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
`;
const Wrapper = styled.div`
  border-radius: 30px;
  position: absolute;
  background-color: #fff;
  width: 350px;
  min-width: 350px;
  left: calc(50% - 175px);
  top: 15%;
  padding: 25px 40px;
  text-align: center;
`;

const APIResponse = styled.p`
  color: ${(props) => (props.success ? '#459000' : CONSTANTS.color.red)};
`;

const Instructions = styled.p`
  color: ${CONSTANTS.color.blackA50};
  font-size: 14px;
  margin: 40px 0px 15px 0px;
`;

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      submitted: false,
      error: null,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(inputType, e) {
    this.setState({ email: e.target.value }, () => console.log(this.state));
  }

  sendEmailResetReq(url) {
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        this.setState({ error: data?.OK ? true : false, submitted: true });
        console.log(data);
        return data;
      })
      .catch((error) => console.log(error));
  }

  handleSubmit() {
    this.setState({ submitted: false });
    const url = `${server}v2/user/reset-password?email=${this.state.email}`;
    this.sendEmailResetReq(url);
  }

  render() {
    return (
      <Background>
        <Wrapper>
          <Logo alt="goodpods" src={logo} />
          <p>Forgot Password</p>
          <Instructions>
            Please enter your registered Email ID.<br></br>
            You will receive a password reset link in your Email.
          </Instructions>
          <form onSubmit={(e) => e.preventDefault()}>
            <InputGroup
              inputName="email"
              onChange={this.handleInputChange}
              label="Email ID"
              inputType="text"
              placeholder="example@goodpods.com"
              value={this.state.email}
            />
            {this.state.submitted && this.state.error === true ? (
              <APIResponse success={this.state.error}>
                Please check your emails for a password reset link.
              </APIResponse>
            ) : (
              ''
            )}
            {this.state.submitted && this.state.error === false ? (
              <APIResponse success={this.state.error}>
                A User with this email does not exist.
              </APIResponse>
            ) : (
              ''
            )}
            <LogBTN onClick={this.handleSubmit} type="button">
              Submit
            </LogBTN>
          </form>
        </Wrapper>
      </Background>
    );
  }
}

export default connect(mapStoreToProps)(ForgotPassword);
