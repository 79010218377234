import React, { useState } from 'react';
import styled from 'styled-components';

import {
  FlexRow,
  VCenteredContent,
  CONSTANTS,
  VCenteredImage,
} from '../assets/CONSTANTS';

import searchIcon from '../assets/HeaderBar/search.png';
import reloadIcon from '../assets/reload.svg';
import loadingGif from '../assets/loading.gif';

import PodcastErrorModal from '../components/PodcastErrorModal';

import { convertDateToHowLongAgoString } from '../timeUtility';

const TableLink = styled.a`
  text-decoration: none;
  color: ${CONSTANTS.color.linkBlue};
`;

const Row = styled(FlexRow)``;

const Wrapper = styled.div`
  background-color: white;
  padding: 30px
`;

const LinkToModal = styled.div`
  color: ${CONSTANTS.color.linkBlue};
  cursor: pointer;
`;

const Heading = styled.p`
  text-transform: uppercase;
  color: ${CONSTANTS.color.blackA70};
  font-size: 1rem;
  cursor: pointer;
  user-select: none;
`;

const HeaderRow = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: row
`;

const TableTitle = styled.span`
  font-size: 2.2rem;
`;

const SearchInput = styled.input`
  height: 36px;
  border: none;
  padding-left: 35px;
  padding-right: 20px;
  background-color: transparent;
  outline: none;
  width: calc(100% - 45px);
`;

const InputWrapper = styled.div`
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 55px;
  width: 225px;
  margin-right: 25px;
`;

const SearchIcon = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
`;

const PodcastHealthTableRow = ({ 
  dataPacket, 
  headerData, 
  tableTitle, 
  onFilterPodcasts, 
  checkPodcastHandler,
  refreshingPodcastID
}) => {

  const [ errorMessage, setErrorMessage ] = useState(''),
        [ isUpdateError, setIsUpdateError ] = useState(true),
        [ isPodcastInfo, setIsPodcastInfo ] = useState(false);

  const FlexItem = styled.div`
    padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '0px')}; 
    padding-right: ${(props) => (props.paddingRight ? props.paddingRight : '0px')}; 
    width: ${(props) => (props.width ? props.width : `${100 / headerData.length}%`)};
    margin-left: 10px;
    text-align: center;
  `;

  const launchErrorModalHandler = (isUpdateError, errorMessage, isPodcastInfo=false) => {
    setErrorMessage(errorMessage);
    setIsUpdateError(isUpdateError);
    setIsPodcastInfo(isPodcastInfo);
  };

  return (
    <>
      <Wrapper>
        <TableHeaderWrapper>
          <TableTitle>{tableTitle}</TableTitle>
          <InputWrapper>
            <SearchIcon src={searchIcon} alt="search" />
            <SearchInput
              onChange={(e) => null}
              onKeyPress={onFilterPodcasts}
              placeholder={`Filter Podcasts`}
              type="text"
            />
          </InputWrapper>
        </TableHeaderWrapper>
        <HeaderRow>     
          {headerData.map(header => (
            <FlexItem textAlign="center">
              <Heading>{header}</Heading>
            </FlexItem>
          ))}
        </HeaderRow> 
        {dataPacket?.map(row => (
          <Row paddedVert tableRowContainer key={row.campaign_id} padded>
            <FlexItem textAlign="center">
              <LinkToModal onClick={_ => launchErrorModalHandler(true, JSON.stringify(row), true)}>{row?.title}</LinkToModal> 
            </FlexItem>
            <FlexItem textAlign="center">
              <VCenteredContent>{row?.subscriber_count}</VCenteredContent>
            </FlexItem>
            <FlexItem textAlign="center">
              <VCenteredContent><TableLink href={row?.feed_url} target="_blank">{row?.feed_url}</TableLink></VCenteredContent>
            </FlexItem>
            <FlexItem textAlign="center">
              <VCenteredContent>{row?.can_update ? 'Yes' : <LinkToModal onClick={_ => launchErrorModalHandler(true, row?.update_error)}>No</LinkToModal>}</VCenteredContent>
            </FlexItem>
            <FlexItem textAlign="center">
              <VCenteredContent>{row?.can_play ? 'Yes' : <LinkToModal onClick={_ => launchErrorModalHandler(false, row?.play_error)}>No</LinkToModal>}</VCenteredContent>
            </FlexItem>
            <FlexItem textAlign="center">
              <VCenteredContent>{convertDateToHowLongAgoString(row?.last_checked)}</VCenteredContent>
            </FlexItem>
            <FlexItem textAlign="center">
              {row?.podcast_id === refreshingPodcastID ?
                <VCenteredImage  object-fit={'cover'} width="80px" height="80px" alt="" src={loadingGif} isDefault={true} />
                :
                <VCenteredImage  width="25px" height="25px" alt="" src={reloadIcon} onClick={() => checkPodcastHandler(row?.podcast_id)} />
              }
            </FlexItem>
          </Row>
        ))}
      </Wrapper>
      {errorMessage &&
        <PodcastErrorModal 
          errorMessage={errorMessage}
          onOKPressHandler={() => setErrorMessage('')}
          isUpdateError={isUpdateError}
          isPodcastInfo={isPodcastInfo}
        />
      }
    </>
  )
};

export default PodcastHealthTableRow;


