// React & React Router
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
// Custom Components
import ProfileSummary from '../components/ProfileSummary';
import ProfileStatus from '../components/ProfileStatus';
import ProfileActions from '../components/ProfileActions';
import ProfileReviews from '../components/ProfileReviews';
import ProfileVerifiedPods from '../components/ProfileVerifiedPods';
import ProfileAddingVPod from '../components/ProfileAddingVPod';
import UserPasswordChange from '../components/UserPasswordChange';
import ProfileComments from '../components/ProfileComments';
import ReplaceUserAuth from '../components/ReplaceUserAuth';

// Assets & Styles
import CONSTANTS, {
  mapStoreToProps, server, FlexRow, FlexCol, FlexItem,
} from '../assets/CONSTANTS';
import UserEmailChange from '../components/UserEmailChange';
import UserEmailVerification from '../components/UserEmailVerification';
import { apiDeleteRequest, apiGetRequest } from '../lib/api/utils';

const host = `${server}`;
const profilePath = 'v2/admin/user-search';
const reviewPath = 'v2/admin/reviews';
const commentsPath = 'feed_comments';
const URLParams = '?user_id=';

const profileURL = `${host}${profilePath}`;
const reviewsURL = `${host}${reviewPath}`;
const commentsURL = `${host}${commentsPath}${URLParams}`;

const Wrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 600;
`;
const Modal = styled.div`
  background-color: ${CONSTANTS.color.whiteP};
  position: absolute;
  top: calc(50vh - 150px);
  left: calc(50vw - 20%);
  max-width: 40%;
  width: 40%;
  min-width: 280px;
  padding: 40px;
  text-align: center;
`;
const Heading = styled.h1`
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.4rem;
`;
const Message = styled.p``;
const CloseButton = styled.button`
  padding: 10px 25px;
  border-radius: 4px;
  border: none;
  background-color: ${CONSTANTS.color.yellowP};
  font-weight: bold;
  width: 80%;
  outline: none;
  cursor: pointer;
`;

function ChangesModal({ onClick }) {
  return (
    <Wrapper>
      <Modal>
        <Heading>Success!</Heading>
        <Message>The changes have been saved</Message>
        <CloseButton onClick={(e) => onClick(e)}>Got it!</CloseButton>
      </Modal>
    </Wrapper>
  );
}
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users_prev: '',
      users_next: '',
      user: null,
      reviews: null,
      comments: null,
      addingVerifiedPod: false,
      profileEndpoint: `${profileURL}?user_profile_id=${this.props.match.params.uID}&include_claimed_podcasts=true`,
      reviewEndpoint: `${reviewsURL}?user_id=${this.props.match.params.uID}`,
      commentsEndpoint: `${commentsURL}${this.props.match.params.uID}`,
      profileReload: false,
      modalActive: false,
    };

    this.hardReload = this.hardReload.bind(this);
    this.handleRDelete = this.handleRDelete.bind(this);
    this.handleCDelete = this.handleCDelete.bind(this);
    this.handleAddVerifiedPod = this.handleAddVerifiedPod.bind(this);
    this.updateVerifiedPodsList = this.updateVerifiedPodsList.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  async componentDidMount() {
    this.setState({
      user: await this.getProfileDataV2(this.state.profileEndpoint, 'single'),
      reviews: await this.getProfileDataV2(this.state.reviewEndpoint, 'multi'),
      /* TODO: ADD V2 */
      // comments: await this.getProfileData(this.state.commentsEndpoint, 'multi'),
    });
  }

  handleRDelete(e) {
    const r_id = e.currentTarget.id;
    this.reviewDelete(r_id);
  }

  handleCDelete(e) {
    const c_id = e.currentTarget.id;
    this.commentDelete(c_id);
  }

  handleAddVerifiedPod() {
    const { addingVerifiedPod } = this.state;

    this.setState({ addingVerifiedPod: !addingVerifiedPod });
  }

  handleModalClose() {
    this.setState({ modalActive: false });
  }

  async getProfileDataV2(ep, resultsCount) {
    return apiGetRequest(ep)
      .then((data) => {
        if (resultsCount === 'single') {
          return data.results[0];
        }

        return data;
      })
      .catch((error) => console.log(error));
  }

  updateVerifiedPodsList(data) {
    const { user } = this.state;
    const userObj = { ...user };
    userObj.user_podcasts = data;
    this.setState({ user: userObj });
  }

  hardReload(reloadState) {
    const reload = reloadState;
    this.setState({ profileReload: !reload }, () => console.log(this.state));
  }

  reviewDelete(r_id) {
    const ep = `${server}v2/admin/delete-feed-item?feed_item_id=${r_id}`;

    apiDeleteRequest(ep)
      .then(async (data) => {
        this.setState({
          reviews: await this.getProfileDataV2(this.state.reviewEndpoint, 'multi'),
        });
        return data;
      })
      .catch((error) => console.log(error));
  }

  // commentDelete(c_id) {
  //   const ep = `${server}delete-comment`;
  //   const data = new FormData();
  //   data.append('comment_id', c_id);

  //   fetch(ep, {
  //     method: 'POST',
  //     headers: {
  //       Authorization: this.props.token,
  //     },
  //     body: data,
  //   })
  //     .then((response) => response.json())
  //     .then(async (data) => {
  //       this.setState({
  //         comments: await this.getProfileData(this.state.commentsEndpoint, 'multi'),
  //       });
  //       return data;
  //     })
  //     .catch((error) => console.log(error));
  // }

  handleModalOpen() {
    this.setState({ modalActive: true });
  }

  render() {
    const {
      user, reviews, comments, addingVerifiedPod,
    } = this.state;
    // const { history } = this.props;
    return (
      <div>
        {this.state.modalActive ? <ChangesModal onClick={this.handleModalClose} /> : ''}
        {user ? (
          <div>
            <ProfileSummary
              handleAddVerifiedPod={this.handleAddVerifiedPod}
              addingVerifiedPod={addingVerifiedPod}
              userData={user}
            />
            {!addingVerifiedPod ? (
              <>
                <FlexRow margin="margin:0px 0px;">
                  <ProfileStatus
                    userID={user.id}
                    influencer={user.is_influencer}
                    podcaster={user.user_type === 'podcaster'}
                    featuredPodcaster={user.is_featured_podcaster}
                    eliteReviewer={user.is_elite_reviewer}
                    promoted={user.is_promoted}
                    inTheKnow={user.is_in_the_know}
                    adminScore={user.admin_score}
                    userNote={user.user_note}
                    userGoodFor={user.user_good_for}
                  />
                  <ProfileActions
                    reloadState={this.state.profileReload}
                    reloadFunction={this.hardReload}
                    userData={user}
                  />
                </FlexRow>
                <ProfileVerifiedPods
                  userData={user}
                  updateVerifiedPodsList={this.updateVerifiedPodsList}
                  onAddVerifiedPod={this.handleAddVerifiedPod}
                  verifiedPodsData={user.user_podcasts}
                />
                <FlexRow margin="margin: 16px 0;">
                  <FlexItem margin="margin-right: 25px;">
                    <FlexCol>
                      <UserPasswordChange onSubmit={this.handleModalOpen} userId={user.id} />
                      <UserEmailChange onSubmit={this.handleModalOpen} userId={user.id} />
                    </FlexCol>
                  </FlexItem>
                  <FlexItem>
                    <FlexCol>
                      <ReplaceUserAuth userId={user.id} />
                      <UserEmailVerification onSubmit={this.handleModalOpen} userId={user.id} />
                    </FlexCol>
                  </FlexItem>
                </FlexRow>
              </>
            ) : (
              <ProfileAddingVPod
                userData={user}
                updateVerifiedPodsList={this.updateVerifiedPodsList}
              />
            )}
          </div>
        ) : (
          <div>Loading</div>
        )}

        {reviews && !addingVerifiedPod ? (
          <ProfileReviews onDelete={this.handleRDelete} reviewData={reviews} />
        ) : (
          <div />
        )}
        {comments && !addingVerifiedPod ? (
          <ProfileComments onDelete={this.handleCDelete} commentData={comments} />
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default connect(mapStoreToProps)(Profile);
