//React & React Router
import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import styled from 'styled-components';

//Custom Components
import InputGroup from '../components/InputGroup';

//Assets & Constants
import { CONSTANTS, server, mapStoreToProps, LoginButton, Logo } from '../assets/CONSTANTS';
import logo from '../assets/HeaderBar/logo@2x.jpg';
import loginBG from '../assets/Login/adminImage.jpg';

//Styled-components
const LogBTN = styled.button`
  font-weight: bold;
  ${LoginButton}
`;
const Background = styled.div`
  background-image: url(${loginBG});
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 80, 0.5);

  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
`;
const APIResponse = styled.p`
  color: ${(props) => (props.success ? '#459000' : CONSTANTS.color.red)};
`;

const Wrapper = styled.div`
  border-radius: 30px;
  position: absolute;
  background-color: #fff;
  width: 350px;
  min-width: 350px;
  left: calc(50% - 175px);
  top: 15%;
  padding: 25px 40px;
  text-align: center;
`;

const Instructions = styled.p`
  color: ${CONSTANTS.color.blackA50};
  font-size: 14px;
  margin: 40px 0px 15px 0px;
`;

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPass: '',
      confPass: '',
      error: false,
      msg: '',
      isV2: false,
    };

    this.handleSubmitV1 = this.handleSubmitV1.bind(this);
    this.handleSubmitV2 = this.handleSubmitV2.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    if (params.v) {
      this.setState({ isV2: true });
    }
  }

  validatePass(password, confPass) {
    if (password !== confPass) {
      this.setState({ error: true, msg: 'Validation Error: Passwords dont match' });
      return false;
    } else if (password.length < 6 || confPass.length < 6) {
      this.setState({
        error: true,
        msg: 'Validation Error: Password is too short, minimum 6 characters',
      });
      return false;
    } else if (password.length >= 51 || confPass.length >= 51) {
      this.setState({
        error: true,
        msg: 'Validation Error: Password exceeds maximum of 50 characters',
      });
      return false;
    } else if (password === confPass) {
      this.setState({
        error: false,
        msg: '',
      });
      return true;
    }
  }

  sendPassResetReqV1(url, code) {
    let bodyData = new FormData();
    bodyData.append('code', code);
    bodyData.append('new_password', this.state.newPass);
    this.setState({ newPass: '', confPass: '' });
    fetch(url, {
      method: 'POST',
      body: bodyData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          this.setState({ error: false, msg: 'Password updated succesfully.' });
        } else {
          this.setState({ error: true, msg: data.comment });
        }
        return data;
      })
      .catch((error) => {
        this.setState({ error: true, msg: 'Unable to reset password. Please try again.' });
      });
  }

  sendPassResetReqV2(url, code) {
    this.setState({ newPass: '', confPass: '' });
    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        code: code,
        new_password: this.state.newPass,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data?.detail) {
          this.setState({ error: true, msg: data.detail?.user_msg });
        } else if (data?.OK) {
          this.setState({ error: false, msg: 'Password updated succesfully' });
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onChange(inputName, e) {
    if (inputName === 'newPassword') {
      const newPass = e.target.value;
      this.setState({ newPass });
    } else {
      const confPass = e.target.value;
      this.setState({ confPass });
    }
  }

  handleSubmitV2() {
    let { newPass, confPass } = this.state;
    const url = `${server}v2/user/reset-password`;
    let params = queryString.parse(this.props.location.search);
    if (this.validatePass(newPass, confPass)) {
      this.sendPassResetReqV2(url, params.c);
    }
  }

  handleSubmitV1() {
    let { newPass, confPass } = this.state;
    const url = `${server}PasswordReset`;
    let params = queryString.parse(this.props.location.search);
    if (this.validatePass(newPass, confPass)) {
      this.sendPassResetReqV1(url, params.c);
    }
  }

  render() {
    return (
      <Background>
        <Wrapper>
          <Logo alt="goodpods" src={logo} />
          <>
            <p>Reset Password</p>
            <Instructions>Please enter your new password.</Instructions>
            <form onSubmit={(e) => e.preventDefault()}>
              <InputGroup
                onChange={this.onChange}
                placeholder="New Password"
                inputName="newPassword"
                type="password"
                label="New Password"
                value={this.state.newPass}
              />
              <InputGroup
                onChange={this.onChange}
                placeholder="Confirm Password"
                inputName="confPassword"
                type="password"
                label="Confirm Password"
                value={this.state.confPass}
              />

              <APIResponse success={!this.state.error}>{this.state.msg}</APIResponse>
              <LogBTN
                onClick={this.state.isV2 ? this.handleSubmitV2 : this.handleSubmitV1}
                type="button"
              >
                Submit
              </LogBTN>
            </form>
          </>
          {/*** 
            <>
              <p>Update Goodpods App</p>
              <Instructions>
                Please update your app to reset your password. We've recently changed the way we
                handle your request. Apologies for any inconvenience.
              </Instructions>
            </>
          ***/}
        </Wrapper>
      </Background>
    );
  }
}

export default connect(mapStoreToProps)(PasswordReset);
