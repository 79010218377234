import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CONSTANTS, { FlexRow, FlexItem } from '../assets/CONSTANTS';

const SectionHeading = styled.h4`
  font-weight: 400;
  font-size: 20px;
  margin: 0;
  padding: 15px 20px;
`;

const SeeAllLink = styled(Link)`
  color: ${CONSTANTS.color.greyP};
  text-decoration: none;
  line-height: 52px;
  padding: 15px 20px;
`;

const Wrapper = styled(FlexRow)`
  border-bottom: 1px solid ${CONSTANTS.color.lightGrey};
  padding: 10px 15px;
`;

function SectionTopBar({ title, linkPath }) {
  return (
    <Wrapper>
      <FlexItem>
        <SectionHeading>{title}</SectionHeading>
      </FlexItem>
      <FlexItem textAlign="right">
        <SeeAllLink to={linkPath}></SeeAllLink>
      </FlexItem>
    </Wrapper>
  );
}

export default SectionTopBar;
