import React from 'react';
import styled from 'styled-components';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { emptyToken } from './redux/actions';
import {
  server,
  mapStoreToProps,
  FlexRow,
  sortUserComparison,
  sortPodcastComparison,
  routeNames,
} from './assets/CONSTANTS';

//Custom Components
import HeaderBar from './components/HeaderBar';
import SideNavBar from './components/SideNavBar';

//Page Components
import Search from './pages/Search';
import Home from './pages/Home';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Users from './pages/Users';
import AllUsers from './pages/AllUsers';
import InactiveUsers from './pages/InactiveUsers';
import Podcasts from './pages/Podcasts';
import Settings from './pages/Settings';
import ReportedContent from './pages/ReportedContent';
import PodcastEpisodes from './pages/PodcastEpisodes';
import EpisodeReviews from './pages/EpisodeReviews';
import ForgotPassword from './pages/ForgotPassword';
import PasswordReset from './pages/PasswordReset';
import DynamicEmails from './pages/DynamicEmails';
import Notifications from './pages/Notifications';
import PodcastHealth from './pages/PodcastHealth';
import Groups from './pages/Groups';
import TipJars from './pages/TipJars';
import CuratedLists from './pages/CuratedLists';

// Cliftons Code below
import VerifyPodcast from './pages/VerifyPodcast.js';
import FirebaseAuthWrapper from './FirebaseAuthWrapper';
import { apiGetRequest, getNextPageFromURL, getPrevPageFromURL } from './lib/api/utils';

const MainApp = styled.div`
  overflow-x: hidden;
`;

const propTypes = {};

const defaultProps = {};

class MainAppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoc: {
        name: 'Home',
        path: '/',
      },
      search: {
        searchValue: '',
        searchSubmitted: false,
        searchType: 'users',
        results: [],
        loading: false,
      },
      sort: {
        currentField: '',
        sortOrder: '',
      },
      firebaseUserExists: false,
    };
    this.handleSortClick = this.handleSortClick.bind(this);
    this.setBreadcrumb = this.setBreadcrumb.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      const pageLocationForTabs = routeNames.find((item) => item.path === location.pathname);
      if (!!pageLocationForTabs && pageLocationForTabs?.name && pageLocationForTabs?.path) {
        this.setState({ pageLoc: pageLocationForTabs });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }
  
  //search Functionality
  //
  async handleSearchSubmit(e) {
    if (e.which === 13 || e.keyCode === 13) {
      //gather and check search input value
      let val = this.state.search.searchValue;
      if (val !== '' && val !== undefined && val.length > 1) {
        let url = '';
        let ST = this.state.search.searchType;
        //define url & search type based on current page location
        if (
          this.state.pageLoc.name === 'Podcasts' ||
          this.state.pageLoc.name === 'Featured Podcasts'
        ) {
          url = `${server}v2/podcast/search?search_term=${val}&limit=10&offset=0`;
          ST = 'podcasts';
        } else {
          url = `${server}v2/admin/user-search?searchterm=${val}&limit=10&offset=0`;
          ST = 'users';
        }

        //create new object with current state, update search values
        let searchObj = { ...this.state.search };
        this.setState({ search: { ...this.state.search, loading: true} });
        let searchRet = await this.getSearchResults(url, ST).catch(() => this.setState({ search: { ...this.state.search, loading: false} }));
        searchObj.results = searchRet;

        searchObj.next = getNextPageFromURL(url);
        searchObj.previous = getPrevPageFromURL(url);
        if (searchRet.length !== 10) {
          searchObj.next = null;
        }
        
        searchObj.searchSubmitted = true;
        searchObj.searchType = ST;
        searchObj.loading = false;

        //set state and redirect to search page
        this.setState({ search: searchObj });
        this.props.history.push(`/Search/${val}`);
      }
    }
  }

  handleSearchChange(e) {
    let searchObj = { ...this.state.search };
    searchObj.searchValue = e.target.value;
    searchObj.searchSubmitted = false;

    this.setState({ search: searchObj });
  }

  getSearchResults(ep, searchType) {
    return apiGetRequest(ep)
      .then((data) => {
        if (searchType === 'podcasts') {
        return data;
        }
        return data.results;
      })
      .catch((error) => console.log(error));
  }

  handleSortClick(e) {
    let sortType = e.currentTarget.getAttribute('data-sorttype');
    if (this.state.search.searchType === 'users') {
      if (sortType === this.state.sort.currentField) {
        this.state.sort.sortOrder === 'ASC'
          ? this.setState({ sort: { currentField: sortType, sortOrder: 'DESC' } }, () => {
              let searchObj = { ...this.state.search };
              searchObj.results = sortUserComparison(
                searchObj.results,
                this.state.sort.currentField,
                this.state.sort.sortOrder
              );
              this.setState({ search: searchObj }, () => console.log(this.state));
            })
          : this.setState({ sort: { currentField: sortType, sortOrder: 'ASC' } }, () => {
              let searchObj = { ...this.state.search };
              searchObj.results = sortUserComparison(
                searchObj.results,
                this.state.sort.currentField,
                this.state.sort.sortOrder
              );
              this.setState({ search: searchObj }, () => console.log(this.state));
            });
      } else {
        this.setState({ sort: { currentField: sortType, sortOrder: 'ASC' } }, () => {
          let searchObj = { ...this.state.search };
          searchObj.results = sortUserComparison(
            searchObj.results,
            this.state.sort.currentField,
            this.state.sort.sortOrder
          );
          this.setState({ search: searchObj }, () => console.log(this.state));
        });
      }
    } else {
      if (sortType === this.state.sort.currentField) {
        this.state.sort.sortOrder === 'ASC'
          ? this.setState({ sort: { currentField: sortType, sortOrder: 'DESC' } }, () => {
              let searchObj = { ...this.state.search };
              searchObj.results = sortPodcastComparison(
                searchObj.results,
                this.state.sort.currentField,
                this.state.sort.sortOrder
              );
              this.setState({ search: searchObj }, () => console.log(this.state));
            })
          : this.setState({ sort: { currentField: sortType, sortOrder: 'ASC' } }, () => {
              let searchObj = { ...this.state.search };
              searchObj.results = sortPodcastComparison(
                searchObj.results,
                this.state.sort.currentField,
                this.state.sort.sortOrder
              );
              this.setState({ search: searchObj }, () => console.log(this.state));
            });
      } else {
        this.setState({ sort: { currentField: sortType, sortOrder: 'ASC' } }, () => {
          let searchObj = { ...this.state.search };
          searchObj.results = sortPodcastComparison(
            searchObj.results,
            this.state.sort.currentField,
            this.state.sort.sortOrder
          );
          this.setState({ search: searchObj }, () => console.log(this.state));
        });
      }
    }
  }
  //
  //end of search

  setBreadcrumb(location) {
    let searchObj = { ...this.state.search };
    switch (location) {
      case 'Home':
        searchObj.searchType = 'users';
        this.setState({ pageLoc: { name: 'Home', path: '/' }, search: searchObj });
        break;
      case 'Reported Content':
        searchObj.searchType = 'users';
        this.setState({
          pageLoc: { name: 'Reported Content', path: '/ReportedContent' },
          search: searchObj,
        });
        break;
      case 'Users':
        searchObj.searchType = 'users';
        this.setState({ pageLoc: { name: 'Users', path: '/Users' }, search: searchObj });
        break;

      case 'Dynamic Emails':
        searchObj.searchType = 'users';
        this.setState({
          pageLoc: { name: 'Dynamic Emails', path: '/DynamicEmails' },
          search: searchObj,
        });
        break;

      case 'Notifications':
        searchObj.searchType = 'users';
        this.setState({
          pageLoc: { name: 'Notifications', path: '/Notifications' },
          search: searchObj,
        });
        break;
      case 'Groups':
        searchObj.searchType = 'users';
        this.setState({
          pageLoc: { name: 'Groups', path: '/Groups' },
          search: searchObj,
        });
        break;
        case 'CuratedLists':
          searchObj.searchType = 'users';
          this.setState({
            pageLoc: { name: 'CuratedLists', path: '/CuratedLists' },
            search: searchObj,
          });
          break;
      case 'TipJars':
        searchObj.searchType = 'users';
        this.setState({
          pageLoc: { name: 'TipJars', path: '/TipJars' },
          search: searchObj,
        });
        break;
      case 'Podcasts':
        searchObj.searchType = 'podcasts';
        this.setState({ pageLoc: { name: 'Podcasts', path: '/Podcasts' }, search: searchObj });
        break;
      case 'All Podcasts':
        searchObj.searchType = 'podcasts';
        this.setState({ pageLoc: { name: 'Podcasts', path: '/Podcasts' }, search: searchObj });
        break;
      case 'Featured Podcasts':
        searchObj.searchType = 'podcasts';
        this.setState({
          pageLoc: { name: 'Featured Podcasts', path: '/Podcasts/Featured' },
          search: searchObj,
        });
        break;
      case 'Settings':
        searchObj.searchType = 'users';
        this.setState({ pageLoc: { name: 'Settings', path: '/Settings' }, search: searchObj });
        break;
      case 'PodcastHealth':
        searchObj.searchType = 'podcasts';
        this.setState({ pageLoc: { name: 'PodcastHealth', path: '/' }, search: searchObj });
        break;
      default:
        break;
    }
  }

  render() {
    const { pageLoc, search } = this.state;
    const { results, searchValue, searchType, next, previous, loading } = search;

    return (
      <FirebaseAuthWrapper>
        <MainApp id="App">
          <div id="main-header-wrapper">
            <HeaderBar
              onSearchSubmit={this.handleSearchSubmit}
              onSearchChange={this.handleSearchChange}
              breadcrumb={pageLoc}
              onHeaderNavClick={this.setBreadcrumb}
              searchValue={searchValue}
              searchType={searchType}
              searchLoading={loading}
            />
          </div>
          <div id="sidenav-and-content-wrapper">
            <FlexRow>
              <SideNavBar currentLoc={this.state.pageLoc} onClick={this.setBreadcrumb} />
              <div id="main-content-container">
                <Switch>
                  <Route
                    path="/Search/:searchContent"
                    render={({ match }) => (
                        <Search
                          sortFunction={this.handleSortClick}
                          sortData={this.state.sort}
                          match={match}
                          searchType={searchType}
                          searchContent={results}
                          next={next}
                          previous={previous}
                        />
                      )
                    }
                  />

                  <Route
                    path="/Featured-Podcasts"
                    render={() => <Podcasts activeTab="Featured" />}
                  />
                  <Route
                    path="/Episode/Reviews/:podID"
                    render={({ match, history }) => (
                        <EpisodeReviews match={match} history={history} />)
                    }
                  />
                  <Route
                    path="/Episode/:podID"
                    render={({ match, history }) => <PodcastEpisodes match={match} history={history} />
                    }
                  />
                  <Route
                    path="/Podcasts"
                    render={() => <Podcasts activeTab="All" />}
                  />
                  <Route
                    path="/Users"
                    render={() => <Users />}
                  />
                  <Route
                    path="/DynamicEmails"
                    render={() => <DynamicEmails />}
                  />
                  <Route
                    path="/All-Users"
                    render={() => <AllUsers />}
                  />
                  <Route
                    path="/Deactivated-Users"
                    render={() => <InactiveUsers />}
                  />
                  <Route
                    path="/Profile/:uID"
                    render={({ match, history }) => <Profile history={history} match={match} />
                    }
                  />
                  <Route
                    path="/Settings"
                    render={() => <Settings />}
                  />
                  <Route
                    path="/ReportedContent"
                    render={() => <ReportedContent />}
                  />
                  <Route
                    path="/Notifications"
                    render={() => <Notifications />}
                  />
                  <Route
                    path="/Groups"
                    render={() => <Groups />}
                  />
                  <Route
                    path="/PodcastHealth"
                    render={() => <PodcastHealth />}
                  />
                  <Route
                    path="/TipJars"
                    render={() => <TipJars />}
                  />
                  <Route
                    path="/CuratedLists"
                    render={() => <CuratedLists />}
                  />
                  {/* Clifton's code */}
                  <Route
                    path="/VerifyPodcast"
                    render={({ match, location }) => (
                      <VerifyPodcast match={match} location={location} />
                    )}
                  />
                  {/* Clifton's code */}
                  <Route path="/Login" render={() =>  <Login />} />
                  <Route path="/ForgotPassword" component={ForgotPassword} />
                  <Route
                    path="/PasswordReset"
                    render={({ match, location }) => (
                      <PasswordReset match={match} location={location} />
                    )}
                  />

                  <Route path="/" render={() => <Home />} />
                  <Route render={() => <Login />} />
                </Switch>
              </div>
            </FlexRow>
          </div>
        </MainApp>
      </FirebaseAuthWrapper>
    );
  }
}

MainAppContainer.propTypes = propTypes;
MainAppContainer.defaultProps = defaultProps;

export default connect(mapStoreToProps, { emptyToken })(withRouter(MainAppContainer));
