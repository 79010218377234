import React from 'react';
import styled from 'styled-components';
import queryString from 'query-string';

//Assets & Constants
import { CONSTANTS, server, Logo } from '../assets/CONSTANTS';
import logo from '../assets/HeaderBar/logo@2x.jpg';
import loginBG from '../assets/Login/adminImage.jpg';

const Background = styled.div`
  background-image: url(${loginBG});
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 80, 0.5);
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
`;
const Wrapper = styled.div`
  border-radius: 30px;
  position: absolute;
  background-color: #fff;
  width: 500px;
  max-height: 80vh;
  overflow-y: scroll;
  min-width: 500px;
  left: calc(50% - 175px);
  top: 10%;
  padding: 25px 40px;
  text-align: center;
  @media (max-width: 756px) {
    width: 350px;
    min-width: 350px;
  }
`;

const PodcastImg = styled.img`
  width: 100%;
`;

const APIResponse = styled.p`
  color: ${(props) => (props.success ? '#459000' : CONSTANTS.color.red)};
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
`;

const Label = styled.p`
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: ${CONSTANTS.color.greyP};
  text-align: left;
  margin-top: 20px;
  margin-bottom: 5px;
`;

const PodData = styled.p`
  text-align: left;
  margin-top: 0px;
`;

// const Instructions = styled.p`
//   color: ${CONSTANTS.color.blackA50};
//   font-size: 14px;
//   margin: 40px 0px 15px 0px;
// `;

class VerifyPodcast extends React.Component {
  state = {
    artist: '',
    completedData: '',
    dateCreated: '',
    desc: '',
    id: 0,
    imageUrl: '',
    itunesID: '',
    title: '',
    track_count: 0,
    success: true,
    msg: '',
  };

  componentDidMount() {
    this.handleApiResp();
  }

  handleApiResp = () => {
    const data = new FormData();
    let params = queryString.parse(this.props.location.search);
    let code = params.c;
    data.append('code', code);

    fetch(`${server}verify-claimed-podcast`, {
      method: `POST`,
      body: data,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.success) {
          this.setState({
            artist: result.podcast.artist,
            desc: result.podcast.description,
            imageUrl: result.podcast.image,
            title: result.podcast.title,
            msg: 'Podcast Verified',
            success: result.success,
          });
        } else {
          this.setState({
            msg:
              "Whoops! Looks like your podcast couldn't be confirmed. Please contact us at hello@goodpods.com",
            success: result.success,
          });
        }
      });
  };

  render() {
    const { artist, desc, imageUrl, success, msg, title } = this.state;
    return (
      <Background>
        <Wrapper>
          <Logo alt="goodpods" src={logo} />
          <APIResponse success={success}>{msg}</APIResponse>
          <hr />
          {title ? <Label>Title:</Label> : null}
          <PodData>{title}</PodData>
          {artist ? <Label>Artist:</Label> : null}
          <PodData>{artist}</PodData>
          {desc ? <Label>Description:</Label> : null}
          <PodData>{desc}</PodData>
          <PodcastImg src={imageUrl} />
        </Wrapper>
      </Background>
    );
  }
}

export default VerifyPodcast;
