import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { server } from './assets/CONSTANTS';
import { auth, signUserOut } from './firebase';
import { apiGetRequest } from './lib/api/utils';
import { addUserInfo } from './redux/actions';

// Unauthorised pages
const publicPages = ['/PasswordReset', '/ForgotPassword'];

const FirebaseAuthWrapper = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const getUserData = () => {
    const url = `${server}v2/user/profile`;
    console.log("getting...")
    console.log(url);
    return apiGetRequest(url)
      .then((data) => data)
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // Skip check for public pages
    if (publicPages.includes(history.location.pathname)) {
      setLoading(false);
      return;
    }
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(false);
      if (user) {
        const user_info = await getUserData();
        if (user_info === undefined) {
          history.push('/Login');
          signUserOut();
        }
        else if (user_info.is_admin) {
          addUserInfo(user_info);
          if (history.location.pathname === '/Login') {
            history.push('/');
          }
        } else {
          history.push('/Login');
          signUserOut();
        }
      } else {
        history.push('/Login');
      }
    });
    return unsubscribe;
  }, [history]);

  return <>{loading ? null : children}</>;
};

export default FirebaseAuthWrapper;
