// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  browserSessionPersistence,
  onAuthStateChanged,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

/** Gets the current authed user's profile */
const getAuthUser = () => auth.currentUser;

const onAuthChangeListener = (callback) => onAuthStateChanged(auth, callback);

/** Sign in with Email and Password */
const signIn = async (email, password) => {
  await setPersistence(auth, browserSessionPersistence);

  return signInWithEmailAndPassword(auth, email, password);
};

/** Signs a user out */
const signUserOut = async () => signOut(auth);

export {
  getAuthUser,
  signIn,
  signUserOut,
  onAuthChangeListener,
};
