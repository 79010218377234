import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  FlexRow,
  FlexItem,
  VCenteredContent,
} from '../assets/CONSTANTS';

const Wrapper = styled.div`
  padding: 0px 25px;
  div:first-child {
    border-top: none;
  }
`;

const Row = styled(FlexRow)``;

class UserRow extends Component {
  generateRowData(rowsData) {
    if (rowsData !== undefined) {
        // alert(JSON.stringify(rowsData));
      return rowsData.map((row) => (
        <Row paddedVert tableRowContainer key={row.name} padded>
          <FlexItem textAlign="center">
            <VCenteredContent>{row.name}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>{row.total_sent}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>{row.unique_owners}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>{row.unique_senders}</VCenteredContent>
          </FlexItem>
          <FlexItem textAlign="center">
            <VCenteredContent>{row.read_percent}%</VCenteredContent>
          </FlexItem>
        </Row>
      ));
    }
    return '';
  }

  render() {
    return <Wrapper>{this.generateRowData(this.props.dataPacket)}</Wrapper>;
  }
}

UserRow.propTypes = {
  dataPacket: PropTypes.array,
};

export default UserRow;
