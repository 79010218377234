import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { logout } from '../redux/actions';

import { mapStoreToProps, CONSTANTS, FlexItem, server } from '../assets/CONSTANTS';
import VerifiedIcon from '../assets/verified.svg';
import UnverifiedIcon from '../assets/unverified.svg';
import { getAuthUser } from '../firebase';

const { color } = CONSTANTS;

const SectionHeading = styled.h1`
  font-weight: bold;
  font-size: 18px;
  padding: ${(props) => (props.padding ? props.padding : '5px 0px;')};
  margin: 20px 0px 20px 0px;
  text-align: left;
`;

const VerificationWrapper = styled.div`
  display: flex;
`;

const VerificationText = styled.p`
  color: ${(props) => (props.verified ? color.green4 : color.red5)};
  font-size: 15px;
  font-weight: bold;
`;

const VerificationIcon = styled.img`
  margin-right: 10px;
`;

const ActionButton = styled.button`
  color: black;
  width: 100%;
  background-color: ${color.yellow};
  border: 1px solid ${color.yellow};
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  min-height: 45px;
  font-weight: bold;
  margin: 20px auto;
  padding: ${(props) => (props.padding ? props.padding : '5px 0px;')};
  transition: 0.15s all ease-in-out;
  &:hover {
    background-color: ${color.yellowP};
    border: 1px solid ${color.yellowP};
  }
`;

const FlexItemPadded = styled(FlexItem)`
  padding: 10px 15px !important;
  box-sizing: border-box;
`;

class UserEmailVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userVerified: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.verifyUserEmail();
  }

  verifyUserEmail() {
    const currUser = getAuthUser();
    currUser.getIdToken(false).then((firebaseToken) => {
      const url = `${server}v2/admin/edit-user`;
      const reqData = {
        user_id: this.props.userId,
        email_verified: true,
      };
      return fetch(url, {
        method: 'POST',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
        body: JSON.stringify(reqData),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            this.setState({ userVerified: true });
            this.props.onSubmit();
          }
        })
        .catch((error) => console.log(error));
    }).catch((err) => console.log(err));
  }

  render() {
    const { userVerified } = this.state;
    return (
      <FlexItemPadded
        textAlign="center"
        bgColor={color.whiteP}
      >
        <SectionHeading>Email Verification Status</SectionHeading>
        <VerificationWrapper>
          <VerificationIcon src={userVerified ? VerifiedIcon : UnverifiedIcon} alt="" />
          <VerificationText verified={userVerified}>{userVerified ? 'Verified' : 'Unverified'}</VerificationText>
        </VerificationWrapper>
        {!userVerified && <ActionButton onClick={this.onClick}>Verify Email</ActionButton>}
      </FlexItemPadded>
    );
  }
}

export default connect(
  mapStoreToProps,
  { logout },
)(UserEmailVerification);
