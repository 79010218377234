// React & React Router
import React from 'react';
import styled from 'styled-components';
// Custom Components
import PasswordChange from '../components/PasswordChange';
import DetailChange from '../components/DetailChange';
import AdminPanel from '../components/AdminPanel';
import LogoutPanel from '../components/LogoutPanel';

// Assets & Styles
import {
  CONSTANTS, FlexCol, FlexRow, FlexItem,
} from '../assets/CONSTANTS';

const FlexColumn = styled(FlexCol)`
  height: 100%;
`;

const gutters = '20px;';
const halfGutters = '10px;';

const Wrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 600;
`;
const Modal = styled.div`
  background-color: ${CONSTANTS.color.whiteP};
  position: absolute;
  top: calc(50vh - 150px);
  left: calc(50vw - 20%);
  max-width: 40%;
  width: 40%;
  min-width: 280px;
  padding: 40px;
  text-align: center;
`;
const Heading = styled.h1`
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.4rem;
`;
const Message = styled.p``;
const CloseButton = styled.button`
  padding: 10px 25px;
  border-radius: 4px;
  border: none;
  background-color: ${CONSTANTS.color.yellowP};
  font-weight: bold;
  width: 80%;
  outline: none;
  cursor: pointer;
`;

function ChangesModal({ onClick }) {
  return (
    <Wrapper>
      <Modal>
        <Heading>Success!</Heading>
        <Message>The changes have been saved</Message>
        <CloseButton onClick={(e) => onClick(e)}>Got it!</CloseButton>
      </Modal>
    </Wrapper>
  );
}

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalActive: false,
    };
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
  }

  handleModalClose() {
    this.setState({ modalActive: false });
  }

  handleModalOpen() {
    this.setState({ modalActive: true });
  }

  render() {
    return (
      <div>
        {this.state.modalActive ? <ChangesModal onClick={this.handleModalClose} /> : ''}
        <FlexRow margin={`margin-bottom:${gutters}`}>
          <FlexItem margin={`margin-right:${halfGutters}`}>
            <FlexColumn>
              <AdminPanel />
              <LogoutPanel />
            </FlexColumn>
          </FlexItem>
          <FlexColumn>
            <PasswordChange onSubmit={this.handleModalOpen} />
            <DetailChange onSubmit={this.handleModalOpen} />
          </FlexColumn>
        </FlexRow>
        <FlexRow />
      </div>
    );
  }
}

export default Settings;
