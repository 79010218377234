import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import TableView from './TableView';
import TableData from '../data.json';

import {
  CONSTANTS,
  server,
  mapStoreToProps,
  sortPodcastComparison,
} from '../assets/CONSTANTS';
import searchIcon from '../assets/HeaderBar/search.png';

const WhiteBGWrapper = styled.div`
  background-color: ${CONSTANTS.color.whiteP};
  margin-bottom: 25px;
  border-radius: 4px;
  padding: 10px 15px;
`;

const WhiteBGWrapperNoPad = styled.div`
  background-color: ${CONSTANTS.color.whiteP};
  margin-bottom: 25px;
  border-radius: 4px;
`;

const Heading = styled.h1`
  margin: 0px;
  padding: 25px 0px 10px 0px;
  width: 100%;
`;

const SearchIcon = styled.img`
  position: absolute;
  top: 6px;
  left: 6px;
`;

const SearchInput = styled.input`
  height: 30px;
  border: none;
  padding-left: 32px;
  padding-right: 20px;
  background-color: transparent;
  outline: none;
  width: 100%;
`;
const InputWrapper = styled.div`
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 55px;
  width: 20%;
  margin-right: 25px;
`;

const PrevNextBtn = styled.button`
  padding: 15px;
  background-color: #fff;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  cursor: pointer;
  outline: none;
  margin: 5px;
  font-weight: bold;
  transition: 0.15s all ease-in-out;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  &:hover {
    background-color: ${CONSTANTS.color.yellowP};
  }
`;

const CenterAligned = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${CONSTANTS.color.whiteP};
  padding: 20px 0px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
`;

const host = `${server}`;
const path = 'podcasts';
const urlParams = '';
const endpoint = `${host}${path}${urlParams}`;

const propTypes = {};
const defaultProps = {};

const Wrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 600;
`;
const Modal = styled.div`
  background-color: ${CONSTANTS.color.whiteP};
  position: absolute;
  top: calc(50vh - 150px);
  left: calc(50vw - 10%);
  max-width: 40%;
  width: 20%;
  min-width: 280px;
  padding: 40px;
  text-align: center;
  border-radius: 4px;
`;
const ModalHeading = styled.h1`
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.4rem;
  width: 80%;
  margin: 10px auto;
  text-align: center;
`;
//const Message = styled.p``;
const ConfirmBtn = styled.button`
  padding: 10px 25px;
  border-radius: 4px;
  border: none;
  background-color: ${CONSTANTS.color.yellowP};
  font-weight: bold;
  margin: 5px auto;
  width: 100%;
  outline: none;
  cursor: pointer;
`;

// const CancelBtn = styled(ConfirmBtn)`
//   border: 1px solid ${CONSTANTS.color.borderGrey};
//   background-color: transparent;
// `;

function ConfirmModal({ onClose, modalType }) {
  return (
    <Wrapper>
      <Modal>
        <ModalHeading>This podcast has been {modalType}</ModalHeading>
        <ConfirmBtn onClick={() => onClose()}>Close</ConfirmBtn>
      </Modal>
    </Wrapper>
  );
}

class ProfileAddingVPod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      podcasts: [],
      podcasts_prev: '',
      podcasts_next: '',
      sort: {
        currentField: '',
        sortOrder: '',
      },
      search: {
        searchValue: '',
        searchSubmitted: false,
        searchType: 'podcasts ',
        results: [],
      },
      modal: {
        status: false,
        type: '',
      },
    };
    this.loadNextPage = this.loadNextPage.bind(this);
    this.loadPrevPage = this.loadPrevPage.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  handleCloseModal = () => {
    let objToAdd = {
      status: false,
      type: '',
    };
    this.setState({ modal: objToAdd });
  };

  handleOpenModal = (message) => {
    let objToAdd = {
      status: true,
      type: message,
    };
    this.setState({ modal: objToAdd });
  };

  async componentDidMount() {
    this.setState({ podcasts: await this.getPodcasts(endpoint) });
  }

  getPodcasts(ep) {
    return fetch(ep, {
      method: 'get',
      headers: {
        Authorization: this.props.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ podcasts_next: data.next, podcasts_prev: data.previous });
        return data.results;
      })
      .catch((error) => console.log(error));
  }

  // search Functionality
  //

  getSearchResults(ep) {
    return fetch(ep, {
      method: 'get',
      headers: {
        Authorization: this.props.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        return data.results;
      })
      .catch((error) => console.log(error));
  }

  async loadNextPage() {
    const { podcasts_next } = this.state;
    if (podcasts_next) {
      this.setState({ podcasts: await this.getPodcasts(podcasts_next) });
    }
  }

  async loadPrevPage() {
    const { podcasts_prev } = this.state;
    if (podcasts_prev) {
      this.setState({ podcasts: await this.getPodcasts(podcasts_prev) });
    }
  }

  async handleSearchChange(e) {
    let searchObj = { ...this.state.search };

    if (e.target.value === '') {
      let url = `${server}podcasts`;
      let pods = await this.getSearchResults(url);

      searchObj.searchSubmitted = true;
      searchObj.searchValue = '';

      // set state and redirect to search page
      this.setState({ podcasts: pods, search: searchObj }, () => {
        console.log(this.state);
      });
    } else {
      searchObj.searchValue = e.target.value;
      searchObj.searchSubmitted = false;

      this.setState({ search: searchObj }, () => {
        console.log(this.state);
      });
    }
  }

  handleSortClick(e) {
    let sortType = e.currentTarget.getAttribute('data-sorttype');

    if (sortType === this.state.sort.currentField) {
      this.state.sort.sortOrder === 'ASC'
        ? this.setState({ sort: { currentField: sortType, sortOrder: 'DESC' } }, () => {
            let searchObj = { ...this.state.search };
            searchObj.results = sortPodcastComparison(
              searchObj.results,
              this.state.sort.currentField,
              this.state.sort.sortOrder
            );
            this.setState({ search: searchObj }, () => console.log(this.state));
          })
        : this.setState({ sort: { currentField: sortType, sortOrder: 'ASC' } }, () => {
            let searchObj = { ...this.state.search };
            searchObj.results = sortPodcastComparison(
              searchObj.results,
              this.state.sort.currentField,
              this.state.sort.sortOrder
            );
            this.setState({ search: searchObj }, () => console.log(this.state));
          });
    } else {
      this.setState({ sort: { currentField: sortType, sortOrder: 'ASC' } }, () => {
        let searchObj = { ...this.state.search };
        searchObj.results = sortPodcastComparison(
          searchObj.results,
          this.state.sort.currentField,
          this.state.sort.sortOrder
        );
        this.setState({ search: searchObj }, () => console.log(this.state));
      });
    }
  }
  //
  // end of search
  // Dunno what happened here, ended up with a duplicate function

  // handleSortClick(e) {
  //   let sortType = e.currentTarget.getAttribute('data-sorttype');
  //   if (sortType === this.state.sort.currentField) {
  //     this.state.sort.sortOrder === 'ASC'
  //       ? this.setState({ sort: { currentField: sortType, sortOrder: 'DESC' } }, () => {
  //           this.setState({
  //             podcasts: sortPodcastComparison(
  //               this.state.podcasts,
  //               this.state.sort.currentField,
  //               this.state.sort.sortOrder
  //             ),
  //           });
  //         })
  //       : this.setState({ sort: { currentField: sortType, sortOrder: 'ASC' } }, () => {
  //           this.setState({
  //             podcasts: sortPodcastComparison(
  //               this.state.podcasts,
  //               this.state.sort.currentField,
  //               this.state.sort.sortOrder
  //             ),
  //           });
  //         });
  //   } else {
  //     this.setState({ sort: { currentField: sortType, sortOrder: 'ASC' } }, () => {
  //       this.setState({
  //         podcasts: sortPodcastComparison(
  //           this.state.podcasts,
  //           this.state.sort.currentField,
  //           this.state.sort.sortOrder
  //         ),
  //       });
  //     });
  //   }
  // }

  async handleSearchSubmit(e) {
    if (e.which === 13 || e.keyCode === 13) {
      // gather and check search input value
      let val = this.state.search.searchValue;
      if (val !== '' && val !== undefined && val.length > 1) {
        // define url & search type
        let url = `${server}podcasts?search_term=${val}`;
        let ST = this.state.search.searchType;

        // create new object with current state, update search values
        let searchObj = { ...this.state.search };
        searchObj.results = await this.getSearchResults(url);
        searchObj.searchSubmitted = true;
        searchObj.searchType = ST;

        // set state and redirect to search page
        this.setState({ search: searchObj, podcasts: searchObj.results }, () => {
          console.log(this.state);
        });
      }
    }
  }

  handleAddClaimedPod = (e) => {
    const { userData, updateVerifiedPodsList } = this.props;
    console.log(userData);
    const uID = userData.id;
    const podID = e.target.attributes['data-pod-id'].value;
    const url = `${server}add-podcast-claim`;
    const formData = new FormData();
    formData.append('user_id', uID);
    formData.append('podcast_id', podID);
    formData.append('skip_email', false);
    formData.append('verified', false);

    console.log(`Adding Claim to Podcast ${podID}, from User: ${uID}`);
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: this.props.token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          this.handleOpenModal('added, ready for verification.');
          updateVerifiedPodsList(data.claimed_podcasts);
        }
      })
      .catch((err) => console.log(err));
  };

  handleAddVerifiedPod = (e) => {
    const { userData, updateVerifiedPodsList } = this.props;
    console.log(userData);
    const uID = userData.id;
    const podID = e.target.attributes['data-pod-id'].value;
    const url = `${server}add-podcast-claim`;
    const formData = new FormData();
    formData.append('user_id', uID);
    formData.append('podcast_id', podID);
    formData.append('skip_email', true);
    formData.append('verified', true);

    console.log(`Adding Claimed & Verified to Podcast ${podID}, from User: ${uID}`);
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: this.props.token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          this.handleOpenModal('Added & Verified.');
          updateVerifiedPodsList(data.claimed_podcasts);
        }
      })
      .catch((err) => console.log(err));
  };

  handleModalStatus = () => {};

  render() {
    const { podcasts, search, modal } = this.state;
    const { status, type } = modal;
    return (
      <>
        {status && <ConfirmModal onClose={this.handleCloseModal} modalType={type} />}
        <WhiteBGWrapper>
          <Heading>Add a podcast</Heading>
          <InputWrapper>
            <SearchIcon src={searchIcon} alt="search" />
            <SearchInput
              value={search.searchValue}
              onChange={(e) => this.handleSearchChange(e)}
              onKeyPress={(e) => this.handleSearchSubmit(e)}
              type="text"
              placeholder="Search Podcast Shows"
            />
          </InputWrapper>
          <br />
        </WhiteBGWrapper>
        <WhiteBGWrapperNoPad>
          <TableView
            addVerifiedPod={this.handleAddVerifiedPod}
            addClaimedPod={this.handleAddClaimedPod}
            tableTopBar={false}
            rowData={podcasts}
            headerData={TableData.headers.verifiedPods}
            rowType="verifiedPodcasts"
            sortFunction={this.handleSortClick}
            sortData={this.state.sort}
          />

          <CenterAligned>
            <PrevNextBtn onClick={this.loadPrevPage}>&lt;</PrevNextBtn>
            <PrevNextBtn onClick={this.loadNextPage}>&gt;</PrevNextBtn>
          </CenterAligned>
        </WhiteBGWrapperNoPad>
      </>
    );
  }
}

ProfileAddingVPod.propTypes = propTypes;
ProfileAddingVPod.defaultProps = defaultProps;

export default connect(mapStoreToProps)(ProfileAddingVPod);
