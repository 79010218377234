import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getAuthUser } from '../firebase';

import {
  CONSTANTS, server, mapStoreToProps, FlexItem, FlexCol,
} from '../assets/CONSTANTS';
import { apiPostRequest } from '../lib/api/utils';

const FlexItemWhiteBG = styled(FlexItem)`
  background-color: ${CONSTANTS.color.whiteP};
  padding: 30px 15px;
`;

const ProfileButton = styled.button`
  background-color: transparent;
  padding: 10px 20px;
  margin: 10px 0px;
  color: black;
  font-weight: bold;
  width: 100%;
  border: 1px solid ${CONSTANTS.color.greyP};
  border-radius: 6px;
  font-size: 20px;
  transition: 0.15s all ease-in-out;
  &:hover {
    background-color: ${CONSTANTS.color.yellowP};
    border: 1px solid ${CONSTANTS.color.borderGrey};
  }
`;

const Wrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 600;
`;
const Modal = styled.div`
  background-color: ${CONSTANTS.color.whiteP};
  position: absolute;
  top: calc(50vh - 150px);
  left: calc(50vw - 10%);
  max-width: 40%;
  width: 20%;
  min-width: 280px;
  padding: 40px;
  text-align: center;
  border-radius: 4px;
`;
const Heading = styled.h1`
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.4rem;
  width: 80%;
  margin: 10px auto;
  text-align: center;
`;
const Message = styled.p``;
const ConfirmBtn = styled.button`
  padding: 10px 25px;
  border-radius: 4px;
  border: none;
  background-color: ${CONSTANTS.color.yellowP};
  font-weight: bold;
  margin: 5px auto;
  width: 100%;
  outline: none;
  cursor: pointer;
`;

const CancelBtn = styled(ConfirmBtn)`
  border: 1px solid ${CONSTANTS.color.borderGrey};
  background-color: transparent;
`;

function ConfirmModal({
  onCancel, onConfirm, modalType, userData,
}) {
  return (
    <Wrapper>
      <Modal>
        <Heading>
          Are you sure you want to
          {' '}
          {modalType === 'admin' ? '' : modalType}
          {' '}
          {userData.first_name}
          {' '}
          {userData.last_name}
          {' '}
          {modalType === 'admin' && 'an Admin' }
        </Heading>
        {modalType === 'delete' ? (
          <Message>
            This can not be undone and will remove their account and all their reviews and comments.
          </Message>
        ) : (
          ''
        )}
        {modalType === 'feature' ? (
          <Message>
            This will send a push notification to every user on Goodpods, telling them that the selected user has a profile that they can check out.
          </Message>
        ) : (
          ''
        )}
        <ConfirmBtn onClick={() => onConfirm(modalType)}>
          Yes,
          {' '}
          {modalType}
          {' '}
          {userData.first_name}
          {' '}
          {userData.last_name}
        </ConfirmBtn>
        <CancelBtn onClick={() => onCancel()}>No, go back</CancelBtn>
      </Modal>
    </Wrapper>
  );
}

class ProfileActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
      deactivateModal: false,
      reactivateModal: false,
      userDeactivated: this.props.userData.is_deactivated,
      adminModal: false,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleOpen(modalType) {
    if (modalType === 'deactivate') {
      this.setState({ deactivateModal: true });
    } else if (modalType === 'reactivate') {
      this.setState({ reactivateModal: true });
    } else if (modalType === 'delete') {
      this.setState({ deleteModal: true });
    } else if (modalType === 'feature') {
      this.setState({ featureModal: true });
    } else if (modalType === 'admin') {
      this.setState({ adminModal: true });
    } else {
      alert('Unknown user modification selected, please report this error');
    }
  }

  handleCancel() {
    this.setState({
      deleteModal: false,
      deactivateModal: false,
      reactivateModal: false,
      featureModal: false,
      adminModal: false,
    });
  }

  handleConfirm(confType) {
    const { reloadFunction, reloadState } = this.props;
    switch (confType) {
      case 'deactivate': {
        this.updateDeactivated(true);
        break;
      }
      case 'delete': {
        this.updateDeleted();
        break;
      }
      case 'reactivate': {
        this.updateDeactivated(false);
        break;
      }
      case 'feature': {
        this.featureUser();
        break;
      }
      case 'admin': {
        this.adminUser();
        break;
      }
      default: {
        break;
      }
    }
    reloadFunction(reloadState);
  }

  async updateDeactivated(activeStatus) {
    const currUser = getAuthUser();
    const firebaseToken = await currUser.getIdToken(false);

    const ep = `${server}v2/admin/edit-user`;
    const reqData = {
      user_id: this.props.userData.id,
      deactivated: activeStatus,
    };

    fetch(ep, {
      method: 'POST',
      headers: {
        DjangoAuth: `Bearer ${firebaseToken}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((response) => {
        if (response.status === 200 && response.statusText === 'OK') {
          this.setState({
            deleteModal: false,
            deactivateModal: false,
            reactivateModal: false,
            userDeactivated: !this.state.userDeactivated,
          });
        }
        response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => console.log(error));
  }

  updateDeleted() {
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      const ep = `${server}v2/admin/safely-delete-user-admin-panel?userProfileId=${this.props.userData.id}`;
      fetch(ep, {
        method: 'DELETE',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
      })
        .then((response) => {
          if (response.status === 200 && response.statusText === 'OK') {
            this.setState({ deleteModal: false, deactivateModal: false, reactivateModal: false });
          }
          response.json();
        })
        .then((data) => data)
        .catch((error) => console.log(error));
    }).catch((err) => console.log(err));
  }

  featureUser() {
    const currUser = getAuthUser();

    currUser.getIdToken(false).then((firebaseToken) => {
      const url = `${server}v2/admin-notifications/new-celebrity-notification`;
      const data = {
        user_id: this.props.userData.id,
      };
      fetch(url, {
        method: 'POST',
        headers: {
          DjangoAuth: `Bearer ${firebaseToken}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              deleteModal: false, deactivateModal: false, reactivateModal: false, featureModal: false,
            });
          }
          response.json();
        })
        .then((data) => data)
        .catch((error) => console.log(error));
    }).catch((err) => console.log(err));
  }

  adminUser() {
    const reqData = {
      user_id: this.props.userData.id,
      is_admin: true,
    };
    const url = `${server}v2/admin/edit-user`;

    apiPostRequest(url, reqData)
      .then((data) => {
        if (data.success) {
          this.setState({
            deleteModal: false,
            deactivateModal: false,
            reactivateModal: false,
            featureModal: false,
            adminModal: false,
          });
        }
        return data;
      })
      .catch((error) => console.log(error));
  }

  render() {
    const { userData } = this.props;
    const {
      userDeactivated, deleteModal, deactivateModal, reactivateModal, featureModal, adminModal,
    } = this.state;
    return (
      <FlexItemWhiteBG padded>
        {deleteModal ? (
          <ConfirmModal
            userData={userData}
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirm}
            modalType="delete"
          />
        ) : (
          ''
        )}
        {deactivateModal ? (
          <ConfirmModal
            userData={userData}
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirm}
            modalType="deactivate"
          />
        ) : (
          ''
        )}
        {reactivateModal ? (
          <ConfirmModal
            userData={userData}
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirm}
            modalType="reactivate"
          />
        ) : (
          ''
        )}
        {featureModal ? (
          <ConfirmModal
            userData={userData}
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirm}
            modalType="feature"
          />
        ) : (
          ''
        )}
        {adminModal ? (
          <ConfirmModal
            userData={userData}
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirm}
            modalType="admin"
          />
        ) : (
          ''
        )}
        <FlexCol>
          <FlexItem flex={1}>
            {userDeactivated ? (
              <ProfileButton
                onClick={() => {
                  this.handleOpen('reactivate');
                }}
              >
                Reactivate
                {' '}
                {userData.first_name}
                {' '}
                {userData.last_name}
              </ProfileButton>
            ) : (
              <ProfileButton
                onClick={() => {
                  this.handleOpen('deactivate');
                }}
              >
                Deactivate
                {' '}
                {userData.first_name}
                {' '}
                {userData.last_name}
              </ProfileButton>
            )}
          </FlexItem>
          <FlexItem flex={1}>
            <ProfileButton
              onClick={() => {
                this.handleOpen('delete');
              }}
            >
              Delete
              {' '}
              {userData.first_name}
              {' '}
              {userData.last_name}
              {' '}
              and all their data
            </ProfileButton>
          </FlexItem>
          <FlexItem flex={1}>
            <ProfileButton
              onClick={() => {
                this.handleOpen('feature');
              }}
            >
              Push notify all users that
              {' '}
              {userData.first_name}
              {' '}
              {userData.last_name}
              {' '}
              is now on Goodpods
            </ProfileButton>
          </FlexItem>
          <FlexItem flex={1}>
            <ProfileButton
              onClick={() => {
                this.handleOpen('admin');
              }}
            >
              Make user Admin
            </ProfileButton>
          </FlexItem>
        </FlexCol>
      </FlexItemWhiteBG>
    );
  }
}

export default connect(mapStoreToProps)(ProfileActions);
