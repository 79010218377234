import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import InputGroup from './InputGroup';
import ImageSelector from './ImageSelector';

import { CONSTANTS, server, mapStoreToProps, FlexItem, FlexRow } from '../assets/CONSTANTS';
import profpic from '../assets/ImgPH.svg';
const { color } = CONSTANTS;
const halfGutters = '10px;';

const ErrorMsg = styled.p`
  color: ${CONSTANTS.color.red};
  text-align: left;
`;
const SectionHeading = styled.h1`
  font-weight: bold;
  font-size: 18px;
  padding: ${props => (props.padding ? props.padding : '5px 0px;')};
  margin: 20px 0px 20px 0px;
  text-align: left;
`;

const ActionButton = styled.button`
  color: black;
  width: 100%;
  background-color: ${color.yellow};
  border: 1px solid ${color.yellow};
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  min-height: 45px;
  font-weight: bold;
  margin: 20px auto;
  padding: ${props => (props.padding ? props.padding : '5px 0px;')};
  transition: 0.15s all ease-in-out;
  &:hover {
    background-color: ${color.yellowP};
    border: 1px solid ${color.yellowP};
  }
`;

const FlexItemPadded = styled(FlexItem)`
  padding: 10px 15px !important;
  box-sizing: border-box;
`;

class DetailChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newName: '',
      confName: '',
      image_path: null,
      image_ext: null,
      image_id: null,
      newNameError: false,
      confNameError: false,
      errorMsg: null
    };
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
  }

  uploadImageFromBlobPath(blobPath, folderName, newProfileName) {
    console.log('POSTING TO save-user-file');
    let ext = this.state.image_ext;
    let token = this.props.token;

    var xhr = new XMLHttpRequest();
    xhr.open('GET', blobPath, true);
    xhr.responseType = 'blob';
    xhr.onload = function(e) {
      if (xhr.status === 200) {
        var currentBlob = xhr.response;
        let url = `${server}save-user-file`;
        let data = new FormData();
        data.append('user_file', currentBlob);
        data.append('file_extension', ext);
        data.append('folder_name', folderName);

        fetch(url, {
          method: 'POST',
          headers: {
            Authorization: token
          },
          body: data
        })
          .then(res => res.json())
          .then(data => {
            this.setState({ image_id: data.user_file.id }, () => {
              console.log(this.state);
              this.changeDetails(newProfileName);
            });
            return data;
          })
          .catch(error => console.log(error));
      } else {
        console.log('not status 200, request failed');
      }
    }.bind(this);
    xhr.send();
  }

  changeDetails(name) {
    const { image_id } = this.state;
    const url = `${server}change-admin-user-details`;
    let data = new FormData();
    data.append('new_name', name);
    if (image_id === null) {
      console.log('using default');
      data.append('profile_image_id', 1);
    } else {
      console.log('using image id');
      data.append('profile_image_id', image_id);
    }

    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: this.props.token
      },
      body: data
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
      })
      .catch(error => console.log(error));
  }

  onChange(inputName, e) {
    if (inputName === 'newName') {
      const newName = e.target.value;
      this.setState({ newName });
    } else {
      const confName = e.target.value;
      this.setState({ confName });
    }
  }

  onImageChange(e) {
    let ext = e.target.value.split('.').pop();
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({ image_path: reader.result, image_ext: ext });
        if (this.refs.imageFileInput != null) {
          this.refs.imageFileInput.value = '';
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  onClick() {
    let nn = this.state.newName;
    let cn = this.state.confName;

    if (nn !== cn) {
      this.setState({ newNameError: true, confNameError: true, errorMsg: "Names don't match." });
    } else if (nn.length <= 1 || cn.length <= 1) {
      this.setState({
        newNameError: true,
        confNameError: true,
        errorMsg: 'Name is too short, minimum 2 characters.'
      });
    } else if (nn.length >= 101 || cn.length >= 101) {
      this.setState({
        newNameError: true,
        confNameError: true,
        errorMsg: 'Name exceeds maximum of 100 characters.'
      });
    } else if (nn === cn) {
      if (this.state.image_path === null) {
        console.log('no image selected');
        this.changeDetails(nn);
        this.props.onSubmit();
        this.setState({
          newName: '',
          confName: '',
          newNameError: false,
          confNameError: false,
          errorMsg: null
        });
        document.getElementById('DCimg').src = profpic;
        document.getElementById('fileInputDC').value = null;
      } else {
        console.log('image selected');
        this.uploadImageFromBlobPath(this.state.image_path, 'admin_profiles', nn);
        this.props.onSubmit();
        this.setState({
          newName: '',
          confName: '',
          newNameError: false,
          confNameError: false,
          errorMsg: null
        });
        document.getElementById('DCimg').src = profpic;
        document.getElementById('fileInputDC').value = null;
      }
    }
  }

  render() {
    let { newName, confName, newNameError, confNameError, errorMsg } = this.state;
    return (
      <FlexItemPadded
        textAlign="center"
        bgColor={color.whiteP}
        margin={`margin-left:${halfGutters}`}
      >
        <SectionHeading>Change Name &amp; Image</SectionHeading>
        <FlexRow>
          <FlexItem>
            <InputGroup
              onChange={this.onChange}
              placeholder="JJ Ramberg"
              inputType="text"
              label="Name"
              inputName="newName"
              value={newName}
              error={newNameError}
            />
            <InputGroup
              onChange={this.onChange}
              placeholder="JJ Ramberg"
              inputType="text"
              label="Confirm Name"
              value={confName}
              error={confNameError}
            />
          </FlexItem>
          <FlexItem>
            <ImageSelector
              onImageChange={this.onImageChange}
              id="fileInputDC"
              imageID="DCimg"
              buttonText="Change image"
            />
          </FlexItem>
        </FlexRow>
        {errorMsg ? <ErrorMsg>*{errorMsg}</ErrorMsg> : ''}
        <ActionButton onClick={this.onClick}>Save Changes</ActionButton>
      </FlexItemPadded>
    );
  }
}

export default connect(mapStoreToProps)(DetailChange);
