import React, { Component } from 'react';
import styled from 'styled-components';

import { CONSTANTS, FlexRow, FlexItem, FlexCol } from '../assets/CONSTANTS';

const FlexColWhiteBG = styled(FlexCol)`
  background-color: ${CONSTANTS.color.whiteP};
  padding-bottom: 20px;
  margin-bottom: 50px;
  border-radius: 4px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
`;

const Heading = styled.h1`
  margin: 0px;
  padding: 25px 0px 25px 50px;
  border-bottom: 1px solid ${CONSTANTS.color.borderGrey};
`;

const ItemWrapper = styled(FlexItem)`
  padding: 15px 0px 15px 50px;
  border-bottom: 1px solid ${CONSTANTS.color.borderGrey};
`;

const RowWrapper = styled(FlexRow)`
  min-height: 0px;
`;

const PodcastEpisodeTitle = styled.h4`
  margin: 0px;
`;

const ReviewText = styled.p`
  color: ${CONSTANTS.color.sidebarP};
  margin: 0;
  font-weight: 200;
`;

const ColPadded = styled(FlexItem)`
  padding: 5px 100px 5px 0px;
`;

const DeleteBtnWrapper = styled(FlexItem)`
  display: flex;
  align-items: center;
`;

const DeleteBtn = styled.button`
  padding: 10px 25px;
  margin-right: 25px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  border-radius: 4px;
  background-color: transparent;
  font-size: 14px;
  border-bottom: 2px solid ${CONSTANTS.color.borderGrey};
  outline: none;
  cursor: pointer;
  &:active {
  }
`;

class ProfileReviews extends Component {
  generateRows(data) {
    return data.map(review => {
      return (
        <ItemWrapper key={review.id}>
          <RowWrapper>
            <ColPadded flex={2}>
              <PodcastEpisodeTitle>
                {review.podcast_title} - {review.episode_title}
              </PodcastEpisodeTitle>
            </ColPadded>
            <ColPadded flex={4}>
              <ReviewText>{review.review_text}</ReviewText>
            </ColPadded>
            <DeleteBtnWrapper textAlign="right" flex={1}>
              <DeleteBtn id={review.id} onClick={e => this.props.onDelete(e)}>
                Delete Review
              </DeleteBtn>
            </DeleteBtnWrapper>
          </RowWrapper>
        </ItemWrapper>
      );
    });
  }

  render() {
    const { reviewData } = this.props;
    return (
      <FlexColWhiteBG margin="margin:20px 0px;">
        <FlexItem>
          <Heading>Reviews</Heading>
        </FlexItem>
        {this.generateRows(reviewData)}
      </FlexColWhiteBG>
    );
  }
}

export default ProfileReviews;
