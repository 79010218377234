import React from 'react';
import { connect } from 'react-redux';
// Custom components
import WeeklyEmail from '../components/WeeklyEmail';

// Assets & Styles
import { FlexRow, mapStoreToProps } from '../assets/CONSTANTS';

const DynamicEmails = () => (
  <FlexRow>
    <WeeklyEmail />
  </FlexRow>
);

export default connect(mapStoreToProps)(DynamicEmails);
