// React & React Router
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Custom Components
import TableView from '../components/TableView';

// Assets & Styles
import TestData from '../data.json';
import {
  CONSTANTS,
  mapStoreToProps,
  FlexRow,
  FlexItem,
  TabLink,
  sortPodcastComparison,
} from '../assets/CONSTANTS';
import { apiGetRequest, getNextPageFromURL, getPrevPageFromURL } from '../lib/api/utils';

const URLParams = '?limit=10&offset=0';
const endpoint = `v2/podcast/search${URLParams}`;

const Tab = styled(FlexItem)`
  background-color: ${CONSTANTS.color.tabGrey};
  border-bottom: 4px solid ${CONSTANTS.color.whiteP};
  justify-content: middle;
  padding: 15px 0px;
  text-align: center;
  border-top-right-radius: 4px !important;
`;
const TabActive = styled(Tab)`
  background-color: ${CONSTANTS.color.whiteP};
  border-bottom: 4px solid ${CONSTANTS.color.yellowP};
  border-top-left-radius: 4px !important;
`;

const PrevNextBtn = styled.button`
  padding: 15px;
  background-color: #fff;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  cursor: pointer;
  outline: none;
  margin: 5px;
  font-weight: bold;
  transition: 0.15s all ease-in-out;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  &:hover {
    background-color: ${CONSTANTS.color.yellowP};
  }
`;

const CenterAligned = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${CONSTANTS.color.whiteP};
  padding: 20px 0px;
  border: 1px solid ${CONSTANTS.color.borderGrey};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
`;

class PodcastsAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      podcasts_next: '',
      podcasts_prev: '',
      current_params: URLParams,
      podcasts: [],
      sort: {
        currentField: '',
        sortOrder: '',
      },
    };
    this.handleSortClick = this.handleSortClick.bind(this);
    this.loadNextPage = this.loadNextPage.bind(this);
    this.loadPrevPage = this.loadPrevPage.bind(this);
  }

  async componentDidMount() {
    this.setState({ podcasts: await this.getPodcasts(endpoint) });
  }

  async loadNextPage() {
    const { podcasts_next } = this.state;
    if (podcasts_next) {
      this.setState({ podcasts: await this.getPodcasts(podcasts_next) });
    }
  }

  async loadPrevPage() {
    const { podcasts_prev } = this.state;
    if (podcasts_prev) {
      this.setState({ podcasts: await this.getPodcasts(podcasts_prev) });
    }
  }

  getPodcasts(ep) {
    return apiGetRequest(ep)
      .then((data) => {
        if (data.length !== 10) {
          this.setState({ podcasts_next: null });
          this.setState({ podcasts_prev: getPrevPageFromURL(ep) });
        } else {
          this.setState({ podcasts_next: getNextPageFromURL(ep) });
          this.setState({ podcasts_prev: getPrevPageFromURL(ep) });
        }
        return data;
      })
      .catch((error) => console.log(error));
  }

  handleSortClick(e) {
    const sortType = e.currentTarget.getAttribute('data-sorttype');
    if (sortType === this.state.sort.currentField) {
      this.state.sort.sortOrder === 'ASC'
        ? this.setState({ sort: { currentField: sortType, sortOrder: 'DESC' } }, () => {
          this.setState({
            podcasts: sortPodcastComparison(
              this.state.podcasts,
              this.state.sort.currentField,
              this.state.sort.sortOrder,
            ),
          });
        })
        : this.setState({ sort: { currentField: sortType, sortOrder: 'ASC' } }, () => {
          this.setState({
            podcasts: sortPodcastComparison(
              this.state.podcasts,
              this.state.sort.currentField,
              this.state.sort.sortOrder,
            ),
          });
        });
    } else {
      this.setState({ sort: { currentField: sortType, sortOrder: 'ASC' } }, () => {
        this.setState({
          podcasts: sortPodcastComparison(
            this.state.podcasts,
            this.state.sort.currentField,
            this.state.sort.sortOrder,
          ),
        });
      });
    }
  }

  render() {
    return (
      <div>
        <FlexRow>
          <TabActive>
            <TabLink to="/Podcasts">All Podcasts</TabLink>
          </TabActive>
          <Tab>
            <TabLink to="/Featured-Podcasts">Featured Podcasts</TabLink>
          </Tab>
        </FlexRow>
        <div>
          <TableView
            tableTitle="All Podcasts"
            linkText="View All"
            linkPath="/"
            tableTopBar={false}
            rowData={this.state.podcasts}
            headerData={TestData.headers.podcasts}
            rowType="podcast"
            sortFunction={this.handleSortClick}
            sortData={this.state.sort}
          />
          <CenterAligned>
            <PrevNextBtn onClick={this.loadPrevPage}>&lt;</PrevNextBtn>
            <PrevNextBtn onClick={this.loadNextPage}>&gt;</PrevNextBtn>
          </CenterAligned>
        </div>
      </div>
    );
  }
}

export default connect(mapStoreToProps)(PodcastsAll);
